const apis = {
  logoutAPI: "/v1/openid-connect/logout",
  getTokenAPI: "/v1/openid-connect/token",
  refreshTokenAPI: "/v1/openid-connect/token/refresh",
  getOfferAPI: "/v1/offers",
  getServiceAPI: "/v1/services",
  getTaxSchemeAPI: "/v1/tax-schemes",
  getCustomerAPI: "/v1/customers",
  getAccountAPI: "/v1/accounts",
  getUserProfile: "/v1/userprofile",
  getAllUserProfile: "/v1/users",
  postMakerCheckerAPI: "/v1/maker-checker-trans",
  getMakerCheckerAPI: "/v1/maker-checker-trans/approval",
  getAllRequestAPI: "/v1/maker-checker-trans/allrequest",
  getMakerCheckerAllRequestAPI: "/v1/maker-checker-trans/allrequest-checker",
  getMakerMakerAllRequestAPI: "/v1/maker-checker-trans/allrequest-maker",
  getMyRequestAPI: "/v1/maker-checker-trans/myrequest",
  getMakerValidatePendingAPI: "/v1/maker-checker-trans/validate-pending",
  getUpload: "/v1/file-uploads",
  getReport: "/v1/reports",
  getUserService: "/v1/user-services",
  getUserCharges: "/v1/user-charges",
  getUserMaker: "/v1/users?user_role=MAKER",
  getUserChecker: "/v1/users?user_role=CHECKER",
  getBankBranch: "/v1/bank-branches",
  putSystemSetting: "/v1/system-settings",
  getUserGroup: "/v1/user-groups",
  getUserIdap: "/v1/user-ldap",
  getBankId: "/v1/banks",
  getHistory: "/v1/maker-checker-trans/histories",
  getTaxSchemeHistory: "/v1/tax-schemes/histories",
  postTaxScheme: "/v1/tax-schemes",
  postService: "/v1/services",
  offerBundleAPI: "/v1/bundle-offers",
  emailStopAlert: "v1/emailalert/deactivate",
};

export default apis;

// GET localhost:8081/v1/services/BOING
// POST localhost:8081/v1/services
// PUT localhost:8081/v1/services/BOING
// GET localhost:8081/v1/services/histories?offset=0&limit=20&service_code=BOING
