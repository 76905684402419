import { format } from "date-fns";
import * as React from "react";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import { PBFECommonTable } from "../../components/Table/commonTable";
import {
  MINIMUM_SEARCH_ACTIVE,
  UPLOAD_MAKER_CHECKER_HEADER,
} from "../../constants/constant";
import { formateDate, formatEmpty } from "../../utils/util";
import {
  PBFEGeneralInfoChecker,
  PBFESectionReason,
} from "../WaitingForApproval/approvalDetail";

const FormatDatRows = (array_input) => {
  const res = array_input?.map((item) => {
    return {
      txn_id: item?.transaction_id,
      tnx_date: item?.transaction_date,
      cust_id: item?.customer_id,
      acct_no: item?.account_id,
      txn_code: item?.transaction_code,
      txn_volume: item?.transaction_volume,
    };
  });

  return res;
};

const UploadMakerChecker = ({ data }) => {
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      setRows(data?.data?.display_obj);
    }
  }, []);

  const requestSearch = (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(rows);
      return;
    } else {
      const filteredRows = rows.filter((row) => {
        return (
          row.account_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.customer_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.transaction_code
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.transaction_date
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.transaction_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase())
        );
      });
      setRows(filteredRows);
    }
  };

  var today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";
  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <div className="w-full px-xl">
        {/* <div className="flex justify-end w-full  pt-md">
          <PBFESearchbar
            placeholder={"Search Service Name / Code"}
            onChange={requestSearch}
            value={searched}
          />
        </div> */}
        <PBFECommonTable
          searched={searched}
          page={page}
          setPage={setPage}
          headCells={UPLOAD_MAKER_CHECKER_HEADER}
          rows={FormatDatRows(rows)}
          formatControl={[null, null, null, null, null, null]}
          showFooter={true}
        />
      </div>
    </div>
  );
};

export default UploadMakerChecker;
