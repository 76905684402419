// import * as React from "react";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { NULL_DATE, ROW_PER_PAGE } from "../../constants/constant";
import {
  getNameFromDebitProfileSeleted,
  getComparator,
  stableSort,
} from "../../functions";
import {
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import NoRecordFound from "../Error/noRecordFound";
import { FooterButton } from "../Elements/Button/footerButton";
import { formatDateApi, formateDate } from "../../utils/util";
import DeleteIcon from "../../assets/icon/delete.svg";
import {
  AccountModal,
  PBFESelectFromDateModal,
  PBFESelectToDateModal,
} from "../Panel/Modal/accountModal";
import { useLocation } from "react-router-dom";

export const PBFEEditTable = ({
  headCells,
  selectable = false,
  // onClickRow,
  page = 0,
  rows,
  orderByDefault = "",
  searched,
  isEdit,
  isNew,
  accountTable,
  // debitProfile,
  globalDebitProfile = [],
  // setDebitProfile = () => {},
  setDebitProfileGlobal = () => {},
  setErrorState = () => {},
}) => {
  const [order, setOrder] = React.useState("asc");
  const [modal, setModal] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState(orderByDefault);
  const [sortBy, setSortBy] = React.useState(true);
  const [selectAccount, setSelectAccount] = React.useState("");
  const [formDateOpen, setFormDateOpen] = React.useState(false);
  const [toDateOpen, setToDateOpen] = React.useState(false);
  const [rowIndex, setRowIndex] = React.useState(0);
  const [apiData, setApiData] = React.useState();
  const [accountSearch, setAccountSearch] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState({});
  // const [myDebit, setMyDebit] = React.useState([]);
  const [debitProfile, setDebitProfile] = React.useState([
    ...globalDebitProfile,
  ]);

  const location = useLocation();

  // const isAdded = debitProfile[debitProfile?.length - 1]?.isAdded;
  const isPathAccount = location?.pathname?.slice(0, 8) === "/account";
  const isPathCustomer = location?.pathname?.slice(0, 9) === "/customer";
  const isDebitProfileHaveLength = debitProfile?.filter((d) => {
    return d?.action !== "DELETE";
  })?.length;

  const isDuplicate = location?.search?.slice(0, 1) === "?";

  React.useEffect(() => {
    setDebitProfileGlobal(debitProfile);
  }, [debitProfile]);

  //UseEffect โหลดครั้งแรก
  React.useEffect(() => {
    if (isNew && isPathCustomer && !isDuplicate) {
      console.log("IN1");

      // CUSTOMER NEW
      setDebitProfile([]);
    } else if (isPathCustomer && !isNew) {
      console.log("IN2");

      //PATH CUSTOMER  FOR EDIT
      // if (rows.length > 0) {
      //   setMyDebit(rows);
      // }
      setDebitProfile(rows);
    } else if (!isNew && !isPathCustomer && !isDuplicate) {
      console.log("IN3");

      // EDIT ACCOUNT
      // if (rows.length > 0) {
      //   setMyDebit(rows);
      // }
      setDebitProfile(rows);
    } else if (!isPathAccount) {
      console.log("IN4");

      // NEW CUSTOMER
      // if (rows.length > 0) {
      //   setMyDebit(rows);
      // }
      setDebitProfile(rows);
    } else if (isPathAccount && isDuplicate) {
      console.log("IN5");
      // if (rows.length > 0) {
      //   setMyDebit(rows);
      // }
      // ACCOUNT DUPLICATE
      setDebitProfile(rows);
    } else if (isPathAccount && isDuplicate) {
      console.log("IN6");
      // if (rows.length > 0) {
      //   setMyDebit(rows);
      // }
      // COUSUMER DUPLICATE
      setDebitProfile(rows);
    } else {
      console.log("IN7");

      return;
    }
  }, [isEdit]);

  const addNewRow = () => {
    const accountSeleted = getNameFromDebitProfileSeleted(selectAccount);
    const account_status = accountTable.find(
      (i) => i.account_id === accountSeleted.id
    );
    const today = new Date();

    let tempAccounts = [...debitProfile];

    let previous_date = "";
    // console.log("tempAccounts", tempAccounts);

    if (tempAccounts[tempAccounts.length - 1]?.action === "DELETE") {
      previous_date = new Date(
        tempAccounts[
          tempAccounts?.filter((d) => {
            return d.action !== "DELETE";
          })?.length - 1
        ]?.to_date
      );

      previous_date.setDate(previous_date.getDate() + 1);
    } else if (tempAccounts[tempAccounts?.length - 1]?.to_date === null) {
      previous_date = "9999-12-31";
    } else {
      previous_date = new Date(tempAccounts[tempAccounts?.length - 1]?.to_date);

      previous_date.setDate(previous_date.getDate() + 1);
    }

    // if (tempAccounts[tempAccounts?.length - 1]?.to_date === null) {
    //   previous_date = "9999-12-31";
    // } else {
    // previous_date = new Date(
    //   tempAccounts[
    //     tempAccounts
    //     ?.filter((d) => {
    //       return d.action !== "DELETE";
    //     })
    //     ?.length - 1
    //   ]?.to_date
    // );

    //   previous_date.setDate(previous_date.getDate() + 1);
    // }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const debitProfileFiltered = debitProfile.filter(
      (x) => x.action !== "DELETE"
    );
    if (debitProfileFiltered?.length > 0 && selectAccount !== "Other") {
      // const fintDelete = debitProfile?.find(
      //   (i) => i.posting_account_id === account_status?.account_id
      // );

      // const findIndex = debitProfile?.findIndex(
      //   (i) => i.posting_account_id === account_status?.account_id
      // );

      // const isDelete = fintDelete?.action === "DELETE";

      // const isDelete = false;

      // let index = 99;

      // if (isDelete) {
      //   if (debitProfile[index].customer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_posting_account_id:
      //         debitProfile[index].customer_posting_account_id,
      //       posting_account_id: accountSeleted?.id,
      //       posting_account_name: accountSeleted?.name,
      //       status: account_status?.status || "-",
      //       from_date: formatDateApi(new Date(previous_date).getTime()),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      //   if (debitProfile[index].customer_offer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_offer_posting_account_id:
      //         debitProfile[index].customer_offer_posting_account_id,
      //       posting_account_id: accountSeleted?.id,
      //       posting_account_name: accountSeleted?.name,
      //       status: account_status?.status || "-",
      //       from_date: formatDateApi(new Date(previous_date).getTime()),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      // } else {
      tempAccounts.push({
        isAdded: true,
        posting_account_id: accountSeleted?.id,
        posting_account_name: accountSeleted?.name,
        status: account_status?.status || "-",
        from_date: formatDateApi(new Date(previous_date).getTime()),
        to_date: null,
        action: "NEW",
      });
      // }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if (debitProfileFiltered?.length > 0 && selectAccount === "Other") {
      console.log("IN ADD 2");

      // let previous_date = "";
      // if (tempAccounts[tempAccounts?.length - 1]?.to_date === null) {
      //   previous_date = "9999-12-31";
      // } else {
      //   previous_date = new Date(
      //     tempAccounts[
      //       tempAccounts?.filter((d) => {
      //         return d.action !== "DELETE";
      //       })?.length - 1
      //     ]?.to_date
      //   );

      //   previous_date.setDate(previous_date.getDate() + 1);
      // }

      // const fintDelete = debitProfile?.find(
      //   (i) => i.posting_account_id === apiData?.account_id
      // );
      // const findIndex = debitProfile?.findIndex(
      //   (i) => i.posting_account_id === apiData?.account_id
      // );

      // const isDelete = fintDelete?.action === "DELETE";

      // const isDelete = false;
      // let index = 99;

      // if (isDelete) {
      //   if (debitProfile[index].customer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_posting_account_id:
      //         debitProfile[index].customer_posting_account_id,
      //       posting_account_id: apiData?.account_id,
      //       posting_account_name: apiData?.account_name,
      //       status: debitProfile[index]?.status || "-",
      //       from_date: formatDateApi(new Date(previous_date).getTime()),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      //   if (debitProfile[index].customer_offer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_offer_posting_account_id:
      //         debitProfile[index].customer_offer_posting_account_id,
      //       posting_account_id: apiData?.account_id,
      //       posting_account_name: apiData?.account_name,
      //       status: debitProfile[index]?.status || "-",
      //       from_date: formatDateApi(new Date(previous_date).getTime()),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      // } else {
      tempAccounts.push({
        isAdded: true,
        posting_account_id: apiData?.account_id,
        posting_account_name: apiData?.account_name,
        status: apiData.account_status || "-",
        from_date: formatDateApi(new Date(previous_date).getTime()),
        to_date: null,
        action: "NEW",
      });
      // }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if (selectAccount === "Other") {
      console.log("IN ADD 3");

      // let previous_date = "";
      // if (tempAccounts[tempAccounts?.length - 1]?.to_date === null) {
      //   previous_date = "9999-12-31";
      // } else {
      //   previous_date = new Date(
      //     tempAccounts[
      //       tempAccounts?.filter((d) => {
      //         return d.action !== "DELETE";
      //       })?.length - 1
      //     ]?.to_date
      //   );

      //   previous_date.setDate(previous_date.getDate() + 1);
      // }

      // const fintDelete = debitProfile?.find(
      //   (i) => i.posting_account_id === apiData?.account_id
      // );
      // const findIndex = debitProfile?.findIndex(
      //   (i) => i.posting_account_id === apiData?.account_id
      // );

      // const isDelete = fintDelete?.action === "DELETE";

      // const isDelete = false;
      // let index = 99;

      // if (isDelete) {
      //   if (debitProfile[index].customer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_posting_account_id:
      //         debitProfile[index].customer_posting_account_id,
      //       posting_account_id: apiData?.account_id,
      //       posting_account_name: apiData?.account_name,
      //       status: debitProfile[index]?.status || "-",
      //       from_date: formatDateApi(new Date(today)),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      //   if (debitProfile[index].customer_offer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_offer_posting_account_id:
      //         debitProfile[index].customer_offer_posting_account_id,
      //       posting_account_id: apiData?.account_id,
      //       posting_account_name: apiData?.account_name,
      //       status: debitProfile[index]?.status || "-",
      //       from_date: formatDateApi(new Date(today)),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      // } else {
      tempAccounts.push({
        isAdded: true,
        posting_account_id: apiData?.account_id,
        posting_account_name: apiData?.account_name,
        status: apiData.account_status || "-",
        from_date: formatDateApi(new Date(today)),
        to_date: null,
        action: "NEW",
      });
      // }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else {
      console.log("IN ADD 4");

      // let previous_date = "";
      // if (tempAccounts[tempAccounts?.length - 1]?.to_date === null) {
      //   previous_date = "9999-12-31";
      // } else {
      //   previous_date = new Date(
      //     tempAccounts[
      //       tempAccounts?.filter((d) => {
      //         return d.action !== "DELETE";
      //       })?.length - 1
      //     ]?.to_date
      //   );

      //   previous_date.setDate(previous_date.getDate() + 1);
      // }

      // const fintDelete = debitProfile?.find(
      //   (i) => i.posting_account_id === account_status?.account_id
      // );
      // const findIndex = debitProfile?.findIndex(
      //   (i) => i.posting_account_id === account_status?.account_id
      // );

      // const isDelete = fintDelete?.action === "DELETE";

      // const isDelete = false;
      // let index = 99;

      // if (isDelete) {
      //   if (debitProfile[index].customer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_posting_account_id:
      //         debitProfile[index].customer_posting_account_id,
      //       posting_account_id: accountSeleted?.id,
      //       posting_account_name: accountSeleted?.name,
      //       status: account_status?.status || "-",
      //       from_date: formatDateApi(new Date(today)),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      //   if (debitProfile[index].customer_offer_posting_account_id) {
      //     tempAccounts[index] = {
      //       customer_offer_posting_account_id:
      //         debitProfile[index].customer_offer_posting_account_id,
      //       posting_account_id: accountSeleted?.id,
      //       posting_account_name: accountSeleted?.name,
      //       status: account_status?.status || "-",
      //       from_date: formatDateApi(new Date(today)),
      //       to_date: null,
      //       action: "EDIT",
      //     };
      //   }
      // } else {
      tempAccounts.push({
        isAdded: true,
        posting_account_id: accountSeleted?.id,
        posting_account_name: accountSeleted?.name,
        status: account_status?.status || "-",
        from_date: formatDateApi(new Date(today)),
        to_date: null,
        action: "NEW",
      });
      // }
    }

    setErrorState((prev) => {
      let temp = [...prev];
      temp = temp.filter((item) => item.field_name !== "posting_account");
      return [...temp];
    });
    setSelectAccount("");
    setApiData("");
    setAccountSearch("");
    setModal(false);
    setDebitProfile([...tempAccounts]);
  };

  const deleteRow = (e, index) => {
    e.stopPropagation();

    let tempAccounts = [...debitProfile];
    // ?.sort((a, b) => {
    //   return new Date(a?.from_date) - new Date(b?.from_date);
    // });

    if (
      debitProfile[index]?.customer_posting_account_id ||
      debitProfile[index]?.customer_offer_posting_account_id
    ) {
      tempAccounts[index].action = "DELETE";
    } else {
      tempAccounts.splice(index, 1);
    }
    setDebitProfile([...tempAccounts]);
  };

  const onClickEditRow = (e) => {
    e.stopPropagation();

    // console.log("hello edit row");

    // console.log("hello", rowIndex);
    // console.log("accountTable", accountTable);

    const accountSeleted = getNameFromDebitProfileSeleted(selectAccount);
    const account_status = accountTable.find(
      (i) => i.account_id === accountSeleted.id
    );
    // const index = debitProfile?.findIndex(
    //   (i) => i.posting_account_id === currentRow.posting_account_id
    // );

    const today = new Date();

    let tempAccounts = [...debitProfile];

    if (debitProfile?.length > 0 && selectAccount !== "Other") {
      let previous_date = "";
      if (
        tempAccounts[
          tempAccounts?.filter((d, d_index) => {
            return d.action !== "DELETE" && d_index !== rowIndex;
          })?.length - 1
        ]?.to_date === null
      ) {
        previous_date = "9999-12-31";
      } else {
        const checkPreviousDate =
          tempAccounts[
            tempAccounts?.filter((d, d_index) => {
              return d.action !== "DELETE" && d_index !== rowIndex;
            })?.length - 1
          ]?.to_date;

        if (checkPreviousDate) {
          previous_date = new Date(
            tempAccounts[
              tempAccounts?.filter((d, d_index) => {
                return d.action !== "DELETE" && d_index !== rowIndex;
              })?.length - 1
            ]?.to_date
          );

          previous_date.setDate(previous_date.getDate() + 1);
        } else {
          if (debitProfile[rowIndex].from_date) {
            previous_date = new Date(debitProfile[rowIndex].from_date);
          } else {
            previous_date = new Date();
          }
        }
      }

      tempAccounts[rowIndex] = {
        posting_account_id: accountSeleted?.id,
        posting_account_name: accountSeleted?.name,
        status: account_status?.status || "-",
        from_date: formatDateApi(new Date(previous_date).getTime()),
        to_date: null,
        action:
          debitProfile[rowIndex]?.customer_posting_account_id ||
          debitProfile[rowIndex]?.customer_offer_posting_account_id
            ? "EDIT"
            : "NEW",
      };
    } else if (debitProfile?.length > 0 && selectAccount === "Other") {
      let previous_date = "";
      if (
        tempAccounts[
          tempAccounts?.filter((d, d_index) => {
            return d.action !== "DELETE" && d_index !== rowIndex;
          })?.length - 1
        ]?.to_date === null
      ) {
        previous_date = "9999-12-31";
      } else {
        const checkPreviousDate =
          tempAccounts[
            tempAccounts?.filter((d, d_index) => {
              return d.action !== "DELETE" && d_index !== rowIndex;
            })?.length - 1
          ]?.to_date;

        if (checkPreviousDate) {
          previous_date = new Date(
            tempAccounts[
              tempAccounts?.filter((d, d_index) => {
                return d.action !== "DELETE" && d_index !== rowIndex;
              })?.length - 1
            ]?.to_date
          );

          previous_date.setDate(previous_date.getDate() + 1);
        } else {
          if (debitProfile[rowIndex].from_date) {
            previous_date = new Date(debitProfile[rowIndex].from_date);
          } else {
            previous_date = new Date();
          }
        }
      }
      tempAccounts[rowIndex] = {
        posting_account_id: apiData?.account_id,
        posting_account_name: apiData?.account_name,
        status: apiData.account_status || "-",
        from_date: formatDateApi(new Date(previous_date).getTime()),
        to_date: null,
        action:
          debitProfile[rowIndex]?.customer_posting_account_id ||
          debitProfile[rowIndex]?.customer_offer_posting_account_id
            ? "EDIT"
            : "NEW",
      };
    } else if (selectAccount === "Other") {
      tempAccounts[rowIndex] = {
        posting_account_id: apiData?.account_id,
        posting_account_name: apiData?.account_name,
        status: apiData.account_status || "-",
        from_date: formatDateApi(new Date(today)),
        to_date: null,
        action:
          debitProfile[rowIndex]?.customer_posting_account_id ||
          debitProfile[rowIndex]?.customer_offer_posting_account_id
            ? "EDIT"
            : "NEW",
      };
    } else {
      tempAccounts[rowIndex] = {
        posting_account_id: accountSeleted?.id,
        posting_account_name: accountSeleted?.name,
        status: account_status.status || "-",
        from_date: formatDateApi(new Date(today)),
        to_date: null,
        action:
          debitProfile[rowIndex]?.customer_posting_account_id ||
          debitProfile[rowIndex]?.customer_offer_posting_account_id
            ? "EDIT"
            : "NEW",
      };
    }

    setErrorState((prev) => {
      let temp = [...prev];
      temp = temp.filter((item) => item.field_name !== "posting_account");
      return [...temp];
    });
    setSelectAccount("");
    setApiData("");
    setAccountSearch("");
    setOpenEdit(false);
    setDebitProfile([...tempAccounts]);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow hover={false}>
          {headCells.map((headCell) => {
            return (
              <TableCell
                // rowSpan={2}
                // colSpan={}
                key={headCell.id}
                align={"left"}
                padding={"normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  // aria-rowspan={2}
                  disabled={true}
                  // disabled={disabledSort}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
  };

  const openModal = (mode) => {
    setModal(true);
  };

  const onClickOpenEditFormDate = (index) => {
    setRowIndex(index);
    setFormDateOpen(true);
  };

  const onClickOpenEditToDate = (index) => {
    setRowIndex(index);
    setToDateOpen(true);
  };

  const onClickOnCloseModal = () => {
    setSelectAccount("");
    setApiData("");
    setModal(false);
    setAccountSearch("");
  };

  const onClickEditModal = (row, index) => {
    setOpenEdit(true);
    // setCurrentRow(row);
    setRowIndex(index);
    setSelectAccount("");
    setApiData("");
    setAccountSearch("");
  };

  const HookPostingAccount = (input) => {
    if (input > 10000) {
      return input?.slice(input?.length - 10, input?.length);
    } else {
      return input;
    }
  };

  const onCloseFromDate = () => {
    setFormDateOpen(false);
  };

  const onCloseToDate = () => {
    setToDateOpen(false);
  };

  const DebitProfileTable = React.memo(() => {
    return (
      <React.Fragment>
        {stableSort(
          isEdit ? debitProfile : rows,
          getComparator(order, orderBy),
          orderBy,
          sortBy
        )
          ?.slice(page * ROW_PER_PAGE, page * ROW_PER_PAGE + ROW_PER_PAGE)

          ?.map((row, index) => {
            const isFuture =
              new Date(row?.from_date)?.getTime() > new Date()?.getTime();

            const isAccount =
              row?.customer_posting_account_id ||
              row?.customer_offer_posting_account_id > 0;

            const isTrueCondition = isAccount
              ? isFuture
                ? true
                : false
              : true;

            const CanEdit = isTrueCondition && isEdit;

            // const findIndex = debitProfile
            // ?.sort((a, b) => {
            //   return new Date(a?.from_date) - new Date(b?.from_date);
            // })
            // .findIndex(
            //   (i) => i.posting_account_id === row?.posting_account_id
            // );

            if (row?.action === "DELETE") {
              return null;
            } else {
              return (
                <TableRow
                  id="table-row"
                  key={index}
                  hover={selectable}
                  sx={
                    (TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
                    { backgroundColor: "#00000014" })
                  }
                >
                  <TableCell
                    // onClick={() => onClickRow && CanEdit && onClickRow(row)}
                    onClick={() => CanEdit && onClickEditModal(row, index)}
                    id="table-cell-id"
                    align="left"
                  >
                    <button className={CanEdit ? "text-textscb" : ""}>
                      {HookPostingAccount(row?.posting_account_id)}
                    </button>
                  </TableCell>
                  <TableCell id="table-cell-name" align="left">
                    {row.posting_account_name}
                  </TableCell>
                  <TableCell id="table-cell-status" align="left">
                    {row.status}
                  </TableCell>
                  <TableCell
                    onClick={() => CanEdit && onClickOpenEditFormDate(index)}
                    id="table-cell-from"
                    align="left"
                  >
                    <button className={CanEdit ? "text-textscb" : ""}>
                      {formateDate(row.from_date)}
                    </button>
                  </TableCell>
                  <TableCell
                    onClick={() => isEdit && onClickOpenEditToDate(index)}
                    id="table-cell-to"
                    align="left"
                  >
                    <button className={isEdit ? "text-textscb" : ""}>
                      {row?.to_date === NULL_DATE
                        ? "-"
                        : formateDate(row.to_date)}
                    </button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "25px" }}
                    id="table-cell-to"
                    align="left"
                  >
                    {CanEdit ? (
                      <img
                        onClick={(e) =>
                          deleteRow(e, index, row?.posting_account_id)
                        }
                        src={DeleteIcon}
                        className={
                          CanEdit ? "text-textscb h-15px w-15px" : "hidden"
                        }
                      />
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          })}
      </React.Fragment>
    );
  });

  const IsNonDebitProfile = React.memo(() => {
    return (
      <React.Fragment>
        <TableRow
          id="non-table-row"
          onClick={() => (isEdit || isNew ? openModal("create") : () => {})}
          sx={
            (TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
            { backgroundColor: "#00000014" })
          }
        >
          <TableCell id="table-cell-id" align="left">
            <button className={isEdit ? "text-textscb" : ""}>
              {isEdit || isNew ? " Select Account" : "-"}
            </button>
          </TableCell>
          <TableCell id="table-cell-name" align="left">
            -
          </TableCell>
          <TableCell id="table-cell-status" align="left">
            -
          </TableCell>
          <TableCell id="table-cell-from" align="left">
            -
          </TableCell>
          <TableCell id="table-cell-to" align="left">
            -
          </TableCell>
          <TableCell
            sx={{ width: "25px" }}
            id="table-cell-to"
            align="left"
          ></TableCell>
        </TableRow>
        {/* {isEdit && rows?.length === 0 && (
          <div className="text-red-500 text-sm">
            Please enter at least one account
          </div>
        )} */}
      </React.Fragment>
    );
  });

  const getPrevIndexWithOutDelete = (index, account = []) => {
    let currentIndex = index;

    if (currentIndex - 1 < 0 || currentIndex === 0) {
      return -1;
    }

    for (let newIndex = currentIndex - 1; newIndex >= 0; newIndex--) {
      const profile = account[newIndex];

      if (profile?.action !== "DELETE") {
        currentIndex = newIndex;
        break;
      }

      if (newIndex === 0) {
        currentIndex = -1;
        break;
      }
    }

    return currentIndex;
  };

  return (
    <div>
      <div className="border-t border-b">
        <TableContainer>
          {searched?.length >= 3 && rows?.length <= 0 ? (
            <NoRecordFound />
          ) : (
            <Table
              sx={TABLE_STYLE_WITH_HOVER_STANDARD_WITDH}
              aria-label="simple table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                rowCount={isEdit ? debitProfile?.length : rows?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {/* <DebitProfileTable /> */}
                {isDebitProfileHaveLength > 0 ? (
                  <DebitProfileTable />
                ) : (
                  <IsNonDebitProfile />
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
      {isEdit && (
        // isDebitProfileHaveLength !== 2 &&
        <FooterButton
          text="Add Future Debit Profile"
          action={() => openModal("create")}
        />
      )}
      <AccountModal
        type="NEW_ACCOUNT"
        open={modal}
        title={"Select Account"}
        action={addNewRow}
        textButton="Confirm"
        onClose={onClickOnCloseModal}
        accountTable={accountTable}
        setSelectAccount={setSelectAccount}
        selectAccount={selectAccount}
        debitProfile={debitProfile}
        apiData={apiData}
        setApiData={setApiData}
        accountSearch={accountSearch}
        setAccountSearch={setAccountSearch}
      />

      <AccountModal
        type="EDIT_ACCOUNT"
        selectAccount={selectAccount}
        open={openEdit}
        title={"Edit Account"}
        action={onClickEditRow}
        setSelectAccount={setSelectAccount}
        textButton="Confirm"
        onClose={() => setOpenEdit(false)}
        accountTable={accountTable}
        debitProfile={debitProfile}
        apiData={apiData}
        setApiData={setApiData}
        accountSearch={accountSearch}
        setAccountSearch={setAccountSearch}
      />
      <PBFESelectFromDateModal
        open={formDateOpen}
        title={"Select From Date"}
        onClose={() => onCloseFromDate()}
        accountTable={accountTable}
        setSelectAccount={setSelectAccount}
        setDebitProfile={setDebitProfile}
        selectAccount={selectAccount}
        debitProfile={debitProfile[rowIndex]}
        index={rowIndex}
        previousDate={
          debitProfile[getPrevIndexWithOutDelete(rowIndex, debitProfile)]
        }
        // previousDate={debitProfile[rowIndex - 1] || debitProfile[rowIndex + 1]}
        setErrorState={setErrorState}
        // errorState={errorState}
        profiles={debitProfile}
      />
      <PBFESelectToDateModal
        open={toDateOpen}
        title={"Select To Date"}
        onClose={() => onCloseToDate()}
        accountTable={accountTable}
        setSelectAccount={setSelectAccount}
        setDebitProfile={setDebitProfile}
        selectAccount={selectAccount}
        debitProfile={debitProfile[rowIndex]}
        index={rowIndex}
        setErrorState={setErrorState}
        profiles={debitProfile}
        // errorState={errorState}
        previousDate={debitProfile[rowIndex + 1]}
      />
    </div>
  );
};
