import {
  MIN_VALUE,
  MAX_VALUE,
  FEE_TABLE_TYPE,
  STEP,
  NULL_DATE,
} from "../constants/constant";
import { format } from "date-fns";
import {
  checkDateInvalid,
  checkFromToDate,
  FunctionCheckDateLength,
  FunctionCheckDateValidCustom,
  TEXT_FIELD_ERROR_DATE_FUTURE,
} from "../functions";
import _ from "lodash";
import DownloadIcon from "../assets/icon/download.svg";
import ErrorIcon from "../assets/icon/error.svg";
import uploadService from "../api/uploadService";
import { useEffect, useState } from "react";
import reportService from "../api/reportService";
import FileSaver from "file-saver";
import { AlertModal } from "../components/Panel/Modal/alertModal";
import { useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";

export const checkInvalidPast = (currentDate = new Date()) => {
  const today = formatDateApi();
  const currentFormatDate = formatDateApi(new Date(currentDate));

  if (today > currentFormatDate) {
    return true;
  }

  return false;
};

export const adjustStringLength = (str, length = 999) => {
  if (str.length <= length) return str;
  return str.substring(0, 17) + "...";
};

export const TwoLineColumn = (context) => {
  const processContext = context.split("|");

  if (processContext.length < 1) return <div></div>;
  return (
    <div>
      <div>{processContext[0]}</div>
      {processContext.length > 1 && (
        <Tooltip title={processContext[1]}>
          <div className="text-textscbdark text-label">
            {" "}
            {adjustStringLength(processContext[1])}{" "}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const formatNumber = (value) => {
  if (value === MIN_VALUE) return "MIN";
  else if (value === MAX_VALUE) return "MAX";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const mappingValue = (value, defaultValue) => {
  if (value) {
    return value;
  }
  return defaultValue;
};

export const formatNumberComma = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getClearValueObject = (data) => {
  const keys = Object.keys(data);
  let newObject = { value: [] };

  keys.forEach((key) => {
    if (data[key]) {
      if (key === "qualifiers") {
        const keyQualifers = Object.keys(data.qualifiers);
        keyQualifers.forEach((qualifier) => {
          newObject = {
            ...newObject,
            qualifiers: {
              ...newObject?.qualifiers,
              [qualifier]: [],
            },
          };
        });
      } else if (key === "value") {
        data.value.forEach((val) => {
          const keyValue = Object.keys(val);
          keyValue.forEach((valKey) => {
            newObject = {
              ...newObject,
              value: [{ ...val[valKey], [valKey]: "" }],
            };
          });
        });
      } else {
        newObject = {
          ...newObject,
          [key]: "",
        };
      }
    }
  });

  return newObject;
};

export const getWordSpaceUppercase = (word) => {
  const splitWord = word.split(" ");
  let newWord = "";
  if (splitWord.length > 1) {
    splitWord.forEach((itemSplitWord) => {
      newWord += `${_.capitalize(itemSplitWord)} `;
    });
    return newWord;
  }

  return _.capitalize(word);
};

export const formatEmpty = (value) => {
  if (value === 0) {
    return 0;
  }
  if (!value) {
    return "-";
  }
  return value;
};

export const formatNumberMinReverse = (value) => {
  if (value === "MIN") return 0;
  return value;
};

export const formatNumberMaxReverse = (value) => {
  if (value === "MAX") return 0;
  return value;
};

export const formatNumberMin = (value, disabledComma = false) => {
  if (value === MIN_VALUE) {
    return "MIN";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const formatNumberMax = (value, disabledComma = false) => {
  if (value >= MAX_VALUE) {
    return "MAX";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const convertRequestType = (type) => {
  let convert_type = "";

  if (type === "New Customer / Account Pricelist") {
    convert_type = "NEWSERVICE";
  } else if (type === "New Customer / Account Offer") {
    convert_type = "NEWOFFER";
  } else if (type === "Update Customer / Account Pricelist") {
    convert_type = "UPDATESERVICE";
  } else if (type === "Update Customer / Account Offer") {
    convert_type = "UPDATEOFFER";
  }

  return convert_type;
};

export const isStepTier = (value) => {
  return value === STEP || value === "asd";
};

export const isValidDate = (date) => {
  if (date === NULL_DATE) return true;
  const expireDate = Date.parse(date);
  const now = new Date().setHours(0, 0, 0, 0, 0);
  if (expireDate < now) return false;
  return true;
};

export const formatField = (value) => {
  if (value === undefined) return "-";
  if (!isNaN(value)) return formatNumber(value);
  if (value?.includes("T00:00:00")) return formateDate(value);
  return value;
};

export const fomat = (value, type = 0) => {};

export const calculateRowSpan = (value) => {
  return value + 1;
};

export const formatPercent = (value) => {
  return value + "%";
};

export const formatNone = (value) => {
  return value;
};

export const checkValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const convertErrorApi = (message = "") => {
  const splitMessage = message.split(":");
  if (splitMessage.length > 1) {
    return splitMessage[1];
  }

  return splitMessage[0];
};

export const getDateOnly = (date = "") => {
  const myDate = date.split("T");
  if (myDate.length > 0) {
    return myDate[0].slice(0, 10);
  }
  return date;
};

export const getValidateOfferError = (value) => {
  const listError = [];

  value.from_date = getDateOnly(value.from_date);
  value.to_date = getDateOnly(value.to_date);

  const validateLengthValidFromDate = FunctionCheckDateLength(
    value.from_date,
    "from_date"
  );

  const validateLengthValidToDate = FunctionCheckDateLength(
    value.to_date,
    "to_date"
  );

  const validateDateValidFromDate = FunctionCheckDateValidCustom(
    value.from_date,
    "from_date"
  );

  const validateDateValidToDate = FunctionCheckDateValidCustom(
    value.to_date,
    "to_date"
  );

  let getErrorFromDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "from_date"
  );

  let getErrorToDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "to_date"
  );

  console.log("validateLengthValidFromDate", validateLengthValidFromDate);
  console.log("validateLengthValidToDate", validateLengthValidToDate);
  console.log("validateDateValidFromDate", validateDateValidFromDate);
  console.log("validateDateValidToDate", validateDateValidToDate);
  console.log("getErrorFromDate", getErrorFromDate);
  console.log("getErrorToDate", getErrorToDate);

  if (
    validateLengthValidFromDate.error ||
    validateLengthValidToDate.error ||
    validateDateValidFromDate.error ||
    validateDateValidToDate.error ||
    getErrorFromDate ||
    getErrorToDate
  ) {
    listError.push(true);
  }

  return listError;
};

export const getCheckValidateThreeMonth = (from, to) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  if (dateTo.getMonth() - dateFrom.getMonth() > 3) {
    return true;
  }

  return false;
};

export const isValidErrorApi = (error) => {
  if (error && error.response.data.status.description) {
    return true;
  }
  return false;
};

export const formatDateApi = (dateString = new Date(), nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  // if (dateString === "9999-12-31T00:00:00") return "";
  // if (!checkValidDate(new Date(dateString))) {
  //   dateString = new Date();
  // }
  // let splitTime = new Date(dateString).toISOString()?.split("T");
  // let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  // let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  // return dateSplit[0] + "-" + dateSplit[1] + "-" + dateSplit[2] + "T00:00:00";

  const currentDate = `${format(new Date(dateString), "yyyy-MM-dd")}T00:00:00`;
  return currentDate;
};

export const formateDate = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateWithTime = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return (
    dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] + " " + splitTime[1]
  );
};

export const formateDateWithTimeHourMintues = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  return (
    dateSplit[2] +
    "/" +
    dateSplit[1] +
    "/" +
    dateSplit[0] +
    " " +
    timeSplit[0] +
    ":" +
    timeSplit[1]
  );

  // return format(new Date(dateString), "dd/MM/yyyy HH:mm");
};

export const checkFeeChargeAllDelete = (fee_charge = []) => {
  return fee_charge.every((charge) => charge?.action === "DELETE");
};

export const onScrollToTop = (id) => {
  const doc = document.getElementById(id);
  if (doc) {
    window.scrollTo(0, -100);
  }
};

export const getFeeChargeOfGroup = (charge_group = []) => {
  let fee_charge = [];
  charge_group?.forEach((charge) => {
    fee_charge = [...fee_charge, ...charge.fee_charge];
  });

  return fee_charge;
};

export const ColorStatus = (status) => {
  if (status === "Pending")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "Approved")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  if (status === "Closed" || status === "Rejected") {
    return <div className="bg-gray-300 rounded-full w-9px h-9px"></div>;
  }
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const ReportColorStatus = (status) => {
  if (_.upperCase(status) === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (_.upperCase(status) === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const UploadColorStatus = (status) => {
  if (status === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "PROCESSED" || status === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const DownloadCell = (record) => {
  const [openError, setOpenError] = useState(false);
  const [errorApi, setReturnErrorApi] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setOpenError(false);
      setReturnErrorApi("");
    }, 1000);
  }, [openError]);

  const onClickDownLoad = async () => {
    try {
      const response = await uploadService.getUploadDownload(
        record.id,
        `?type=${record.type}`
      );

      if (response.status === 200) {
        let filename = response.headers["content-disposition"].replace(
          "attachment; filename=",
          ""
        );
        filename = filename.replace(/['"]+/g, "");
        downloadFile(response.data, filename);
      } else {
        setReturnErrorApi(response?.statusText);
        setOpenError(true);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  if (record?.status !== "PROCESSED") {
    return null;
  }

  return (
    <div
      onClick={onClickDownLoad}
      target={"_blank"}
      className="flex text-textscb cursor-pointer"
    >
      <AlertModal
        open={openError}
        title={"Error"}
        description={errorApi}
        action={() => setOpenError(false)}
      />
      <img className="mr-4" src={DownloadIcon}></img>
      {record.amount}
    </div>
  );
};

export const downloadFile = (fileBlob, filename) => {
  const blob = new Blob([fileBlob], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, filename);
};

export const validateFormatDate = (date) => {
  var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

  const myDate = new Date(date);
  const year = myDate.getFullYear();

  if (String(year).length < 4) {
    return true;
  }

  return false;
};

export const formatAction = (action) => {
  return <div>{_.capitalize(action)}</div>;
};

export const DownloadCellCommonWithOutText = (record) => {
  const [openError, setOpenError] = useState(false);
  const [errorApi, setReturnErrorApi] = useState("");

  const onClickDownLoad = async () => {
    try {
      if (record.status === "FAILED") {
        setOpenError(true);
        return;
      }

      const response = await reportService.downloadReport(record.request_id);
      let filename = response.headers["content-disposition"].replace(
        "attachment; filename=",
        ""
      );
      return downloadFile(response.data, filename);
    } catch (error) {
      if (error.response.status === 400) {
        setReturnErrorApi(convertErrorApi("Data not found"));
        setOpenError(true);
      }
    }
  };

  if (record === undefined || record === null || record === "") {
    return <></>;
  }

  if (record.status === "FAILED") {
    return (
      <div>
        <AlertModal
          open={openError}
          title={"Priced Transaction"}
          description={record.fail_reason}
          action={() => setOpenError(false)}
        />
        <img onClick={onClickDownLoad} className="" src={ErrorIcon} />
      </div>
    );
  }

  if (record.status !== "SUCCESS") {
    return null;
  }

  return (
    <div>
      <AlertModal
        open={openError}
        title={"Priced Transaction"}
        description={errorApi}
        action={() => setOpenError(false)}
      />
      <div
        onClick={onClickDownLoad}
        target={"_blank"}
        className="flex text-textscb cursor-pointer"
      >
        <img className="" src={DownloadIcon}></img>
      </div>
    </div>
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const DownloadCellCommon = (record) => {
  const onClickDownLoad = async () => {
    try {
      const response = await reportService.downloadReport(record.request_id);
      let filename = response.headers["content-disposition"].replace(
        "attachment; filename=",
        ""
      );
      downloadFile(response.data, filename);
    } catch (error) {
      console.log("download error", error);
    }
  };

  return (
    <div
      onClick={onClickDownLoad}
      target={"_blank"}
      className="flex text-textscb"
    >
      <img className="mr-4" src={DownloadIcon}></img>
      DOWNLOAD
    </div>
  );
};

export const tableBorderBottomStyle = (type) => {
  return {
    borderBottom: type === FEE_TABLE_TYPE.Report ? "" : "4px solid white",
  };
};

export const isRefreshTokenNearlyExpired = () => {};

export const saveTokenToLocalStorage = (data) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("expired_at", getExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    getExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    getExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
};

export const getExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const getUserFromStorage = () => {
  let data = { user: null };

  let getUserStorage = sessionStorage.getItem("user");
  if (getUserStorage) {
    data.user = JSON.parse(getUserStorage).user;
  }
  return data.user;
};

export const getTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
    info: parseJwt(access_token),
  };
};

export const saveUserLogin = (isLoggedIn) => {
  if (isLoggedIn) {
    sessionStorage.setItem("isLoggedIn", true);
  } else {
    sessionStorage.removeItem("isLoggedIn");
  }
};

export const checkUserLogin = () => {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  return isLoggedIn !== undefined && isLoggedIn !== null;
};

export const parseJwt = (token) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkCurrentDate = (
  from_date = new Date(),
  to_date = new Date()
) => {
  const today = new Date();
  const formatToday = format(today, "yyyy-MM-dd");
  const formatFromDate = format(from_date, "yyyy-MM-dd");
  return formatToday >= formatFromDate;
};

export const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

export const checkDebitProfileFutureDate = (currentRow = 0, profiles = []) => {
  let currentIndex = 0;

  if (profiles.length > 1) {
    if (currentRow === 0) {
      currentIndex = 1;
    } else if (currentRow === 1) {
      currentIndex = 0;
    }

    const notCurrentProfile = profiles[currentIndex];
    const currentProfile = profiles[currentRow];

    // console.log("notCurrentProfile", notCurrentProfile);

    if (
      formatDateApi(new Date(currentProfile.from_date)) >=
        formatDateApi(new Date(notCurrentProfile.from_date)) &&
      formatDateApi(new Date(currentProfile.from_date)) <=
        formatDateApi(new Date(notCurrentProfile.to_date))
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const checkErrorDate = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService.charge_group.forEach((group, groupIndex) => {
    group.fee_charge
      .filter(
        (item) => item?.isRemove === false && item.hasOwnProperty("isRemove")
      )
      .forEach((charge, index) => {
        const filter_not_self = group.fee_charge
          .filter((localCharge, f_index) => {
            return (
              localCharge?.isRemove === false &&
              localCharge?.hasOwnProperty("isRemove")
            );
          })
          .filter((item, f_index) => f_index !== index);

        const self_from_date = new Date(charge.from_date);
        const self_to_date = new Date(charge.to_date);

        if (filter_not_self.length > 0) {
          for (let f_i = 0; f_i < filter_not_self.length; f_i++) {
            const not_self = filter_not_self[f_i];
            const not_self_from_date = new Date(not_self.from_date);
            const not_self_to_date = new Date(
              not_self.to_date === "9999-12-31T00:00:00" ||
              not_self.to_date === "" ||
              not_self.to_date === null
                ? "9999-12-31"
                : not_self.to_date
            );

            if (
              !checkDateInvalid(self_from_date) &&
              !checkDateInvalid(not_self_from_date) &&
              !checkDateInvalid(not_self_to_date)
            ) {
              if (
                formatDateApi(self_from_date) >=
                  formatDateApi(not_self_from_date) &&
                formatDateApi(self_from_date) <= formatDateApi(not_self_to_date)
              ) {
                checkErrorList = [
                  ...checkErrorList,
                  {
                    index: index,
                    groupIndex,
                    error: true,
                    message: `${String(
                      group.charge_code
                    ).toUpperCase()} ${TEXT_FIELD_ERROR_DATE_FUTURE}`,
                    field_name: "overlap_date",
                    field_value: [],
                    show: true,
                  },
                ];
                return;
              }
            }
          }
        }
      });
  });
  return checkErrorList;
};

export const returnMessageDuplicate = (index, groupIndex, group, priority) => {
  return {
    index: index,
    groupIndex,
    error: true,
    message: `Charge Code ${group.charge_code} priority ${priority} is duplicate`,
    field_name: "priority",
    field_value: priority,
    show: true,
  };
};

const toFindDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index);

export const checkDuplicatePriority = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService?.charge_group.forEach((group, groupIndex) => {
    group?.fee_charge?.forEach((charge, index) => {
      const fee_value = charge.fee_value;
      const hasQualifer = fee_value.hasOwnProperty("conditions");
      if (hasQualifer) {
        let tempPriority = [];
        tempPriority = fee_value.conditions.map((condition) => {
          const hasPriority = condition.hasOwnProperty("priority");
          if (hasPriority && condition?.priority !== "") {
            return String(condition.priority);
          }
          return null;
        }, []);

        const uniquePriority = toFindDuplicates(
          tempPriority.filter((item) => item !== null)
        );
        checkErrorList = [
          ...checkErrorList,
          ...uniquePriority.map((item) => {
            return {
              ...returnMessageDuplicate(index, groupIndex, group, item),
            };
          }),
        ];
      }
    });
  });

  return checkErrorList;
};

export const getErrorToDateMax = (debitProfile = []) => {
  if (debitProfile.length > 0) {
    const prevTodate = formatDateApi(
      debitProfile[debitProfile.length - 1].to_date,
      "9999-12-31T00:00:00"
    );
    if (
      prevTodate === "9999-12-31T00:00:00" ||
      prevTodate === null ||
      prevTodate === ""
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const formatDateCheckValid = (date) => {
  if (date) {
    const thisDate = new Date(date);
    return (
      thisDate?.getFullYear() +
      "-" +
      pad(thisDate?.getMonth() + 1) +
      "-" +
      pad(thisDate?.getDate())
    );
  } else {
    return "";
  }
};

export const formateDateWithOutNullValue = (dateString) => {
  if (dateString === "" || dateString === null || !dateString) return "";
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateWithTimeHourMintuesOneline = (dateString) => {
  return (
    <div className="w-28">{formateDateWithTimeHourMintues(dateString)}</div>
  );
};
