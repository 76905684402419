import { format } from "date-fns";
import _ from "lodash";
import {
  MAX_VALUE,
  MIN_VALUE,
  NULL_DATE,
  RATE_TYPE,
  RATE_TYPE_VALUE,
} from "../constants/constant";
import {
  formatDateApi,
  formatNumberMaxReverse,
  formatNumberMinReverse,
  isValidDate,
} from "./util";

export const mappingEmptyValue = (value, defaultValue = "") => {
  if (value) {
    return value;
  }
  return defaultValue;
};

export const mappingServicePositingAccount = (account) => {
  let from_date = account?.from_date;
  let to_date = account?.to_date;
  let action = account?.action;
  let posting_account_id =
    account?.customer_posting_account_id ||
    account?.customer_offer_posting_account_id;

  const newObj = {
    posting_account_id: account?.posting_account_id,
    posting_account_name: account?.posting_account_name,
    status: account?.status,
    from_date: formatDateApi(from_date),
    to_date: formatDateApi(to_date, NULL_DATE),
    action: action,
  };

  if (account.customer_posting_account_id) {
    newObj.customer_posting_account_id = account.customer_posting_account_id;
  }

  if (account.customer_offer_posting_account_id) {
    newObj.customer_posting_account_id =
      account.customer_offer_posting_account_id;
  }

  return newObj;
};

export const mappingServiceChargeGroup = (group) => {
  return {
    charge_code: group.charge_code,
    charge_name: group.charge_name,
  };
};

export const mappingServiceFeeCharge = (charge) => {
  let price_without_post = charge?.price_without_post;

  if (charge?.price_without_post_checked) {
    price_without_post = "Y";
  } else {
    price_without_post = "N";
  }

  let from_date = formatDateApi(charge?.from_date);
  let to_date = formatDateApi(charge?.to_date, NULL_DATE);

  const newObj = {
    customer_fee_charge_id: charge?.customer_fee_charge_id || "",
    charge_code: charge?.charge_code,
    charge_name: charge?.charge_name,
    from_date: from_date,
    to_date: to_date,
    posting_consolidation: charge?.posting_consolidation,
    pricing_frequency: charge?.pricing_frequency,
    price_without_post: price_without_post,
    pricing_method: charge?.pricing_method,
    transaction_consolidation: charge?.transaction_consolidation,
    vat_type: charge?.vat_type,
    wht_type: charge?.wht_type,
    qualifiers: charge?.qualifiers || null,
  };

  return newObj;
};

export const mappingServiceFeeValue = (fee_value, charge) => {
  let new_object = {
    max: fee_value?.max === "" ? NaN : Number(fee_value?.max),
    min: fee_value?.min === "" ? NaN : Number(fee_value?.min),
    tier: fee_value?.tier,
    priority: fee_value?.priority ? NaN : Number(charge?.priority),
  };

  if (_.isNaN(new_object.min) || new_object.min === undefined) {
    delete new_object.min;
  }

  if (_.isNaN(new_object.max) || new_object.max === undefined) {
    delete new_object.max;
  }

  if (_.isNaN(new_object.priority) || new_object.priority === undefined) {
    delete new_object.priority;
  }

  return new_object;
};

export const mappingKeyNull = (
  dataObj = {},
  newDataObj = {},
  listKeys = []
) => {
  listKeys.forEach((key) => {
    if (!dataObj?.hasOwnProperty(key)) {
      delete newDataObj[key];
    }
  });
  return newDataObj;
};

export const mappingServiceCondition = (condition) => {
  let new_object = {
    min: condition?.min === "" ? NaN : Number(condition?.min),
    max: condition?.max === "" ? NaN : Number(condition?.max),
    priority: condition?.priority === "" ? NaN : Number(condition?.priority),
    qualifiers: condition?.qualifiers,
    tier: condition?.tier,
    value: mappingEmptyValue(condition.value, []).map((value) => {
      return mappingServiceValue(value);
    }),
  };

  if (
    _.isNaN(new_object?.min) ||
    new_object?.min === undefined ||
    new_object?.min === null
  ) {
    delete new_object.min;
  }

  if (
    _.isNaN(new_object?.max) ||
    new_object?.max === undefined ||
    new_object?.max === null
  ) {
    delete new_object.max;
  }

  if (
    _.isNaN(new_object?.priority) ||
    new_object?.priority === undefined ||
    new_object?.priority === null
  ) {
    delete new_object.priority;
  }

  return new_object;
};

export const mappingServiceValueRateType = (rate_type) => {
  switch (rate_type) {
    case RATE_TYPE.PERCENT:
    case RATE_TYPE_VALUE["%"]:
      return RATE_TYPE_VALUE["%"];
    case RATE_TYPE.THB:
      return RATE_TYPE_VALUE.THB;
    case RATE_TYPE.POINT:
    case RATE_TYPE_VALUE.POINT:
        return RATE_TYPE_VALUE.POINT;
    default:
      return "";
  }
};

export const mappingServiceValue = (value) => {
  if (String(value.to).toUpperCase() === "MAX") {
    value.to = MAX_VALUE;
  } else {
    value.to = Number(value.to);
  }

  if (String(value.from).toUpperCase() === "MIN") {
    value.from = MIN_VALUE;
  } else {
    value.from = Number(value.from);
  }

  if (value.fix_amount === "") {
    value.fix_amount = NaN;
  } else {
    value.fix_amount = Number(value.fix_amount);
  }

  if (value.rate === "") {
    value.rate = NaN;
  } else {
    value.rate = Number(value.rate);
  }

  if (!value.rate_type) {
    value.rate_type = NaN;
  } else {
    value.rate_type = mappingServiceValueRateType(value.rate_type);
  }

  // console.log("value", value);

  let new_object = {
    to: Number(value.to) >= 0 ? Number(value.to) : value.to,
    from: Number(value.from) >= 0 ? Number(value.from) : value.from,
    rate: Number(value.rate) >= 0 ? Number(value.rate) : value.rate,
    fix_amount:
      Number(value.fix_amount) >= 0
        ? Number(value.fix_amount)
        : value.fix_amount,
    min: Number(value.min) >= 0 ? Number(value.min) : value.min,
    max: Number(value.max) >= 0 ? Number(value.max) : value.max,
    max_tier:
      Number(value.max_tier) >= 0 ? Number(value.max_tier) : value.max_tier,
    min_tier:
      Number(value.min_tier) >= 0 ? Number(value.min_tier) : value.min_tier,
    rate_type: value.rate_type,
  };

  if (_.isNaN(new_object.rate) || new_object.rate === undefined) {
    delete new_object.rate;
  }

  if (_.isNaN(new_object.fix_amount) || new_object.fix_amount === undefined) {
    delete new_object.fix_amount;
  }

  if (_.isNaN(new_object.rate) || new_object.rate === undefined) {
    delete new_object.rate;
  }

  if (_.isNaN(new_object.rate_type) || new_object.rate_type === undefined) {
    delete new_object.rate_type;
  }

  if (_.isNaN(new_object.from) || new_object.from === undefined) {
    delete new_object.from;
  }

  if (_.isNaN(new_object.to) || new_object.to === undefined) {
    delete new_object.to;
  }

  if (_.isNaN(new_object.min) || new_object.min === undefined) {
    delete new_object.min;
  }

  if (_.isNaN(new_object.max) || new_object.max === undefined) {
    delete new_object.max;
  }

  if (_.isNaN(new_object.min_tier) || new_object.min_tier === undefined) {
    delete new_object.min_tier;
  }

  if (_.isNaN(new_object.max_tier) || new_object.max_tier === undefined) {
    delete new_object.max_tier;
  }

  return new_object;
};

export const mappingFeeCharge = (charge) => {
  return {
    ...mappingServiceFeeCharge(charge),
    fee_value:
      charge?.fee_value?.conditions?.length > 0
        ? {
            conditions: mappingEmptyValue(
              charge?.fee_value?.conditions,
              []
            ).map((condition) => {
              return {
                ...mappingServiceCondition(condition),
                ...mappingServiceFeeValue(condition, charge),
              };
            }),
          }
        : {
            ...mappingServiceFeeValue(charge?.fee_value, charge),
            value: mappingEmptyValue(charge?.fee_value?.value, []).map(
              (value) => {
                return mappingServiceValue(value);
              }
            ),
          },
  };
};

export const mappingUndefinedDelete = (charge = []) => {
  return charge.map((item) => {
    if (item.action === undefined) {
      return {
        ...item,
        action: "DELETE",
      };
    }

    return {
      ...item,
    };
  });
};

export const mappingServiceEditApprover = (service, dataRequest = {},remark="") => {
  let before_charge = [];
  let after_charge = [];
  let update_after_charge = [];

  const originalData = sessionStorage.getItem("originalData");
  const parseOriginalData = JSON.parse(originalData);

  const currentService = mappingEmptyValue(parseOriginalData?.service, []).find(
    (originalService) =>
      String(originalService?.customer_fee_service_id) ===
      String(service?.customer_fee_service_id)
  );

  // หา before
  service.charge_group.forEach((group, index) => {
    for (let jedex = 0; jedex < group.fee_charge.length; jedex++) {
      const charge = group.fee_charge[jedex];
      if (charge.action) {
        if (charge?.isTemplate === true && charge?.hasOwnProperty("isTemplate"))
          return;
        const mappingCharge = currentService.charge_group[index].fee_charge.map(
          (currentCharge) => {
            delete currentCharge?.isRemove;
            delete currentCharge?.isError;
            delete currentCharge?.checkCurrentDate;

            return {
              ...currentCharge,
              charge_code: group.charge_code,
              charge_name: group.charge_name,
            };
          }
        );
        before_charge = [...before_charge, ...mappingCharge];
        break;
      }
    }
  });

  // หา after
  let tempChargeGroup = [];

  service?.charge_group.forEach((group, index) => {
    for (let jedex = 0; jedex < group.fee_charge.length; jedex++) {
      const charge = group.fee_charge[jedex];
      if (charge.action) {
        tempChargeGroup = [...tempChargeGroup, group];
        break;
      }
    }
  });

  tempChargeGroup.forEach((group, index) => {
    group.fee_charge.forEach((charge) => {
      let currentCharge = {
        charge_code: group.charge_code,
        charge_name: group.charge_name,
        ...charge,
      };

      if (currentCharge.action === undefined) {
        currentCharge.action = "";
      }

      if (currentCharge.action !== "DELETE") {
        let newAction = currentCharge.action;
        if (currentCharge.customer_fee_charge_id === "") {
          newAction = "NEW";
        }
        after_charge.push({
          ...mappingFeeCharge(currentCharge),
          action: newAction,
        });
      }

      if (currentCharge.customer_fee_charge_id > 0) {
        if (["DELETE", "EDIT"].includes(currentCharge.action)) {
          update_after_charge.push({
            ...mappingFeeCharge(currentCharge),
            action: currentCharge.action,
          });
        }
      } else {
        if (currentCharge.action !== "DELETE") {
          update_after_charge.push({
            ...mappingFeeCharge(currentCharge),
            action: "NEW",
          });
        }
      }
    });
  });

  // console.log("before charge", before_charge);
  // console.log("after charge", after_charge);
  // console.log("update_after_charge", update_after_charge);

  // mappingEmptyValue(service?.charge_group, [])?.forEach(
  //   (charge_group, index) => {
  //     mappingEmptyValue(charge_group?.fee_charge, []).forEach(
  //       (charge, jedex) => {
  //         // console.log("charge", charge);
  //         const currentCharge = currentService?.charge_group[
  //           index
  //         ].fee_charge?.find(
  //           (currentCharge) =>
  //             currentCharge.customer_fee_charge_id ===
  //             charge.customer_fee_charge_id
  //         );

  //         const currentJsonString = JSON.stringify(
  //           currentService?.charge_group[index].fee_charge[jedex]
  //         );
  //         const newJsonString = JSON.stringify(charge);

  //         if (charge?.isNewAdded) {
  //           charge.action = "NEW";
  //         }

  //         if (
  //           charge?.customer_fee_charge_id === "" &&
  //           charge?.action !== "DELETE"
  //         ) {
  //           charge.action = "NEW";
  //         }

  //         if (
  //           charge?.customer_fee_charge_id === "" &&
  //           charge?.action === "DELETE"
  //         ) {
  //           delete charge.action;
  //         }

  //         const sameCharge = {
  //           customer_fee_charge_id:
  //             charge.customer_fee_charge_id === ""
  //               ? ""
  //               : charge.customer_fee_charge_id,
  //           customer_fee_service_id: service.customer_fee_service_id,
  //           charge_code: charge_group?.charge_code,
  //           charge_name: charge_group?.charge_name,
  //           action: charge.action,
  //         };

  //         delete charge?.checkCurrentDate;
  //         delete currentCharge?.checkCurrentDate;

  //         // check delete before
  //         if (charge?.action === "DELETE" || charge?.action === "NEW") {
  //           const notCurrentCharge = currentService?.charge_group[
  //             index
  //           ].fee_charge?.find(
  //             (currentCharge) =>
  //               currentCharge.customer_fee_charge_id !==
  //               charge.customer_fee_charge_id
  //           );

  //           const checkHasEdit = before_charge.some(
  //             (beCharge) =>
  //               beCharge.customer_fee_charge_id ===
  //                 notCurrentCharge.customer_fee_charge_id &&
  //               beCharge.action === "EDIT"
  //           );

  //           if (!checkHasEdit && notCurrentCharge?.customer_fee_charge_id) {
  //             before_charge.push({
  //               ...mappingFeeCharge(notCurrentCharge),
  //               customer_fee_charge_id:
  //                 notCurrentCharge?.customer_fee_charge_id,
  //               customer_fee_service_id: service?.customer_fee_service_id,
  //               charge_code: charge_group?.charge_code,
  //               charge_name: charge_group?.charge_name,
  //               action: "CURRENT",
  //             });
  //           }
  //         }

  //         if (charge.action === "NEW") {
  //           before_charge.push({
  //             ...mappingFeeCharge(currentCharge),
  //             ...sameCharge,
  //           });
  //           after_charge.push({
  //             ...mappingFeeCharge(charge),
  //             ...sameCharge,
  //           });
  //         } else {
  //           if (currentJsonString !== newJsonString) {
  //             if (
  //               (charge.action === "DELETE" &&
  //                 charge.customer_fee_charge_id !== "") ||
  //               (charge.action === "EDIT" &&
  //                 charge.customer_fee_charge_id !== "")
  //             ) {
  //               before_charge.push({
  //                 ...mappingFeeCharge(currentCharge),
  //                 ...sameCharge,
  //               });
  //               after_charge.push({
  //                 ...mappingFeeCharge(charge),
  //                 ...sameCharge,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     );
  //   }
  // );

  let before_debit = [];
  let after_debit = [];
  let update_after_debit = [];

  let checkSomeAccount = service?.posting_account.some((debit_account) => {
    return debit_account.action;
  });

  service.posting_account?.forEach((debit_account, index) => {
    if (checkSomeAccount) {
      delete debit_account.isAdded;

      let objMapping = {
        ...mappingServicePositingAccount({
          ...debit_account,
          action: debit_account.action,
        }),
      };

      if (
        !debit_account?.hasOwnProperty("action") ||
        debit_account.action === undefined
      ) {
        objMapping.action = "";
      }

      if (debit_account?.action !== "DELETE") {
        after_debit.push(objMapping);
      }
      if (debit_account.hasOwnProperty("action")) {
        update_after_debit.push(objMapping);
      }
    }

    // if (debit_account?.hasOwnProperty("action")) {
    //   update_after_debit.push(mappingPositingAccount(debit_account));
    // }

    // if (debit_account.action !== "DELETE") {
    //   after_debit.push(mappingPositingAccount(debit_account));
    // }

    // const currentPostingAccount = currentService?.posting_account?.find(
    //   (account) => {
    //     return (
    //       String(account.posting_account_id) ===
    //       String(debit_account.posting_account_id)
    //     );
    //   }
    // );

    // const currentJsonString = JSON.stringify(currentPostingAccount);

    // const debitJsonString = JSON.stringify(debit_account);

    // let sameDebit = {
    //   posting_account_id: debit_account.posting_account_id || "",
    //   customer_posting_account_id:
    //     debit_account.customer_posting_account_id || "",
    // };

    // if (sameDebit.customer_posting_account_id === "") {
    //   debit_account.action = "NEW";
    // }

    // delete debit_account?.isAdded;

    // if (debit_account.action === "NEW") {
    //   after_debit.push({
    //     ...sameDebit,
    //     ...mappingServicePositingAccount(debit_account),
    //   });
    // } else if (
    //   debit_account.action === "EDIT" ||
    //   debit_account.action === "DELETE"
    // ) {
    //   if (debitJsonString !== currentJsonString) {
    //     before_debit.push({
    //       ...sameDebit,
    //       ...mappingServicePositingAccount(currentPostingAccount),
    //     });
    //     after_debit.push({
    //       ...sameDebit,
    //       ...mappingServicePositingAccount(debit_account),
    //     });
    //   }
    // }
  });

  if (after_debit.length > 0) {
    before_debit = currentService.posting_account;
  }

  console.log("update charge", update_after_charge);
  console.log("after charge", after_charge);
  console.log("before charge", before_charge);

  console.log("-------------------------------");
  console.log("update_after_debit", update_after_debit);
  console.log("after_debit", after_debit);
  console.log("before_debit", before_debit);

  const obj_acc_cus = {
    customer_id: parseOriginalData?.customer_id || dataRequest?.customer_id,
    customer_name:
      parseOriginalData?.customer_name || dataRequest?.customer_name,
    account_id: parseOriginalData?.account_id || dataRequest?.account_id,
    account_name: parseOriginalData?.account_name || dataRequest?.account_name,
  };

  return {
    ...obj_acc_cus,
    reference_key: service.customer_fee_service_id,
    refer_transaction_id: dataRequest.transaction_id || null,
    transaction_type: "UPDATESERVICE",
    customer_fee_service_id: service?.customer_fee_service_id,
    reference1: service?.service_code,
    remark:remark,
    data: {
      display_obj: {
        ...obj_acc_cus,
        service_name: service.service_name,
        service_code: service.service_code,
        before_charge: before_charge,
        after_charge: after_charge,
        before_debit,
        after_debit,
        raw_data: service,
      },
      update_obj: {
        ...obj_acc_cus,
        reference_key: service.customer_fee_service_id,
        service_code: service.service_code,
        customer_fee_service_id: service?.customer_fee_service_id,
        posting_account: update_after_debit?.map((debit_account) => {
          return mappingPositingAccount(debit_account);
        }),
        fee_charge: update_after_charge,
      },
    },
  };
};

export const mappingPositingAccount = (account) => {
  let from_date = formatDateApi(account?.from_date);
  let to_date = formatDateApi(account?.to_date, NULL_DATE);

  // console.log("account", account);

  // let customer_service_posting_account_id = account?.customer_posting_account_id;
  // let  customer_offer_posting_account_id = account?.customer_offer_posting_account_id;

  // let action = "";

  // if (account?.action === "DELETE") {
  //   action === "DELETE";
  // } else

  // if (account?.action === "DELETE") {
  //   action = "DELETE";
  // } else if (
  //   account?.customer_offer_posting_account_id ||
  //   account?.customer_posting_account_id
  // ) {
  //   action = "EDIT";
  // } else {
  //   action = "NEW";
  // }

  // if (account?.customer_offer_posting_account_i) {
  //   posting_id = account?.customer_offer_posting_account_i;
  // } else if (account?.customer_posting_account_id) {
  //   posting_id = account?.customer_posting_account_id;
  // } else {
  //   return 0;
  // }

  // if(account.customer_service_posting_account_id) {
  //   account.customer_service_posting_account_id = account.customer_service_posting_account_id
  // }

  // if(account.customer_offer_posting_account_id) {
  //   account.customer_offer_posting_account_id = account.customer_offer_posting_account_id
  // }

  // customer_posting_account_id

  const newObj = {
    action: account?.action,
    from_date: from_date,
    status: account?.status,
    to_date: to_date,
    account_id: account?.posting_account_id,
    account_name: account?.posting_account_name,
    // posting_account_id: account?.posting_account_id,
  };

  if (account.customer_posting_account_id) {
    newObj.posting_account_id = account.customer_posting_account_id;
  }

  if (account.customer_offer_posting_account_id) {
    newObj.posting_account_id = account.customer_offer_posting_account_id;
  }

  // customer_posting_account_id

  // console.log("newObj", newObj);

  return newObj;
};

export const mappingOfferEditApprover = (offer, dataRequest = {},remark="") => {
  let before_debit = [];
  let after_debit = [];
  let update_after_debit = [];
  let before_date = {};
  let after_date = {};

  const originalData = sessionStorage.getItem("originalData");
  const parseOriginalData = JSON.parse(originalData);

  const find_index_current = parseOriginalData?.offer?.findIndex(
    (offer_ori) => offer_ori.customer_offer_id === offer.customer_offer_id
  );

  let checkSomeAccount = offer?.posting_account.some((debit_account) => {
    return debit_account.action;
  });

  offer?.posting_account?.forEach((debit_account) => {
    if (checkSomeAccount) {
      delete debit_account.isAdded;

      let objMapping = {
        ...mappingServicePositingAccount({
          ...debit_account,
          action: debit_account.action,
        }),
      };

      if (
        !debit_account?.hasOwnProperty("action") ||
        debit_account.action === undefined
      ) {
        objMapping.action = "";
      }

      if (debit_account?.action !== "DELETE") {
        after_debit.push(objMapping);
      }
      if (debit_account.hasOwnProperty("action")) {
        update_after_debit.push(objMapping);
      }
    }

    // const currentPostingAccount = parseOriginalData?.offer[
    //   find_index_current
    // ].posting_account?.find((account) => {
    //   return (
    //     String(account.posting_account_id) ===
    //     String(debit_account.posting_account_id)
    //   );
    // });
    // const currentJsonString = JSON.stringify(currentPostingAccount);
    // const debitJsonString = JSON.stringify(debit_account);
    // // console.log("debit_account", debit_account);
    // let sameDebit = {
    //   posting_account_id: debit_account.posting_account_id || "",
    //   customer_offer_posting_account_id:
    //     debit_account.customer_offer_posting_account_id || "",
    // };
    // if (sameDebit.customer_offer_posting_account_id === "") {
    //   debit_account.action = "NEW";
    // }
    // delete debit_account?.isAdded;
    // if (debit_account.action === "NEW") {
    //   after_debit.push({
    //     ...sameDebit,
    //     ...mappingServicePositingAccount(debit_account),
    //   });
    // } else if (
    //   debit_account.action === "EDIT" ||
    //   debit_account.action === "DELETE"
    // ) {
    //   if (debitJsonString !== currentJsonString) {
    //     before_debit.push({
    //       ...sameDebit,
    //       ...mappingServicePositingAccount(currentPostingAccount),
    //     });
    //     after_debit.push({
    //       ...sameDebit,
    //       ...mappingServicePositingAccount(debit_account),
    //     });
    //   }
    // }
  });

  console.log("-------------------------------");
  console.log("update_after_debit", update_after_debit);
  console.log("after_debit", after_debit);
  console.log("before_debit", before_debit);

  if (after_debit.length > 0) {
    before_debit = parseOriginalData?.offer[find_index_current].posting_account;
  }

  if (offer.action === "EDIT") {
    before_date = {
      from_date: formatDateApi(
        parseOriginalData.offer[find_index_current].from_date
      ),
      to_date: formatDateApi(
        parseOriginalData.offer[find_index_current].to_date,
        NULL_DATE
      ),
    };

    after_date = {
      from_date: formatDateApi(offer.from_date),
      to_date: formatDateApi(offer.to_date, NULL_DATE),
    };
  }

  const obj_acc_cus = {
    customer_id: parseOriginalData.customer_id || dataRequest?.customer_id,
    customer_name:
      parseOriginalData.customer_name || dataRequest?.customer_name,
    account_id: parseOriginalData.account_id || dataRequest?.account_id,
    account_name: parseOriginalData.account_name || dataRequest?.account_name,
  };

  return {
    ...obj_acc_cus,
    refer_transaction_id: dataRequest?.transaction_id || null,
    transaction_type: "UPDATEOFFER",
    reference1: offer.offer_code,
    reference_key: offer.customer_offer_id,
    remark:remark,
    data: {
      display_obj: {
        ...obj_acc_cus,
        offer_code: offer.offer_code,
        offer_name: offer.offer_name,
        after_debit: [...after_debit],
        before_debit,
        before_date,
        after_date,
        raw_data: offer,
      },
      update_obj: {
        ...obj_acc_cus,
        customer_offer_id: offer.customer_offer_id,
        reference1: offer.offer_code,
        reference_key: offer.customer_offer_id,
        offer_code: offer.offer_code,
        offer_name: offer.offer_name,
        from_date: formatDateApi(offer.from_date),
        to_date: formatDateApi(offer.to_date, NULL_DATE),
        posting_account: update_after_debit?.map((debit_account) => {
          return mappingPositingAccount(debit_account);
        }),
      },
    },
  };
};

export const mappingServiceApprover = (
  service,
  originalData,
  dataRequest = {},
  remark=""
) => {
  const obj_acc_cus = {
    customer_id: service?.customer_id || dataRequest?.customer_id,
    customer_name: service?.customer_name || dataRequest?.customer_name,
    account_id: originalData?.account_id || dataRequest?.account_id,
    account_name: originalData?.account_name || dataRequest?.account_name,
  };

  console.log("service", service);

  return {
    ...obj_acc_cus,
    refer_transaction_id: dataRequest.transaction_id || null,
    transaction_type: "NEWSERVICE",
    customer_fee_service_id: service?.customer_fee_service_id,
    reference1: service?.service_code,
    remark:remark,
    data: {
      display_obj: {
        ...obj_acc_cus,
        raw_data: service,
        customer_fee_service_id: service?.customer_fee_service_id,
        reference1: service?.service_code,
        service_code: service.service_code,
        service_name: service.service_name,
        account: originalData.account,
        application_date: originalData.application_date,
        contact_name1: originalData.contact_name1,
        contact_name2: originalData.contact_name2,
        contact_number1: originalData.contact_number1,
        contact_number2: originalData.contact_number2,
        customer_segment: originalData.customer_segment,
        date_of_birth: originalData.date_of_birth,
        full_name_eng: originalData.full_name_eng,
        full_name_thai: originalData.full_name_thai,
        gender: originalData.gender,
        id_type: originalData.id_type,
        open_date: originalData.open_date,
        payroll_indicator: originalData.payroll_indicator,
        status: originalData.status,
        sub_segment_code: originalData.sub_segment_code,
        title_thai: originalData.title_thai,
        service: [
          {
            charge_group: mappingEmptyValue(service.charge_group, [])
              .map((group) => {
                return {
                  charge_code: group.charge_code,
                  charge_name: group.charge_name,
                  fee_charge: mappingEmptyValue(group.fee_charge, [])
                    .filter((charge) => !charge?.isRemove)
                    .map((charge) => {
                      return {
                        ...mappingServiceFeeCharge(charge),
                        fee_value:
                          charge?.fee_value?.conditions?.length > 0
                            ? {
                                conditions: mappingEmptyValue(
                                  charge.fee_value.conditions,
                                  []
                                ).map((condition) => {
                                  return mappingServiceCondition(condition);
                                }),
                              }
                            : {
                                ...mappingServiceFeeValue(
                                  charge.fee_value,
                                  charge
                                ),
                                value: mappingEmptyValue(
                                  charge.fee_value.value,
                                  []
                                ).map((value) => {
                                  return mappingServiceValue(value);
                                }),
                              },
                      };
                    }),
                };
              })
              .filter((group) => group?.fee_charge?.length > 0),
            posting_account: service?.posting_account.map((account) => {
              return mappingServicePositingAccount(account);
            }),
            service_code: service?.service_code,
            service_name: service?.service_name,
          },
        ],
      },
      update_obj: {
        ...obj_acc_cus,
        customer_fee_service_id: service?.customer_fee_service_id,
        reference1: service?.service_code,
        service_code: service.service_code,
        service_name: service.service_name,
        posting_account: mappingEmptyValue(service.posting_account, []).map(
          (account) => {
            return mappingPositingAccount(account);
          }
        ),
        fee_charge: mappingEmptyValue(service.charge_group, []).reduce(
          (prev, group) => {
            let new_charge = [];
            for (let jedex = 0; jedex < group.fee_charge.length; jedex++) {
              const charge = group.fee_charge[jedex];
              if (!charge?.isRemove) {
                const new_charge_obj = {
                  ...mappingServiceFeeCharge(charge),
                  fee_value:
                    charge?.fee_value?.conditions?.length > 0
                      ? {
                          conditions: mappingEmptyValue(
                            charge.fee_value.conditions,
                            []
                          ).map((condition) => {
                            return mappingServiceCondition(condition);
                          }),
                        }
                      : {
                          ...mappingServiceFeeValue(charge?.fee_value, charge),
                          value: mappingEmptyValue(
                            charge.fee_value.value,
                            []
                          ).map((value) => {
                            return mappingServiceValue(value);
                          }),
                        },
                };
                new_charge.push(new_charge_obj);
              }
            }
            return [...prev, ...new_charge];
          },
          []
        ),
      },
    },
  };
};

export const mappingOfferApprover = (offer, originalData, dataRequest = {},remark="") => {
  const obj_acc_cus = {
    customer_id: offer?.customer_id || dataRequest?.customer_id,
    customer_name: offer?.customer_name || dataRequest?.customer_name,
    account_id: offer?.account_id || dataRequest?.account_id,
    account_name: offer?.account_name || dataRequest?.account_name,
  };

  return {
    ...obj_acc_cus,
    refer_transaction_id: dataRequest.transaction_id || null,
    transaction_type: "NEWOFFER",
    reference1: offer.offer_code,
    remark:remark,
    data: {
      display_obj: {
        ...obj_acc_cus,
        raw_data: offer,
        account: originalData.account,
        application_date: originalData.application_date,
        contact_name1: originalData.contact_name1,
        contact_name2: originalData.contact_name2,
        contact_number1: originalData.contact_number1,
        contact_number2: originalData.contact_number2,
        customer_segment: originalData.customer_segment,
        customer_type: originalData.customer_type,
        date_of_birth: originalData.date_of_birth,
        entry_date: originalData.entry_date,
        full_name_eng: originalData.full_name_eng,
        full_name_thai: originalData.full_name_thai,
        gender: originalData.gender,
        id_type: originalData.id_type,
        offer_fee_service: offer.offer_fee_service,
        posting_account: offer.posting_account,
        offer_name: offer.offer_name,
        offer_code: offer.offer_code,
        from_date: formatDateApi(offer.from_date),
        to_date: formatDateApi(offer.to_date, NULL_DATE),
      },
      update_obj: {
        ...obj_acc_cus,
        reference1: offer.offer_code,
        offer_code: offer.offer_code,
        offer_name: offer.offer_name,
        from_date: formatDateApi(offer.from_date),
        to_date: formatDateApi(offer.to_date, NULL_DATE),
        posting_account: offer?.posting_account?.map((debit_account) => {
          return mappingPositingAccount(debit_account);
        }),
      },
    },
  };
};
