import api from "./index";

// config
import { apiConfig } from "../config";

const getCustomer = async (params, queryString) => {
  return api.instanceRetial.get(`${apiConfig.getCustomerAPI}${queryString}`);
};

const getCustomerById = async (params, queryString) => {
  return api.instanceRetial.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/info`
  );
};

// const getServiceCustomerById = async (params, queryString) => {
//   return api.instanceRetial.get(
//     `${apiConfig.getCustomerAPI}/${params.customer_id}/unassigned-services`
//   );
// };

const getOfferCustomerById = async (params, queryString) => {
  return api.instanceRetial.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/unassigned-offers`
  );
};

export default {
  getCustomer,
  getCustomerById,
  // getServiceCustomerById,
  getOfferCustomerById,
};
