import api from "./apiConfig";
import modal from "./modalConfig";

export const apiConfig = api;

export const modalConfig = modal;

export const pbfeVersion = window?.config?.pbfeVersion;
export const pbfeUser = window?.config?.pbfeUser;

export const pbfeApiBaseRetailURL = window?.config?.pbfeApiBaseRetailURL;
export const keyCloakRedirectIndividualProduct =
  window?.config?.keyCloakRedirectIndividualProduct;
export const keyCloakRedirect = window?.config?.keyCloakRedirect;
export const keyCloakLogout = window?.config?.keyCloakLogout;

export const sessionExpire = window?.config?.sessionExpire;

export const timeReloadReport = window?.config?.timeReloadReport;
