import api from "./index";

// config
import { apiConfig } from "../config";

const getAccount = async (params, queryString) => {
  return api.instanceRetial.get(`${apiConfig.getAccountAPI}${queryString}`);
};

const getAccountById = async (params, queryString) => {
  return api.instanceRetial.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/info`
  );
};

const getAccountByIdSearch = async (params, queryString) => {
  return api.instanceRetial.get(
    `${apiConfig.getAccountAPI}/${params.account_id}`
  );
};

// const getServiceAccountById = async (params, queryString) => {
//   return api.instanceRetial.get(
//     `${apiConfig.getAccountAPI}/${params.account_id}/unassigned-services`
//   );
// };

const getOfferAccountById = async (params, queryString) => {
  return api.instanceRetial.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/unassigned-offers`
  );
};

export default {
  getAccount,
  getAccountById,
  // getServiceAccountById,
  getOfferAccountById,
  getAccountByIdSearch,
};
