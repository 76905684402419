import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import PBFEError from "../../components/Error/alertError";
import { PBFECommonTable } from "../../components/Table/commonTable";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import {
  MAKER_CHECKER_REQUEST_PATH_NAME,
  MAKER_CHECKER_REQUEST_ROUTE,
  MAKER_CHECKER_WAITING_PATH_NAME,
  MAKER_CHECKER_WAITING_ROUTE,
  MINIMUM_SEARCH_ACTIVE,
  MORE_THAN_SEARCH_ACTIVE,
  MY_REQUEST_TABLE_HEADER,
  OPTIONS_REQUEST_TYPE,
  OPTIONS_STATUS,
  WAITING_TABLE_HEADER,
} from "../../constants/constant";

import React from "react";
import { DateRangePicker } from "../../components/Input/dateRangePicker";
import { FilterDropdown } from "../../components/Input/filterDropdown";
import { Checkbox } from "@mui/material";
import makerCheckerService from "../../api/makerCheckerService";
import useQuery from "../../hooks/useQuery";
import {
  ColorStatus,
  convertRequestType,
  formatDateApi,
  formateDate,
  formateDateWithTime,
  formateDateWithTimeHourMintues,
  TwoLineColumn,
  validateFormatDate,
} from "../../utils/util";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";

export const PBFEAllRequest = ({
  setRouteName,
  serviceType,
  setActionAppbar,
}) => {
  let limit = 15;

  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("request_date");
  const [sortBy, setSortBy] = React.useState(true);

  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  const [calendarValue, setCalendarValue] = useState([
    new Date(today),
    new Date(today),
  ]);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [isErrorDate, setIsErrorDate] = useState([false, false]);

  const refCalendar = useRef();

  const {
    data: rows,
    setData: setRows,
    loading,
    originalData,
    error,
    totalRecords,
    refetch,
  } = useQuery(
    makerCheckerService.getAllRequest,
    {},
    {
      offset: 0,
      limit,
      search_criteria: searched,
      order_by: orderBy,
      sort_by: order,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
    },
    []
  );

  const navigate = useNavigate();

  const toggleCalendar = (e) => {
    e?.stopPropagation();
    setOpenCalendar(!isOpenCalendar);
  };

  useEffect(() => {
    setRouteName([
      {
        title: "ALL REQUEST",
        path:
          serviceType === "checker"
            ? MAKER_CHECKER_WAITING_ROUTE
            : MAKER_CHECKER_REQUEST_ROUTE,
      },
    ]);
    setActionAppbar(
      serviceType === "checker" ? "WAITING_FOR_APPROVAL" : "MY_REQUEST"
    );
  }, [setRouteName]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [refCalendar, calendarValue, isErrorDate]);

  const handleClickOutsideCalendar = async (event) => {
    if (refCalendar.current && !refCalendar.current.contains(event.target)) {
      let newCalendarValue = calendarValue;

      if (isErrorDate.some((item) => item === true)) {
        return;
      }

      setOpenCalendar(false);
      setIsErrorDate([false, false]);
      await reload(newCalendarValue);
    }
  };

  const requestSearch = async (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length > MORE_THAN_SEARCH_ACTIVE) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: value,
          sort_by: order,
          order_by: orderBy,
          ...getFilter(),
        }
      );
      return;
    }
    if (value?.length === 0) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: "",
          sort_by: order,
          order_by: orderBy,
          ...getFilter(),
        }
      );
      return;
    }
  };

  const getFilterObj = (status, request_type) => {
    const filterObj = {
      status: status || null,
      request_type: request_type || null,
    };

    if (filterObj.request_type === null) {
      delete filterObj.request_type;
    }

    if (filterObj.status === null) {
      delete filterObj.status;
    }

    return filterObj;
  };

  const onClickFilterStatus = async (e) => {
    const value = e.target.value;
    setStatus(value);

    await refetch(
      {},
      {
        ...getFilterObj(value, type),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date: formatDateApi(calendarValue[1]),
      }
    );
  };

  const onClickFilterType = async (e) => {
    const value = e.target.value;
    setType(value);

    await refetch(
      {},
      {
        ...getFilterObj(status, value),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date: formatDateApi(calendarValue[1]),
      }
    );
  };

  const reload = async (value) => {
    await refetch(
      {},
      {
        ...getFilter(),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(value[0]),
        to_date:
          value[1] === null ? formatDateApi(value[0]) : formatDateApi(value[1]),
      }
    );
  };

  const onChangeFilterCalendar = (value, shouldReload = false) => {
    setCalendarValue(value);
    if (shouldReload) reload(value);
  };

  const getFilter = () => {
    let filter_obj = {
      status,
      request_type: type,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
    };

    if (status === "") {
      delete filter_obj.status;
    }

    if (type === "") {
      delete filter_obj.request_type;
    }

    return filter_obj;
  };

  const getShowFields = () => {
    return [
      "status",
      "transaction_reference",
      "request_date",
      "request_type,customer_name,account_id",
      "code",
      "maker",
      "status",
      "reject_reason",
    ];
  };

  const getShowFormatControl = () => {
    return [
      ColorStatus,
      null,
      formateDateWithTimeHourMintues,
      TwoLineColumn,
      null,
      null,
      null,
      null,
    ];
  };

  const getHeadCells = () => {
    return MY_REQUEST_TABLE_HEADER;
  };

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full min-h-screen">
      <div className="flex justify-between mt-50px w-full pt-md">
        <div className="m-15px flex">
          <FilterDropdown
            label="All Status"
            value={status}
            onChange={onClickFilterStatus}
            options={OPTIONS_STATUS}
            width="100px"
            isErrorDate={isErrorDate}
          />
          <div className="w-20px"></div>
          <FilterDropdown
            label="Request Type"
            value={type}
            onChange={onClickFilterType}
            options={OPTIONS_REQUEST_TYPE}
            width="120px"
            isErrorDate={isErrorDate}
          />
          <div className="w-20px"></div>
          <DateRangePicker
            isErrorDate={isErrorDate}
            setIsErrorDate={setIsErrorDate}
            refCalendar={refCalendar}
            isOpenCalendar={isOpenCalendar}
            calendarValue={calendarValue}
            setCalendarValue={setCalendarValue}
            toggleCalendar={toggleCalendar}
            onChange={onChangeFilterCalendar}
          />
        </div>
        <PBFESearchbar
          placeholder={"Request ID / Code / Maker"}
          onChange={requestSearch}
          value={searched}
        />
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <React.Fragment>
          <PBFECommonTableAsync
            orderByDefault={"request_date"}
            filter={getFilter()}
            sort_name_by="request_date"
            disabledSortList={["user_role"]}
            searched={searched}
            page={page}
            setPage={setPage}
            headCells={getHeadCells()}
            rows={rows}
            showFooter={true}
            selectable={true}
            onClickRow={(row) => {
              let pathName = "";
              if (serviceType === "checker") {
                pathName = "/waitingforapprove/allrequest";
              } else {
                pathName = "/myrequest/allrequest";
              }
              navigate(`${pathName}/${row.request_id}`);
            }}
            formatControl={getShowFormatControl()}
            showFields={getShowFields()}
            refetch={refetch}
            limit={limit}
            total_records={totalRecords}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </React.Fragment>
      )}
    </div>
  );
};
