import {
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "unset",
    wordBreak: "break-all",
  },
}));

export const TableDropdown = ({
  options = [],
  value,
  onChange,
  isMulti,
  name,
  error = false,
  setErrorState,
  setErrorLocal,
  index = 0,
  primaryIndex = 0,
  feeValueIndex = 0,
  conditionIndex = 0,
}) => {
  const classes = useStyles();
  const [focus, setFocus] = useState("");

  const errorBorder = "border border-red-500 h-10 mb-10 w-full";

  useEffect(() => {
    if (setErrorState) {
      let field_name_error = `${name}_${primaryIndex}_${feeValueIndex}_${conditionIndex}_${index}`;

      setErrorState((prev) => {
        let temp = [...prev];

        if (error) {
          let obj_error = {
            error: true,
            message: "This field is required",
            field_name: field_name_error,
          };
          temp.push(obj_error);
          temp = _.unionBy(temp, "fieldname");
        } else {
          temp = temp.filter((item) => item.field_name !== field_name_error);
        }
        return temp;
      });
    }
  }, [setErrorState, value, error]);

  return (
    <div className={error ? errorBorder : focus}>
      <Select
        name={name}
        value={value}
        IconComponent={() => <div></div>}
        onChange={(e) => onChange(e, name, conditionIndex)}
        variant="standard"
        sx={{
          color: "#7A58BF",
          fontSize: 16,
          paddingY: "6px",
          width: "100%",
        }}
        disableUnderline
        onFocus={() => setFocus("border-2 border-textscb")}
        onBlur={() => setFocus("")}
        multiple={isMulti}
        renderValue={
          isMulti
            ? (selected) => {
                if (!selected?.length) {
                  return <div>-</div>;
                }

                return selected.join(",\n");
              }
            : null
        }
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {isMulti ? (
                <React.Fragment>
                  <Checkbox checked={value?.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </React.Fragment>
              ) : (
                item
              )}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText className="Mui-error">
          This field is required
        </FormHelperText>
      )}
    </div>
  );
};
