import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export const FilterDropdown = ({
  onChange,
  width = "100px",
  value = "ALL",
  options = ["All Status"],
  label,
  validateError = null,
  isErrorDate = [],
}) => {
  return (
    <div>
      <div className="relative">
        {value === "" && (
          <InputLabel
            variant="standard"
            id="demo-simple-select-standard-label"
            sx={{
              position: "absolute",
              bottom: 0,
              paddingX: "12px",
              height: "25px",
              color: "#000000DE",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          defaultValue={options[0]}
          sx={{
            color: "#000000DE",
            backgroundColor: "#7A58BF26",
            fontSize: 14,
            fontWeight: 500,
            // width: width,
            // height: "32px",
            borderRadius: "15px",
            // padding: 0,
            paddingX: "12px",
            textAlign: "right",
            width: value === "" ? width : "auto",
          }}
          // hiddenLabel
          disabled={isErrorDate?.some((item) => item === true)}
          disableUnderline
          variant="standard"
          onChange={onChange}
        >
          {options.map((menu, index) => {
            return (
              <MenuItem key={index} value={menu.value}>
                {menu.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      {validateError !== null && (
        <div className="text-red-400 text-sm ml-2 ">{validateError}</div>
      )}
    </div>
  );
};
