import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";
// routes
import ProtectedRoute from "./protectedRoute";

// mui
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// context
import DrawerContextProvider from "../context/drawerContext";

// components
import PBFEDrawer from "../components/Panel/Drawer/drawer";

// pages
import Home from "../pages/Landing";
import TaxScheme from "../pages/TaxScheme";
import Service from "../pages/Service";
import Offer from "../pages/Offer";
import AuthCallback from "../pages/AuthCallback";
import Login from "../pages/Login";
import PBFEAppbar from "../components/Layout/Appbar/appbar";
import Retail from "../pages/Retail";
import ServiceDetail from "../pages/ServiceDetail";

// config
import { OfferDetail } from "../pages/OfferDetail";
import RetailDetail from "../pages/RetialDetail";
import Customer from "../pages/Customer";
import { PBFECustomerInfo } from "../pages/Customer/customerInfo";
import Account from "../pages/Account";
import { PBFEAccountInfo } from "../pages/Account/accountInfo";
import { pbfeVersion } from "../config";
import useQuery from "../hooks/useQuery";
import userProfileService from "../api/userProfile";
import PBFEError from "../components/Error/alertError";
import {
  BANK_BRANCH_ROUTE,
  GENERATE_FEE_REPORT_ROUTE,
  GENERATE_PRICE_REPORT_ROUTE,
  GROUP_ROUTE,
  MAKER_CHECKER_REQUEST_ROUTE,
  MAKER_CHECKER_WAITING_ROUTE,
  PROFILE_ROUTE,
  REPORT_FEE_PATH_NAME,
  REPORT_FEE_ROUTE,
  REPORT_PRICE_PATH_NAME,
  REPORT_PRICE_ROUTE,
  SETTINGS_ROUTE,
  UPLOAD_ROUTE,
} from "../constants/constant";
import MyRequest from "../pages/MyRequest";
import { useDispatch } from "react-redux";
import { getUserProfileThunk } from "../redux/authSlice";
import Waitingforapprove from "../pages/WaitingForApproval";
import UserGroup from "../pages/Admin/UserGroup";
import UserProfile from "../pages/Admin/UserProfile";
import { PBFEApprovalDetail } from "../pages/WaitingForApproval/approvalDetail";
import { PBFEMyRequestDetail } from "../pages/MyRequest/myRequestDetail";
import { PBFEAllRequest } from "../pages/WaitingForApproval/allrequest";
import Upload from "../pages/Upload";
import Report from "../pages/Report";
import GenerateReport from "../pages/Report/generateReport";
import Settings from "../pages/Settings";
import BankBranch from "../pages/BankBranch";
import { TaxInfoHistory } from "../pages/TaxScheme/taxInfoHistory";

function Layout({
  routeName,
  setRouteName,
  primaryDisplay,
  setPrimaryDisplay,
  setDataRequest,
  dataRequest,
  setPrevPath,
  setActionAppbar,
  actionAppbar,
  editSetting,
  setEditSetting,
  setReset,
  reset,
  dataGenerateReport,
  settingsGlobal,
  isCreate,
  setIsCreate,

  setGroupSetting,
  groupSetting,
  setDataUserGroup,
  dataUserGroup,
  dataUserProfile,
  setDataUserProfile,
  errorDesc,
  setErrorDesc,
  action,
  setAction,
}) {
  const location = useLocation();

  if (
    location.pathname === "/auth-callback" ||
    location.pathname === "/login"
  ) {
    return null;
  }

  return (
    <>
      <CssBaseline />
      <PBFEDrawer version={pbfeVersion} />
      {routeName !== null && (
        <PBFEAppbar
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          dataGenerateReport={dataGenerateReport}
          primaryDisplay={primaryDisplay}
          setPrimaryDisplay={setPrimaryDisplay}
          titles={routeName}
          setRouteName={setRouteName}
          dataRequest={dataRequest}
          setPrevPath={setPrevPath}
          setActionAppbar={setActionAppbar}
          actionAppbar={actionAppbar}
          editSetting={editSetting}
          setEditSetting={setEditSetting}
          setReset={setReset}
          reset={reset}
          settingsGlobal={settingsGlobal}
          setGroupSetting={setGroupSetting}
          groupSetting={groupSetting}
          setDataUserGroup={setDataUserGroup}
          dataUserGroup={dataUserGroup}
          dataUserProfile={dataUserProfile}
          setDataUserProfile={setDataUserProfile}
          errorDesc={errorDesc}
          setErrorDesc={setErrorDesc}
          action={action}
          setAction={setAction}
          setDataRequest={setDataRequest}
        />
      )}
    </>
  );
}

function MainRoute() {
  const [routeName, setRouteName] = React.useState([]);
  const [primaryDisplay, setPrimaryDisplay] = React.useState(false);
  const [dataRequest, setDataRequest] = React.useState({});
  const [actionAppbar, setActionAppbar] = React.useState();
  const [editSetting, setEditSetting] = React.useState(false);
  const [dataGenerateReport, setDataGenerateReport] = React.useState({});
  const [reset, setReset] = React.useState(false);
  const [settingsGlobal, setSettingsGlobal] = React.useState([]);
  const [isCreate, setIsCreate] = React.useState(false);
  const [groupSetting, setGroupSetting] = React.useState(false);
  const [dataUserGroup, setDataUserGroup] = React.useState([]);
  const [dataUserProfile, setDataUserProfile] = React.useState([]);
  const [errorDesc, setErrorDesc] = React.useState(false);
  const [action, setAction] = React.useState("");

  return (
    <div>
      {/* <Router> */}
      <DrawerContextProvider>
        <Box sx={{ display: "flex", boxShadow: 0 }}>
          <Layout
            primaryDisplay={primaryDisplay}
            setPrimaryDisplay={setPrimaryDisplay}
            routeName={routeName}
            setRouteName={setRouteName}
            setDataRequest={setDataRequest}
            dataRequest={dataRequest}
            setActionAppbar={setActionAppbar}
            actionAppbar={actionAppbar}
            editSetting={editSetting}
            setEditSetting={setEditSetting}
            setReset={setReset}
            reset={reset}
            dataGenerateReport={dataGenerateReport}
            settingsGlobal={settingsGlobal}
            setIsCreate={setIsCreate}
            isCreate={isCreate}
            setGroupSetting={setGroupSetting}
            groupSetting={groupSetting}
            setDataUserGroup={setDataUserGroup}
            dataUserGroup={dataUserGroup}
            dataUserProfile={dataUserProfile}
            setDataUserProfile={setDataUserProfile}
            errorDesc={errorDesc}
            setErrorDesc={setErrorDesc}
            action={action}
            setAction={setAction}
          />
          <Routes>
            <Route
              path="/"
              caseSensitive={false}
              element={
                <Home
                  setRouteName={setRouteName}
                  setActionAppbar={setActionAppbar}
                />
              }
            />
            <Route path="/login" caseSensitive={false} element={<Login />} />
            <Route
              path="/auth-callback"
              caseSensitive={false}
              element={<AuthCallback setActionAppbar={setActionAppbar} />}
            />
            <Route
              path={MAKER_CHECKER_REQUEST_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <MyRequest
                    primaryDisplay={primaryDisplay}
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path={MAKER_CHECKER_WAITING_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Waitingforapprove
                    primaryDisplay={primaryDisplay}
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/${MAKER_CHECKER_WAITING_ROUTE}/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    serviceType="checker"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/${MAKER_CHECKER_WAITING_ROUTE}/allrequest`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEAllRequest
                    serviceType="checker"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/${MAKER_CHECKER_WAITING_ROUTE}/allrequest/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    serviceType="checker"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/${MAKER_CHECKER_REQUEST_ROUTE}/allrequest/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    serviceType="maker"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/${MAKER_CHECKER_REQUEST_ROUTE}/allrequest`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEAllRequest
                    serviceType="maker"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/myrequest/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    serviceType="maker"
                    setDataRequest={setDataRequest}
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={PROFILE_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <UserProfile
                    errorDesc={errorDesc}
                    setErrorDesc={setErrorDesc}
                    actionAppbar={actionAppbar}
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={editSetting}
                    setIsEdit={setEditSetting}
                    setRouteName={setRouteName}
                    setPrimaryDisplay={setPrimaryDisplay}
                    setActionAppbar={setActionAppbar}
                    routeName={routeName}
                    dataUserProfile={dataUserProfile}
                    setDataUserProfile={setDataUserProfile}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path={GROUP_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <UserGroup
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    setRouteName={setRouteName}
                    setPrimaryDisplay={setPrimaryDisplay}
                    setActionAppbar={setActionAppbar}
                    setGroupSetting={setGroupSetting}
                    groupSetting={groupSetting}
                    setDataUserGroup={setDataUserGroup}
                    dataUserGroup={dataUserGroup}
                    actionAppbar={actionAppbar}
                    setErrorDesc={setErrorDesc}
                    errorDesc={errorDesc}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/taxScheme"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <TaxScheme
                    action={action}
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    setAction={setAction}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/taxScheme/:tax_scheme_id"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <TaxScheme
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    setAction={setAction}
                    action={action}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/taxScheme/history/:tax_scheme_id/:tax_scheme_history_id"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <TaxInfoHistory setRouteName={setRouteName} />
                </ProtectedRoute>
              }
            />

            {/* <Route
                path="/service"
                caseSensitive={false}
                element={
                  <ProtectedRoute>
                    <Service
                      routeName={routeName}
                      setRouteName={setRouteName}
                      setActionAppbar={setActionAppbar}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/service/:service_code"
                caseSensitive={false}
                element={
                  <ProtectedRoute>
                    <ServiceDetail
                      routeName={routeName}
                      setRouteName={setRouteName}
                      setActionAppbar={setActionAppbar}
                    />
                  </ProtectedRoute>
                }
              /> */}
            <Route
              path="/offer"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Offer
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/offer/:offer_code"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <OfferDetail
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/retail"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Retail
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/retail/:offer_code"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <RetailDetail
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Customer
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/:customer_id"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFECustomerInfo
                    routeName={routeName}
                    setRouteName={setRouteName}
                    dataRequest={dataRequest}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            {/* <Route
              path={`/customer/:customer_id/history/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    type="customer"
                    serviceType="history"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path={`/customer/:customer_id/history/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    type="customer"
                    serviceType="history"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Account
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/:account_id"
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEAccountInfo
                    dataRequest={dataRequest}
                    setDataRequest={setDataRequest}
                    routeName={routeName}
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={`/account/:account_id/history/:request_id`}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <PBFEApprovalDetail
                    type="account"
                    serviceType="history"
                    setRouteName={setRouteName}
                    setDataRequest={setDataRequest}
                    setActionAppbar={setActionAppbar}
                    actionAppbar={actionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={UPLOAD_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Upload
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={REPORT_PRICE_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Report
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    path={REPORT_PRICE_PATH_NAME}
                    route={REPORT_PRICE_ROUTE}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={REPORT_FEE_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Report
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    path={REPORT_FEE_PATH_NAME}
                    route={REPORT_FEE_ROUTE}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path={GENERATE_FEE_REPORT_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <GenerateReport
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    path={REPORT_FEE_PATH_NAME}
                    route={REPORT_FEE_ROUTE}
                    setDataGenerateReport={setDataGenerateReport}
                    dataGenerateReport={dataGenerateReport}
                    reset={reset}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path={GENERATE_PRICE_REPORT_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <GenerateReport
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    path={REPORT_PRICE_PATH_NAME}
                    route={REPORT_PRICE_ROUTE}
                    setDataGenerateReport={setDataGenerateReport}
                    dataGenerateReport={dataGenerateReport}
                    reset={reset}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path={SETTINGS_ROUTE}
              caseSensitive={false}
              element={
                <ProtectedRoute>
                  <Settings
                    settingsGlobal={settingsGlobal}
                    setSettingsGlobal={setSettingsGlobal}
                    setRouteName={setRouteName}
                    setActionAppbar={setActionAppbar}
                    editSetting={editSetting}
                    setEditSetting={setEditSetting}
                  />
                </ProtectedRoute>
              }
            />
            {/* <Route
                path={BANK_BRANCH_ROUTE}
                caseSensitive={false}
                element={
                  <ProtectedRoute>
                    <BankBranch
                      setActionAppbar={setActionAppbar}
                      setRouteName={setRouteName}
                    />
                  </ProtectedRoute>
                }
              /> */}
          </Routes>
        </Box>
      </DrawerContextProvider>
      {/* </Router> */}
    </div>
  );
}

export default MainRoute;
