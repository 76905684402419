import React, { useEffect, useState } from "react";

import { TaxInfoMain } from "./taxInfo";
import PBFESideTable from "../../components/Table/sideTable";
import {
  MINIMUM_SEARCH_ACTIVE,
  TAX_SCHEME_TITLE,
} from "../../constants/constant";
import PBFEError from "../../components/Error/alertError";
import useQuery from "../../hooks/useQuery";
import taxSchemeService from "../../api/taxSchemeService";
import { TaxCreate } from "./taxCreate";
import {
  checkDateInvalid,
  checkFromToDate,
  FunctionCheckDateLength,
  TEXT_FIELD_EQUAL,
} from "../../functions";
import { formatDateApi } from "../../utils/util";
import { ConfirmModal } from "../../components/Panel/Modal/confirmModal";
import { useParams } from "react-router-dom";
import { FunctionCheckNoSpace } from "../../components/radium-omnibus/src/utils/Field.Function";
import { ERROR_MESSAGE_NO_SPACE } from "../../components/radium-omnibus/src/utils/Field.Constant";

function TaxScheme({ setRouteName, setActionAppbar, action, setAction }) {
  const [selectedTaxScheme, setSelectedTaxScheme] = useState("");
  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);
  const [history, setHistory] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [reload, setReload] = useState(false);
  const [form, setForm] = useState({ tax_type: "VAT" });
  const [currentScheme, setCurrentScheme] = useState(null);
  const [isEdit, setIsEdit] = useState({});
  const [validateError, setValidateError] = useState([]);
  const [rates, setRates] = useState([
    { tax_rate: "", effective_date: "", end_date: "" },
  ]);
  const [deleteRates, setDeleteRates] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const [errorMsg, setErrorMsg] = useState("");

  const {
    data: rows,
    setData: setRows,
    loading,
    originalData,
    error,
  } = useQuery(taxSchemeService.getTaxScheme, {}, {}, [reload]);

  useEffect(() => {
    if (params && params.tax_scheme_id) {
      setSelectedTaxScheme(params.tax_scheme_id);
      /// find row that match to param
      for (let i = 0; i < rows?.length; i++) {
        if (rows[i].tax_scheme_id == params.tax_scheme_id) {
          setInfo(rows[i]);
        }
      }
    } else {
      setRouteName([{ title: TAX_SCHEME_TITLE, path: "/taxScheme" }]);
      setActionAppbar(TAX_SCHEME_TITLE);
    }
  }, [rows]);

  const clear = () => {
    setIsCreate(false);
    setSelectedTaxScheme("");
    setActionAppbar(TAX_SCHEME_TITLE);
    setReload(!reload);
    setValidateError([]);
    setErrorMsg("");
  };

  const changePath = (name) => {
    setRouteName([
      {
        title: TAX_SCHEME_TITLE,
        path: "/taxScheme",
        setSelected: clear,
      },
      {
        title: name,
      },
    ]);
  };

  const submittedTaxScheme = async () => {
    var tax_rate = [];

    for (let i = 0; i < rates.length; i++) {
      let status = "N";
      if (!isCreate && rates[i].tax_rate_id) {
        status = "E";
      }
      tax_rate.push({
        tax_rate: Number(rates[i].tax_rate),
        tax_rate_id: rates[i].tax_rate_id || null,
        status: status,
        effective_date: formatDateApi(rates[i].effective_date),
        end_date:
          rates[i].end_date === ""
            ? "9999-12-31T00:00:00"
            : formatDateApi(rates[i].end_date),
      });
    }
    for (let i = 0; i < deleteRates?.length; i++) {
      tax_rate.push({
        tax_rate: deleteRates[i].tax_rate,
        tax_rate_id: deleteRates[i].tax_rate_id,
        status: "D",
        effective_date: formatDateApi(deleteRates[i].effective_date),
        end_date:
          deleteRates[i]?.end_date === ""
            ? "9999-12-31T00:00:00"
            : formatDateApi(deleteRates[i].end_date),
      });
    }
    /// need case delete

    try {
      const payload = {
        tax_scheme_id: form.tax_scheme_id,
        tax_scheme_name: form.tax_scheme_name,
        tax_type: form.tax_type,
        gl_product_code: form.gl_product_code,
        gl_account_code: form.gl_account_code,
        gl_tax_code: form.gl_tax_code,
        gl_rc_code: form.gl_rc_code,
        gl_oc_code: form.gl_oc_code,
        gl_description: form.gl_description,
        tax_rate: tax_rate,
        remark: form.remark,
      };
      setLoadingAction(true);
      var result;

      console.log("payload", payload);
      //return;
      if (isCreate) result = await taxSchemeService.createTaxscheme(payload);
      else
        result = await taxSchemeService.updateTaxscheme(
          form.tax_scheme_id,
          payload
        );
      if (result.data.status.description === "Success") {
        window.location.reload();
        // setShowConfirm(false);
        // setIsSuccess(false)
        //setIsSuccess(true)
        // setIsError(false);
        // clear();
      }
    } catch (error) {
      // console.log("error", error);
      // console.log("status", error?.description);
      // //console.log("status", error?.message);
      // console.log("respon", error?.response);
      setIsError(true);
      setErrorMsg(error.response.data.status.description);
    } finally {
      setLoadingAction(false);
    }
    setAction("");
  };

  const validate = () => {
    /// Validation here
    var tempError = [];
    if (form.tax_scheme_id === undefined || form.tax_scheme_id === "")
      tempError.push({
        message: "Tax Scheme Id is required and litmit 8 characters.",
      });
    if (form.tax_scheme_name === undefined || form.tax_scheme_name === "")
      tempError.push({
        message: "Tax Scheme Name is required and limit 64 characters..",
      });
    if (FunctionCheckNoSpace(form.tax_scheme_id, "ERROR") !== "") {
      tempError.push({
        message: `Tax Scheme ID ${ERROR_MESSAGE_NO_SPACE["EN"]}`,
      });
    }
    if (form.tax_type == undefined || form.tax_type === "")
      tempError.push({ message: "Type is required." });
    if (
      form.gl_description == undefined ||
      form.gl_description.length < 1 ||
      form.gl_description.length > 64
    )
      tempError.push({
        message: "Description is required and limit 64 characters.",
      });
    if (
      form.gl_product_code == undefined ||
      form.gl_product_code === "" ||
      isNaN(form.gl_product_code)
    )
      tempError.push({
        message: "Product code is required as number and limit 16 characters.",
      });
    if (
      form.gl_account_code == undefined ||
      form.gl_account_code === "" ||
      isNaN(form.gl_account_code)
    )
      tempError.push({
        message: "Account code is required as number and limit 16 characters.",
      });
    if (
      form.gl_tax_code == undefined ||
      form.gl_tax_code === "" ||
      isNaN(form.gl_tax_code)
    )
      tempError.push({
        message: "Tax code is required as number  and limit 1 character",
      });
    if (
      form.gl_rc_code == undefined ||
      form.gl_rc_code === "" ||
      isNaN(form.gl_rc_code)
    )
      tempError.push({
        message: "GL RC is required as number  and limit 16 characters",
      });
    if (
      form.gl_oc_code == undefined ||
      form.gl_oc_code === "" ||
      isNaN(form.gl_oc_code)
    )
      tempError.push({
        message: "GL OC is required as number and limit 16 characters",
      });
    if (
      rates == undefined ||
      rates?.length === 0
      // rates[0].tax_rate === "" ||
      // rates[0].effective_date === ""
    )
      tempError.push({ message: "Rates must be atleast one" });

    for (let i = 0; i < rates?.length; i++) {
      if (rates[i].tax_rate == undefined || rates[i].tax_rate === "")
        tempError.push({ message: "Tax rate cannot be null" });
      if (isNaN(rates[i].tax_rate))
        tempError.push({ message: "Tax rate must be number" });
      if (rates[i].tax_rate > 100)
        tempError.push({ message: "Tax rate must be less than 100" });

      if (rates[i].effective_date === rates[i].end_date)
        tempError.push({
          message: "Effective date cannot be equal to End Date",
        });
      if (
        !rates[i]?.disabled &&
        new Date(rates[i].effective_date) < new Date().setHours(0, 0, 0, 0)
      )
        tempError.push({ message: "Effective date cannot be past" });
      if (
        i > 0 &&
        checkFromToDate(
          rates[i].effective_date,
          rates[i].end_date,
          rates[i - 1].end_date || "9999-12-31",
          "from_date",
          true,
          "Effective Date cannot be equal to End Date"
        )
      )
        tempError.push({
          message:
            "Effective Date must be greater than End Date of previous row",
        });

      if (
        i > 0 &&
        rates[i].effective_date &&
        (rates[i - 1].end_date === "" || rates[i - 1].end_date === "9999-12-31")
      ) {
        tempError.push({ message: "End date of previous row cannot be empty" });
      }
      if (
        checkFromToDate(
          rates[i].effective_date,
          rates[i].end_date,
          null,
          "to_date",
          true,
          "Effective Date cannot be equal to End Date"
        )
      )
        tempError.push({ message: "Effective To Cannot Be Before End Date." });

      if (!rates[i].effective_date || rates[i].effective_date === "")
        tempError.push({
          message: "Effective Date cannot be null.",
        });
      if (
        FunctionCheckDateLength(rates[i]?.effective_date, "form_date")?.error
      ) {
        tempError.push({
          message: "Invalid Effective Date.",
        });
      }
      if (
        rates[i]?.end_date &&
        rates[i]?.end_date?.length > 0 &&
        FunctionCheckDateLength(rates[i]?.end_date, "to_date")?.error
      ) {
        tempError.push({
          message: "Invalid End Date.",
        });
      }
    }

    setValidateError(tempError);
    return tempError.length === 0;
  };

  useEffect(() => {
    if (action === TAX_SCHEME_TITLE) {
      console.log("IN NEW");
      changePath("NEW TAX SCHEME");
      setIsCreate(true);
      setRates([{ tax_rate: "", effective_date: "", end_date: "" }]);
      setSelectedTaxScheme("");
      setAction("");
      setActionAppbar("CREATE TAX SCHEME");
    }
    if (action === "CREATING TAX SCHEME") {
      console.log("IN CREATE");
      // validate before submit
      if (validate()) {
        setShowConfirm(true);
        // submittedTaxScheme()
      }
      setAction("CREATE TAX SCHEME");
    }
    if (action === "TAX SCHEME EDIT") {
      /// set form

      setRouteName([
        {
          title: TAX_SCHEME_TITLE,
          path: `/taxScheme`,
          setSelected: () => {
            window.location.reload();
          },
        },
        {
          title: `${selectedTaxScheme.tax_scheme_id}`,
          path: `/taxScheme/${selectedTaxScheme.tax_scheme_id}`,
          setSelected: () => {
            setIsEdit(false);
            setActionAppbar("TAX SCHEME SELECT");
          },
          check: true,
        },
        {
          title: "EDIT TAX SCHEME",
        },
      ]);
      setActionAppbar("CREATE TAX SCHEME");
      setIsEdit(true);
      setValidateError([]);
      setAction("");
    }
  }, [action]);

  const setInfo = (row) => {
    changePath(row.tax_scheme_id);
    setActionAppbar("TAX SCHEME SELECT");
    setIsCreate(false);
    setIsEdit(false);
    setSelectedTaxScheme(row);
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value);
    setPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalData);
      return;
    }

    const filteredRows = originalData?.filter((row) => {
      return row.tax_scheme_name
        .toLowerCase()
        .includes(searchedVal.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  if (error) return <PBFEError error={error} />;

  const rowsDataSorted = rows?.sort((a, b) => {
    if (a.tax_scheme_name) {
      return a.tax_scheme_name.toString().localeCompare(b.tax_scheme_name);
    } else {
      return 0;
    }
  });

  return (
    <div className="h-screen w-full">
      <div className=" h-full flex">
        <div className=" pt-xxl min-w-max  h-screen overflow-y-scroll">
          <PBFESideTable
            id="PBFESideTable"
            labelKey={"tax_scheme_name"}
            valueKey={"current_tax_rate"}
            searched={searched}
            setInfo={setInfo}
            requestSearch={requestSearch}
            rows={rowsDataSorted}
            loading={loading}
            valueSuffix="%"
            page={page}
            setPage={setPage}
            setHistory={setHistory}
            history={history}
          />
        </div>
        <div className="border-r"></div>
        <div className=" w-full overflow-y-scroll h-screen">
          {isCreate && (
            <TaxCreate
              deleteRates={deleteRates}
              setDeleteRates={setDeleteRates}
              rates={rates}
              setRates={setRates}
              form={form}
              isCreate={true}
              setForm={setForm}
              error={validateError}
            />
          )}
          {selectedTaxScheme && (
            <TaxInfoMain
              validateError={validateError}
              history={history}
              setHistory={setHistory}
              id="PBFETaxSchemeInfo"
              selectedTaxScheme={selectedTaxScheme}
              form={form}
              rates={rates}
              setRates={setRates}
              deleteRates={deleteRates}
              setDeleteRates={setDeleteRates}
              setForm={setForm}
              isEdit={isEdit}
              setCurrentScheme={setCurrentScheme}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        onClick={submittedTaxScheme}
        loading={loadingAction}
        isSuccess={isSuccess}
        isError={isError}
        message={
          isError
            ? errorMsg
            : "Successfully " + (isCreate ? "create" : "update") + " tax scheme"
        }
        open={showConfirm}
        confirmText="OK"
        onClose={() => {
          setShowConfirm(false);
          setIsError(false);
        }}
        text={
          "Do you want to " + (isCreate ? "create" : "update") + " tax scheme?"
        }
      />
    </div>
  );
}

export default TaxScheme;
