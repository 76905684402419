import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import useQuery from "../../../hooks/useQuery";
import { PBFELoading } from "../../Elements/Loading/loading";
import LoadingButton from "@mui/lab/LoadingButton";
import PBFESearchbar from "../../Elements/Searchbar/searchbar";
import { ErrorPopUp } from "./accountModal";
import { PBFECommonTableCheckBox } from "../../Table/commonTableCheckBox";
import {
  ADD_SERVICE_CHECKBOX_TABLE_HEADER,
  MINIMUM_SEARCH_ACTIVE,
} from "../../../constants/constant";
import SelectAll from "../../../assets/icon/select_all.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addBooleanCheckBox = (array_data, dataFromApi) => {
  const res = array_data.map((item) => {
    const findIndex = dataFromApi.findIndex((d) => {
      return d.service_code === item.offer_code;
    });
    return {
      checked: findIndex !== -1 ? true : false,
      service_name: item.offer_name,
      service_code: item.offer_code,
    };
  });
  return res;
};

export const AsyncModalService = ({
  open = false,
  setOpen,
  textButton = "Confirm",
  onClose,
  title = "Title",
  serviceApi,
  query = {},
  params = {},
  placeholder = "Search",
  errorModalMessage,
  dataFromApi,
  setData,
  setIsSetBack,
  isSetBack,
  prepireDataWhenSearch,
  setPrePairDataWhenSearch,
  setAccordion,
}) => {
  const {
    data: rows,
    setData: setRows,
    loading,
    error,
  } = useQuery(
    serviceApi,
    { ...params },
    {
      ...query,
    }
  );

  const [searched, setSearched] = React.useState("");
  const [keepDataWhenSearch, setKeepDataWhenSearch] = React.useState([]);
  const [isDataBack, setIsDataBack] = React.useState(false);

  const requestSearch = (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    if (value.length >= MINIMUM_SEARCH_ACTIVE) {
      if (isDataBack) {
        const filteredRows = keepDataWhenSearch.filter((o) => {
          return (
            o.service_code
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            o.service_name
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase())
          );
        });
        setRows(filteredRows);
      } else {
        const filteredRows = rows.filter((o) => {
          return (
            o.service_code
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            o.service_name
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase())
          );
        });
        setIsDataBack(true);
        setKeepDataWhenSearch(rows);
        setRows(filteredRows);
      }
    } else {
      if (isDataBack) {
        setRows(keepDataWhenSearch);
        setKeepDataWhenSearch([]);
        setIsDataBack(false);
        return;
      } else {
        return;
      }
    }
    // if (value.length <= MINIMUM_SEARCH_ACTIVE) {
    // if (isDataBack) {
    //   setRows(keepDataWhenSearch);
    //   setKeepDataWhenSearch([]);
    //   setIsDataBack(false);
    //   return;
    // } else {
    //   return;
    // }
    // } else {
    // const filteredRows = rows.filter((o) => {
    //   return (
    //     o.service_code
    //       .toLowerCase()
    //       .includes(searchedVal.target.value.toLowerCase()) ||
    //     o.service_name
    //       .toLowerCase()
    //       .includes(searchedVal.target.value.toLowerCase())
    //   );
    // });
    // if (isDataBack) {
    //   setRows(keepDataWhenSearch);
    // } else {
    //   setIsDataBack(true);
    //   setKeepDataWhenSearch(rows);
    //   setRows(filteredRows);
    // }
    // }
  };

  const onCloseModal = () => {
    setSearched("");
    // setRows(originalData);
    onClose();
    // setRadio();
    // setErrorModalMessage("");
    setOpen(false);
  };

  const onClickConfirm = () => {
    if (isDataBack) {
      const row_that_checked = keepDataWhenSearch?.filter((items) => {
        return items.checked === true;
      });
      setData(row_that_checked);
      setSearched("");
      setAccordion(true);
      setOpen(false);
    } else {
      const row_that_checked = rows?.filter((items) => {
        return items.checked === true;
      });
      setData(row_that_checked);
      setSearched("");
      setAccordion(true);
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (rows?.length > 0) {
      if (
        (rows?.length > 0 && rows[0]?.checked === true) ||
        (rows?.length > 0 && rows[0]?.checked === false)
      ) {
      } else {
        setRows(addBooleanCheckBox(rows, dataFromApi));
      }
    }
  }, [rows]);

  const setSelectAll = () => {
    setRows(
      rows?.map((item) => {
        return {
          service_name: item.service_name,
          service_code: item.service_code,
          checked: true,
        };
      })
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      onClose={() => onCloseModal()}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="text-value w-full text-center font-medium text-textprimary py-10px border-b border-gray-300">
        {title}
      </div>
      {errorModalMessage?.pending === "Y" && (
        <ErrorPopUp error={`${errorModalMessage.description}`} />
      )}
      <div className="flex ml-auto px-4 py-2">
        <PBFESearchbar
          boder=" rounded-full"
          height={34}
          fontSize={12}
          placeholder={placeholder}
          // onChange={(event) => {
          //   requestSearch(
          //     true,
          //     event,
          //     prepireDataWhenSearch,
          //     isSetBack,
          //     setIsSetBack,
          //     setServicesData,
          //     setPrePairDataWhenSearch
          //   );
          // }}
          onChange={requestSearch}
          value={searched}
        />
      </div>
      <DialogContent sx={{ width: "100%", padding: 0 }}>
        {loading ? (
          <div className="mt-5">
            <PBFELoading />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <div className="w-full p-4">Someting went wrong</div>
            ) : (
              <div className="p-large pt-0">
                <PBFECommonTableCheckBox
                  searched={searched}
                  headCells={ADD_SERVICE_CHECKBOX_TABLE_HEADER}
                  rows={rows}
                  showFooter={true}
                  setRows={setRows}
                  orderByDefault={"service_code"}
                  setIsSetBack={setIsSetBack}
                  isSetBack={isSetBack}
                  prepireDataWhenSearch={prepireDataWhenSearch}
                  setPrePairDataWhenSearch={setPrePairDataWhenSearch}
                  setData={setData}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 3, fontSize: 14 }}>
        <Button
          onClick={() => setSelectAll()}
          sx={{ marginRight: "auto" }}
          startIcon={<img src={SelectAll} />}
        >
          Select All
        </Button>
        <Button variant="text" onClick={onCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          disabled={
            rows?.length === 0 || rows?.every((row) => row?.checked === false)
          }
          loading={loading}
          loadingPosition="start"
          variant="contained"
          onClick={() => onClickConfirm()}
        >
          {textButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
