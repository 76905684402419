import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { saveUserLogin } from "../../utils/util";

// components
import { PBFELoading } from "../../components/Elements/Loading/loading";

// api

// redux
import { getTokenThunk, getUserProfileThunk } from "../../redux/authSlice";

function AuthCallback({ setActionAppbar }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    asyncFunction();
  }, [dispatch, location, navigate]);

  const asyncFunction = async () => {
    const search = location.search;
    saveUserLogin(true);

    if (search) {
      const { code, session_state } = queryString.parse(search);
      if (!code || !session_state) {
        return;
      }
      await dispatch(getTokenThunk({ code, session_state })).unwrap();
      await dispatch(getUserProfileThunk()).unwrap();
      navigate("/");
    }
    setActionAppbar("");
  };

  return (
    <div className="h-screen flex items-center justify-center w-full ">
      <PBFELoading />
    </div>
  );
}

export default AuthCallback;
