import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Check from "../../../assets/icon/normal_check.svg";
import { PBFEEList } from "../../Display/pbfeList";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { MORE_THAN_SEARCH_ACTIVE } from "../../../constants/constant";
import customerService from "../../../api/customerService";
import accountService from "../../../api/accountService";
import userService from "../../../api/userService";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import NoRecordFound from "../../Error/noRecordFound";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const layer = {
  customer: { label: "All Customer", placeholder: "Customer ID / Name" },
  account: { label: "All Account", placeholder: "Account Number / Name" },
  service: { label: "All Service", placeholder: "Service Code / Name" },
  charge: { label: "All Charge", placeholder: "Charge Code / Description" },
};

const HookFilter = (data, mode, searched) => {
  if (mode === "charge" || mode === "service") {
    const filter_data = data?.filter((row) => {
      return (
        row.id.toLowerCase().includes(searched.toLowerCase()) ||
        row.name.toLowerCase().includes(searched.toLowerCase())
      );
    });
    return filter_data;
  } else {
    return data;
  }
};

const GenerateReportFilterModal = ({
  open = false,
  onClose,
  onSubmit,
  selected = [],
  mode,
  cacheData,
  setCacheData,
}) => {
  const [data, setData] = React.useState([]);
  const [searched, setSearched] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [prepairDataService, setPrepairDataService] = React.useState([]);
  const [prepairDataCharge, setPrepairDataCharge] = React.useState([]);

  React.useEffect(() => {
    setSelectedData(_.cloneDeep(selected));
    if (selected.length > 0) {
      setData(cacheData[mode]);
    }
  }, [selected, open]);

  React.useEffect(() => {
    setSearched("");

    // ดึง cache data แต่ละ mode

    if (mode) {
      if (cacheData[mode]?.length > 0) {
        setData(cacheData[mode]);
      } else {
        setData([]);
      }
    }
  }, [mode, open]);

  React.useEffect(() => {
    asyncFunction();
  }, []);

  const asyncFunction = async () => {
    const { data: dataCharge } = await userService.getUserCharge();

    setPrepairDataCharge(dataCharge?.data);
  };

  const unSelect = (item, index) => {
    let temp = [...selectedData];
    if (index > -1) temp.splice(index, 1);
    setSelectedData(temp);
  };

  const toogle = (item, selected) => {
    let temp = [...selectedData];
    if (selected) {
      let index = temp.findIndex((element) => element.id === item.id);
      if (index > -1) {
        temp.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      temp.push(item);
    }
    setSelectedData(temp);
  };

  const isSelected = (id) => {
    const found = selectedData.find((element) => element.id === id);
    return found !== undefined;
  };

  const SelectRow = ({ item, index }) => {
    const selected = isSelected(item.id);

    return (
      <div
        className="flex  mt-30px cursor-pointer hover:text-textscb"
        onClick={() => toogle(item, selected)}
      >
        <div className="w-8">
          <img className={selected ? "" : "hidden"} src={Check} />
        </div>
        <div className="text-value font-normal">
          {item.id + " - " + item.name}
        </div>
      </div>
    );
  };

  const getParamQueryString = (query) => {
    const queryKeys = Object.keys(query);
    let queryString = "";
    if (queryKeys.length > 0) {
      const searchParams = new URLSearchParams();
      for (const queryKey of queryKeys) {
        searchParams.append(queryKey, query[queryKey]);
      }
      queryString = `?${searchParams.toString()}`;
    }
    return queryString;
  };

  const mappingDataQuery = (data = []) => {
    if (data.length > 0) {
      return data.map((item) => {
        let sameObj = {};
        if (mode === "customer") {
          sameObj.id = item.customer_id;
          sameObj.name = item.customer_name;
        } else if (mode === "service") {
          sameObj.id = item.service_code;
          sameObj.name = item.service_name;
        } else if (mode === "account") {
          sameObj.id = item.account_id;
          sameObj.name = item.account_name;
        } else if (mode === "charge") {
          sameObj.id = item.charge_code;
          sameObj.name = item.charge_name;
        }
        return sameObj;
      });
    }
    return data;
  };

  const switchModeQuery = async (searched) => {
    setLoading(true);
    try {
      let tempData = { data: { data: [] } };
      let items;

      if (searched?.length > MORE_THAN_SEARCH_ACTIVE) {
        if (mode === "customer") {
          tempData = await customerService.getCustomer(
            {},
            getParamQueryString({
              search_criteria: searched,
              limit: 100,
              offset: 0,
              sort_by: "desc",
              order_by: "customer_name",
            })
          );
        } else if (mode === "account") {
          tempData = await accountService.getAccount(
            {},
            getParamQueryString({
              search_criteria: searched,
              limit: 100,
              offset: 0,
              sort_by: "desc",
              order_by: "account_name",
            })
          );
        } else if (mode === "service") {
          items = HookFilter(
            mappingDataQuery(prepairDataService),
            mode,
            searched
          );
          setData(items);
          return;
        } else if (mode === "charge") {
          // if (isGet) {
          items = HookFilter(
            mappingDataQuery(prepairDataCharge),
            mode,
            searched
          );
          setData(items);
          return;
        }
      } else {
        setData([]);
        return;
      }

      items = HookFilter(mappingDataQuery(tempData.data.data), mode, searched);

      setData(items);
      return;
    } catch (error) {
      console.error("ERROR TO LOAD MODAL", error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = async (e) => {
    const value = e.target.value;
    setSearched(value);
    await switchModeQuery(value);
  };

  const selectAll = () => {
    setCacheData({ ...cacheData, [mode]: [] });
    setSelectedData([]);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <PBFEEList
          selectAll={selectAll}
          selectAllLabel={mode && layer[mode].label}
          action={unSelect}
          arr={selectedData}
          // action={toogle}
        />
        <TextField
          value={searched}
          onChange={onChangeSearch}
          placeholder={mode && layer[mode].placeholder}
          InputProps={{
            style: {
              height: 42,
              fontSize: 14,
              marginTop: 10,
              borderRadius: 45,
              width: "860px",
            },
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className="h-96 overflow-y-auto">
          {loading ? (
            <div className="p-fourteen">
              <Skeleton
                containerTestId="react-loading-skeleton"
                height={30}
                width={`100%`}
                count={9}
              />
            </div>
          ) : (
            <div>
              {searched?.length >= 3 && data?.length <= 0 ? (
                <NoRecordFound />
              ) : (
                <div>
                  {data?.map((item, index) => {
                    return <SelectRow item={item} index={index} />;
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onSubmit(selectedData)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateReportFilterModal;
