import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import useQuery from "../../../hooks/useQuery";
import { PBFELoading } from "../../Elements/Loading/loading";
import LoadingButton from "@mui/lab/LoadingButton";
import PBFESearchbar from "../../Elements/Searchbar/searchbar";
import { PBFECommonTableRadio } from "../../Table/commonTableRadio";
import { useNavigate } from "react-router-dom";
import { MINIMUM_SEARCH_ACTIVE } from "../../../constants/constant";
import { ErrorPopUp } from "./accountModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AsyncModal = ({
  open = false,
  action,
  textButton = "Confirm",
  onClose,
  title = "Title",
  radioValue,
  setRadio,
  serviceApi,
  query = {},
  params = {},
  option_field_label,
  option_field_value,
  orderByDefault,
  loadingAction,
  head_cells = [],
  showFields = [],
  placeholder = "Search",
  callbackFilter = () => {},
  errorModalMessage,
  setErrorModalMessage,
}) => {
  const {
    data: rows,
    setData: setRows,
    loading,
    originalData,
    error,
  } = useQuery(
    serviceApi,
    { ...params },
    {
      ...query,
    }
  );

  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value);
    setPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalData);
      return;
    }
    if (originalData.length === 0) return;
    const filteredRows = originalData.filter(
      callbackFilter(searchedVal.target.value)
    );
    setRows(filteredRows);
  };

  const onCloseModal = () => {
    setPage(0);
    setSearched("");
    setRows(originalData);
    onClose();
    setRadio();
    setErrorModalMessage("");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      // onClose={action}
      onClose={() => onCloseModal()}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="text-value w-full text-center font-medium text-textprimary py-10px border-b border-gray-300">
        {title}
      </div>
      {errorModalMessage?.pending === "Y" && (
        <ErrorPopUp error={`${errorModalMessage.description}`} />
      )}
      <div className="flex justify-end w-full">
        <PBFESearchbar
          boder=" rounded-full"
          height={34}
          fontSize={12}
          placeholder={placeholder}
          onChange={requestSearch}
          value={searched}
        />
      </div>
      <DialogContent sx={{ width: "100%", padding: 0 }}>
        {loading ? (
          <div className="mt-5">
            <PBFELoading />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <div className="w-full p-4">Someting went wrong</div>
            ) : (
              <div className="p-large pt-0">
                <PBFECommonTableRadio
                  open={open}
                  searched={searched}
                  page={page}
                  setPage={setPage}
                  headCells={head_cells}
                  rows={rows}
                  showFooter={true}
                  selectable={true}
                  orderByDefault={orderByDefault}
                  formatControl={[null, null]}
                  onClickRow={(row) => {
                    return;
                  }}
                  showFields={showFields}
                  disabledPagination={true}
                  option_field_label={option_field_label}
                  option_field_value={option_field_value}
                  radioValue={radioValue}
                  setRadio={setRadio}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!radioValue}
          loading={loadingAction}
          loadingPosition="start"
          variant="contained"
          onClick={action}
        >
          {textButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
