import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import makerCheckerService from "../api/makerCheckerService";
import { modalConfig } from "../config";
import { convertErrorApi } from "../utils/util";

export const postSendApproverCustomerService = createAsyncThunk(
  "actionAppbar/addCustomerService",
  async ({ data, setShowConfirm }, thunkAPI) => {
    try {
      await makerCheckerService.postMakerChecker(data);
      return {
        isError: false,
        isSuccess: true,
        message: modalConfig.sendApproverSuccess.description,
      };
    } catch (error) {
      console.error("post add customerService error", error);
      return {
        isError: true,
        isSuccess: false,
        message:
          convertErrorApi(error?.response?.data?.status?.description) ||
          "Error",
      };
    }
  }
);

export const initialStateAppbar = {
  stateComponent: "",
  disabledAction: true,
  loadingAction: false,
  isError: false,
  isSuccess: false,
  confirmText: "",
  message: "",
  onClickAction: () => {},
  onClickBeforeAction: () => {},
};

const actionAppbarSlice = createSlice({
  name: "actionAppbar",
  initialState: initialStateAppbar,
  reducers: {
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setStateComponent: (state, action) => {
      state.stateComponent = action.payload.stateComponent;
      state.onClickAction = action.payload.onClickAction;
      state.onClickBeforeAction = action.payload.onClickBeforeAction;
    },
    setDisabledAction: (state, action) => {
      state.disabledAction = action.payload.disabledAction;
    },
    setConfirmText: (state, action) => {
      state.confirmText = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
  extraReducers: {
    [postSendApproverCustomerService.pending]: (state, action) => {
      state.loadingAction = true;
    },
    [postSendApproverCustomerService.fulfilled]: (state, action) => {
      state.isError = action.payload.isError;
      state.isSuccess = action.payload.isSuccess;
      state.message = action.payload.message;
      state.loadingAction = false;
    },
    [postSendApproverCustomerService.rejected]: (state, action) => {
      state.loadingAction = false;
    },
  },
});

export const {
  setStateComponent,
  setDisabledAction,
  setConfirmText,
  setIsError,

  setErrorMessage,
  setIsSuccess,
} = actionAppbarSlice.actions;

export default actionAppbarSlice.reducer;
