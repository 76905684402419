import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  PRICING_METHOD,
  TIER_TYPE_FORMAT,
  RATE_TYPE,
} from "../../constants/constant";
import {
  GRAY_100,
  GRAY_BORDER_INSIDE,
  TABLE_STYLE,
  UNDERLINE,
  WHITE_BORDER_INSIDE,
} from "../../constants/style";
import {
  calculateRowSpan,
  formatEmpty,
  formatNumberComma,
  formatNumberMax,
  formatNumberMin,
} from "../../utils/util";

export const PBFERetailTable = ({ data }) => {
  const StepRow = ({ row, data, index, underline }) => {
    return (
      <TableRow
        sx={{
          backgroundColor: GRAY_100,
        }}
      >
        {index === 0 && (
          <TableCell
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
            sx={{
              width: "120px",
              backgroundColor: "#FFF",
              borderBottom: WHITE_BORDER_INSIDE,
            }}
          >
            {PRICING_METHOD[data.pricing_method]}
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            sx={{
              borderBottom: WHITE_BORDER_INSIDE,
              backgroundColor: "#FFF",
              minWidth: "300px",
            }}
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
          >
            <div className="grid grid-cols-1 items-center w-full">
              <div className="flex flex-col">
                <div className="whitespace-pre">{data?.qualifiers || "-"}</div>
              </div>

              {/* <div className="flex items-center text-textsecoundary">
                payrollFlag <div className="text-blue-400 ml-2 mr-1">IN</div>
                <div className="text-pink-500">
                  {JSON.stringify(data?.qualifiers?.payrollFlag)}
                </div>
              </div>
              <div className="flex items-center text-textsecoundary">
                tranCode <div className="text-blue-400 ml-2 mr-1">IN</div>
                <div className="text-pink-500">
                  {JSON.stringify(data?.qualifiers?.tranCode)}
                </div>
              </div> */}
            </div>
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            sx={{
              minWidth: "60px",
              borderBottom: GRAY_BORDER_INSIDE,
            }}
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
          >
            {TIER_TYPE_FORMAT[data.fee_value.tier]}
          </TableCell>
        )}
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberMin(row.from))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberMax(row.to))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberComma(row.fix_amount))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {row.rate}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(RATE_TYPE[row.rate_type])}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
            width: "80px",
          }}
        >
          {formatEmpty(formatNumberComma(row.int ? `${row.int}%` : row.int))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
            width: "80px",
          }}
        >
          {formatEmpty(formatNumberComma(row.min_tier))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: underline ? UNDERLINE : "0px",
            width: "80px",
          }}
        >
          {formatEmpty(formatNumberComma(row.max_tier))}
        </TableCell>

        {index === 0 && (
          <TableCell
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
            sx={{
              width: "110px",
              borderBottom: GRAY_BORDER_INSIDE,
            }}
          >
            {formatEmpty(formatNumberComma(data.fee_value.min))}
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
            sx={{
              width: "110px",
              borderBottom: GRAY_BORDER_INSIDE,
            }}
          >
            {formatEmpty(formatNumberComma(data.fee_value.max))}
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            rowSpan={calculateRowSpan(data?.fee_value?.value?.length)}
            sx={{
              width: "110px",
              borderBottom: GRAY_BORDER_INSIDE,
            }}
          >
            {formatEmpty(formatNumberComma(data?.fee_value?.monthly_max))}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div className="border-t border-b overflow-x-auto	">
      <TableContainer>
        <Table sx={TABLE_STYLE}>
          <TableHead>
            <TableRow>
              <TableCell>Pricing Method </TableCell>
              <TableCell>Qualifier</TableCell>
              <TableCell>Tier</TableCell>
              <TableCell className="w-24">From</TableCell>
              <TableCell className="w-24">To</TableCell>
              <TableCell className="w-28">Fixed Amount</TableCell>
              <TableCell className="w-16">Rate</TableCell>
              <TableCell className="w-16">Type</TableCell>
              <TableCell>Interest</TableCell>
              <TableCell>Tier Min</TableCell>
              <TableCell>Tier Max</TableCell>
              <TableCell>Min Charge</TableCell>
              <TableCell>Max Charge</TableCell>
              <TableCell>CAP / Month</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.fee_value?.value?.map((row, index) => {
              return (
                <StepRow
                  key={index}
                  row={row}
                  data={data}
                  index={index}
                  underline={
                    index < data?.fee_value?.value?.length - 1 || index > 0
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
