import React from "react";
import { PBFEContent } from "../../Elements/Text/content";
import { PBFEChargesTable } from "../../Table/chargesTable";
import { PBFERetailTable } from "../../Table/retailTable";
import { PBFEAccordionMain } from "../Accordion/accordionMain";

export const RetailDetail = ({ data }) => {
  return (
    <div className="w-full ">
      <div className=" pl-12 mt-5 mb-2 text-sm">
        <PBFEContent
          label="Enrollment Criteria"
          value={
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="whitespace-pre">
                  {data?.enrollment_condition ||
                    data?.enrollment_conditions ||
                    "-"}
                </div>
              </div>
            </div>
          }
        />
      </div>
      <PBFEAccordionMain
        label="Charges"
        detail={
          <PBFEChargesTable
            type="Offer"
            data={data?.offer_fee_charge}
            displayQualifier={false}
            displayPricingDate={true}
          />
        }
      />
      {data?.offer_fee_charge?.map((charge, index) => {
        return (
          <React.Fragment key={index}>
            <PBFEAccordionMain
              id={charge.charge_code}
              label={`${charge.charge_code} - ${charge.charge_name}`}
              detail={
                <PBFERetailTable displayLimit displayFrequency data={charge} />
              }
            />
            <div className="h-10px bg-gray-100"></div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
