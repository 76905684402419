import React, { memo, useCallback, useEffect, useState } from "react";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEContent } from "../../components/Elements/Text/content";
import PBFEError from "../../components/Error/alertError";
import {
  PBFEAccordionMain,
  PBFEAccordionSearch,
  PBFEAccordionWithButton,
} from "../../components/Panel/Accordion/accordionMain";
import { AsyncModal } from "../../components/Panel/Modal/asyncModal";
import {
  ADD_OFFER_TABLE_HEADER,
  ADD_SERVICE_TABLE_HEADER,
  CUSTOMER_TITLE,
  HEAD_ACCOUNT_CELLS,
  MAPPING_CUSTOMER_TYPE,
  MAPPING_GENDER,
  MAPPING_PAYROLL_INDICATOR,
  MINIMUM_SEARCH_ACTIVE,
} from "../../constants/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Plus from "../../assets/icon/plus.svg";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { TextField } from "@mui/material";
import NoFound from "../../components/Error/noFound";
import {
  Information,
  OfferInformation,
  OfferRetailInformation,
  ServiceInformation,
} from "../../components/Panel/Information/information";
import useQuery from "../../hooks/useQuery";
import customerService from "../../api/customerService";
import {
  checkCurrentDate,
  checkErrorDate,
  checkFeeChargeAllDelete,
  formatDateApi,
  formateDate,
  formatEmpty,
  getFeeChargeOfGroup,
  getValidateOfferError,
  onScrollToTop,
} from "../../utils/util";
import { PBFECommonTable } from "../../components/Table/commonTable";
import { useSelector } from "react-redux";
import { HistoryModal } from "../../components/Panel/Modal/historyModal";
import serviceService from "../../api/serviceService";
import offerService from "../../api/offersService";
import {
  setStateComponent,
  postSendApproverCustomerService,
  setDisabledAction,
  setConfirmText,
} from "../../redux/actionAppbarSlice";
import { PBFEHeader } from "../../components/Elements/Text/header";
import {
  mappingServiceApprover,
  mappingOfferApprover,
  mappingServiceEditApprover,
  mappingOfferEditApprover,
} from "../../utils/mappingData";
import { modalConfig } from "../../config";
import PBFEInvalid from "../../components/Error/invalidData";
import _ from "lodash";
import makerCheckerService from "../../api/makerCheckerService";
import historyService from "../../api/historyService";
import HISTORY_IMAGE from "../../assets/icon/history.svg";

export const GeneralInfo = ({ data }) => {
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2">
      <PBFEContent
        label="Customer Title - Thai"
        value={formatEmpty(data.title_thai)}
      />
      <PBFEContent
        label="Customer Full Name (Eng)"
        value={formatEmpty(data.full_name_eng)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="Customer Full Name (Thai)"
          value={formatEmpty(data.full_name_thai)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Date of Incorporation/Date of Birth"
          value={formateDate(data?.date_of_birth)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Gender"
          value={formatEmpty(MAPPING_GENDER[data.gender])}
        />
      </div>
      <PBFEContent
        label="Entry Date"
        value={formateDate(data.entry_date, "-")}
      />
      <PBFEContent
        label="Application Date"
        value={formateDate(data.application_date)}
      />
      <PBFEContent label="Open Date" value={formateDate(data.open_date)} />
      <PBFEContent label="ID Type" value={formatEmpty(data.id_type)} />
      <div className="col-span-2">
        <PBFEContent
          label="Payroll Indicator"
          value={formatEmpty(data?.payroll_indicator)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Customer Type"
          value={formatEmpty(MAPPING_CUSTOMER_TYPE[data.customer_type])}
        />
      </div>

      <PBFEContent
        label="Contact Name1"
        value={formatEmpty(data.contact_name1)}
      />
      <PBFEContent
        label="Contact Name2"
        value={formatEmpty(data.contact_name2)}
      />
      <PBFEContent
        label="Contact Number1"
        value={formatEmpty(data.contact_number1)}
      />
      <PBFEContent
        label="Contact Number2"
        value={formatEmpty(data.contact_number2)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="User Defined Status"
          value={formatEmpty(data.status)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Sub Segment"
          value={formatEmpty(data.sub_segment_code)}
        />
      </div>
    </div>
  );
};

export const AccountInfo = memo(
  ({ searchedPage, accountPage, setAccountPage, accountTable }) => {
    const navigate = useNavigate();

    return (
      <PBFECommonTable
        sort_name_by="account_name"
        searched={searchedPage}
        page={accountPage}
        setPage={setAccountPage}
        headCells={HEAD_ACCOUNT_CELLS}
        rows={accountTable}
        showFooter={true}
        selectable={true}
        orderByDefault="account_name"
        onClickRow={(row) => {
          navigate(`/account/${row?.account_id}`);
        }}
        formatControl={[formatEmpty, formatEmpty]}
        showFields={["account_name", "account_id"]}
      />
    );
  }
);

export const AddNewOfferDuplicate = ({
  dataRequest,
  data,
  customer,
  service_code,
  accountTable,
  debitProfile,
  setDebitProfile,
  isAddActionService,
  originalData,
  setErrorState,
  is_maker,
  errorState,
  setCheckConfirmError,
  checkConfirmError,
  setRouteName,
  setIsNewService,
}) => {
  useEffect(() => {
    setRouteName([
      { title: CUSTOMER_TITLE, path: "/customer" },
      {
        title: `${dataRequest.customer_name}`,
        path: `/customer/${dataRequest.customer_id}`,
        setSelected: setIsNewService,
        check: true,
      },
      {
        title: "ADD OFFER",
      },
    ]);
  }, [dataRequest]);

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto ">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Customer Name" value={customer?.customer_name} />
          <PBFEContent label="Customer ID" value={customer?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Offer"
          detail={
            <CustomerOffer
              dataRequest={dataRequest}
              checkConfirmError={checkConfirmError}
              setCheckConfirmError={setCheckConfirmError}
              is_maker={is_maker}
              customer={customer}
              originalData={originalData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setErrorState={setErrorState}
              data={{
                offer: [
                  {
                    ...data,
                    posting_account: data.posting_account || [],
                  },
                ],
              }}
              isEdit={true}
              isNew={true}
              accountTable={accountTable}
            />
          }
        />
      </div>
    </div>
  );
};

export const AddNewOffer = ({
  customer,
  offer_code,
  accountTable,
  isAddActionOffer,
  debitProfile,
  setDebitProfile,
  originalData,
  errorState,
  setErrorState,
  setRadioOffer,
  is_maker,
  checkConfirmError,
  setCheckConfirmError,
}) => {
  const prePareData = (data) => {
    return {
      ...data,
      posting_account: [],
      from_date: formatDateApi(),
      to_date: "",
    };
  };

  const { data, error, loading } = useQuery(
    offerService.getOffersInfo,
    {
      offer_code: offer_code,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  React.useEffect(() => {
    setRadioOffer(null);
  }, []);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto ">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Customer Name" value={customer?.customer_name} />
          <PBFEContent label="Customer ID" value={customer?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Offer"
          detail={
            isAddActionOffer ? (
              <div></div>
            ) : (
              <CustomerOffer
                errorState={errorState}
                checkConfirmError={checkConfirmError}
                setCheckConfirmError={setCheckConfirmError}
                is_maker={is_maker}
                customer={customer}
                originalData={originalData}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                setErrorState={setErrorState}
                data={{
                  offer: [
                    {
                      ...data,
                    },
                  ],
                }}
                isEdit={true}
                isNew={true}
                accountTable={accountTable}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export const CustomerService = ({
  dataRequest = {},
  data,
  accountTable = [],
  hideButton = false,
  isEdit = false,
  isNew = false,
  setIsEdit,
  setDebitProfile,
  debitProfile,
  setIsEditWhereData,
  customer,
  originalData,
  setErrorState,
  is_maker,
  setRouteName,
  errorState,
  checkConfirmError,
  setCheckConfirmError,
  refetch,
}) => {
  const [dataService, setDataService] = useState([]);
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");

  useEffect(() => {
    setDataService(data?.service);
    setRemark(data?.remark || "");
  }, []);

  useEffect(() => {
    if (dataService.length > 0) {
      let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
      const checkCharge = checkFeeChargeAllDelete(fee_charge);

      if (!checkCharge) {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "all_fee_charge");
          return [...temp];
        });
      }
    }
  }, [dataService]);

  useEffect(() => {
    const dontDoThis = !dataService?.length || !isNew || !isEdit;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    }
  }, [
    dataService,
    debitProfile,
    errorState,
    checkConfirmError,
    errorState,
    dataRequest,
    originalData,
  ]);

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    const checkErroDateList = checkErrorDate(dataService[0]);
    if (checkErroDateList.length > 0) {
      const unqiue_error_state = _.unionBy(checkErroDateList, "field_name");
      setErrorState((prev) => [...prev, ...unqiue_error_state]);
      checkError = true;
    }

    let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
    const checkCharge = checkFeeChargeAllDelete(fee_charge);

    if (checkCharge) {
      setErrorState((prev) => [
        ...prev,
        {
          error: true,
          message: "Please add fee charge at least 1 charge",
          field_name: "all_fee_charge",
          field_value: [],
          show: true,
        },
      ]);
      checkError = true;
    }

    if (!debitProfile.length) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    }

    if (errorState?.length > 0) {
      checkError = true;
    }

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    dispatch(
      postSendApproverCustomerService({
        data: mappingServiceApprover(
          {
            ...customer,
            service_name: dataService[0].service_name,
            service_code: dataService[0].service_code,
            posting_account: debitProfile,
            charge_group: dataService[0].charge_group,
          },
          originalData,
          dataRequest,
          remark
        ),
      })
    );
  };

  if (!data?.service?.length) return <NoFound label={"No Service Found"} />;

  return (
    <div>
      {dataService?.map((sevice_self, index) => {
        return (
          <Information
            refetch={refetch}
            customer={customer}
            setRouteName={setRouteName}
            is_maker={is_maker}
            originBigData={sevice_self}
            setIsEdit={setIsEdit}
            key={index}
            origin={dataService}
            index={index}
            account_table={sevice_self.posting_account}
            label={sevice_self.service_name + " - " + sevice_self.service_code}
            data={sevice_self}
            setData={setDataService}
            accountTable={accountTable}
            hideButton={hideButton}
            isEdit={isEdit}
            isNew={isNew}
            setIsEditWhereData={setIsEditWhereData}
            type="service"
            setDebitProfile={setDebitProfile}
            debitProfile={debitProfile}
            setErrorState={setErrorState}
            setCheckConfirmError={setCheckConfirmError}
          />
        );
      })}
      {(isEdit || isNew) && (
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            fullWidth
            helperText={"Limited " + remark?.length + "/300"}
            inputProps={{ maxLength: 300 }}
          />
        </div>
      )}
    </div>
  );
};

export const CustomerOffer = ({
  customer,
  data,
  accountTable = [],
  hideButton = false,
  setIsEdit,
  isEdit = false,
  isNew = false,
  setIsEditWhereData,
  debitProfile,
  setDebitProfile,
  originalData,
  setErrorState,
  is_maker,
  setRouteName,
  dataRequest = {},
  checkConfirmError,
  setCheckConfirmError,
  errorState,
  refetch,
  setDataRequest,
}) => {
  const dispatch = useDispatch();
  const [dataOffer, setDataOffer] = useState([]);
  const [remark, setRemark] = useState("");

  useEffect(() => {
    setDataOffer(data?.offer);
    setRemark(data?.remark ? data?.remark : data?.remark);
  }, []);

  useEffect(() => {
    const dontDoThis = !dataOffer.length || !isNew || !isEdit;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    }
  }, [
    dataOffer,
    debitProfile,
    dataRequest,
    errorState,
    checkConfirmError,
    setCheckConfirmError,
    originalData,
    remark,
  ]);

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    const listError = getValidateOfferError(_.cloneDeep(dataOffer[0]));
    if (listError.length > 0) {
      checkError = true;
      setErrorState((prev) => {
        return [...prev, { field_name: "validate_error", show: false }];
      });
    } else {
      setErrorState((prev) =>
        prev.filter((item) => item.field_name !== "validate_error")
      );
    }

    if (!debitProfile.length) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    }

    if (
      errorState?.filter(
        (item) => !["validate_error"].includes(item.field_name)
      ).length > 0
    ) {
      checkError = true;
    }

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    // alert("Add Offer")
    dispatch(
      postSendApproverCustomerService({
        data: mappingOfferApprover(
          {
            ...customer,
            ...dataOffer[0],
            posting_account: debitProfile,
          },
          originalData,
          dataRequest,
          remark
        ),
      })
    );
  };
  if (!data?.offer?.length) {
    return <NoFound label={"No Offer Found"} />;
  } else {
    return (
      <div>
        {dataOffer?.map((offer, index) => {
          return (
            <Information
              setDataRequest={setDataRequest}
              refetch={refetch}
              customer={customer}
              setRouteName={setRouteName}
              is_maker={is_maker}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              key={index}
              disabledDebitProfile={true}
              isOffer={true}
              account_table={offer?.posting_account}
              label={offer?.offer_name + " - " + offer?.offer_code}
              data={offer}
              setData={setDataOffer}
              index={index}
              accountTable={accountTable}
              setIsEditWhereData={setIsEditWhereData}
              hideButton={hideButton}
              isNew={isNew}
              type="offer"
              setDebitProfile={setDebitProfile}
              debitProfile={debitProfile}
              setErrorState={setErrorState}
              setCheckConfirmError={setCheckConfirmError}
            />
          );
        })}
        {(isEdit || isNew) && (
          <div className="ml-40px mr-25px">
            <TextField
              id="remark"
              onChange={(e) => setRemark(e.target.value)}
              value={remark}
              label="REMARK"
              variant="filled"
              fullWidth
              helperText={"Limited " + remark?.length + "/300"}
              inputProps={{ maxLength: 300 }}
            />
          </div>
        )}
      </div>
    );
  }
};

export const PBFECustomerInfo = ({
  setRouteName,
  dataRequest,
  setActionAppbar,
  setDataRequest,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const prePareData = (data) => {
    const offer = data?.offer?.map((offer) => {
      return {
        ...offer,
        checkCurrentDate: checkCurrentDate(
          new Date(offer.from_date),
          new Date(offer.to_date)
        ),
      };
    });

    return {
      ...data,
      offer,
    };
  };

  const { data, setData, loading, error, refetch } = useQuery(
    customerService.getCustomerById,
    {
      customer_id: params.customer_id,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  const [accountTable, setAccountTable] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchedPage, setSearchedPage] = useState("");
  const [accountPage, setAccountPage] = useState(0);
  const [radioService, setRadioService] = useState(null);
  const [radioOffer, setRadioOffer] = useState(null);
  const [isAddActionService, setIsAddActionService] = useState(false);
  const [isAddActionOffer, setIsAddActionOffer] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isNewService, setIsNewService] = useState(false);
  const [isNewOffer, setIsNewOffer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditWhereData, setIsEditWhereData] = useState("");
  const [debitProfile, setDebitProfile] = useState([]);
  const [errorState, setErrorState] = useState([]);
  const [checkConfirmError, setCheckConfirmError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [remark, setRemark] = useState("");

  const [errorModalMessage, setErrorModalMessage] = useState("");

  useEffect(() => {
    setActionAppbar("CUSTOMER_SERVICE");
    setErrorModalMessage("");
    if (!data || isNewService || isNewOffer) return;
    dispatch(
      setStateComponent({
        stateComponent: "customerService",
        onClickAction: onClickSendToApprover,
        onClickBeforeAction: onClickBeforeAction,
      })
    );
    dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    if (!isEditWhereData) {
      dispatch(setDisabledAction({ disabledAction: true }));
    }
  }, [data, isEditWhereData, debitProfile, errorState, dataRequest]);

  useEffect(() => {
    if (data) {
      setAccountTable(data.account);
      setOriginalData(data.account);
    }
  }, [setRouteName, data]);

  useEffect(() => {
    if (isNewService || isNewOffer || isEdit) {
      setCheckConfirmError(false);
      setErrorState([]);
    }
  }, [isNewOffer, isNewService, isEdit]);

  useEffect(() => {
    if (data && !isEdit) {
      setRouteName([
        { title: CUSTOMER_TITLE, path: "/customer" },
        {
          title: `${data.customer_name}`,
          path: `/customer/${params.customer_id}`,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    setRemark("");
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [isEdit, isNewOffer, isNewService]);

  const onBackButtonEvent = async () => {
    if (isEdit || isNewService || isNewOffer) {
      setIsEdit(false);
      setIsNewService(false);
      setIsNewOffer(false);
      setRadioService(null);
      setRadioOffer(null);
      dispatch(setDisabledAction({ disabledAction: true }));
      await refetch({ customer_id: params.customer_id }, {});
    } else {
      navigate("/customer");
    }
  };

  useEffect(() => {
    const queryStringUrl = queryString.parse(location.search);

    if (Object.keys(dataRequest).length > 0 && queryStringUrl.transaction_id) {
      setDuplicate(true);
      dispatch(setDisabledAction({ disabledAction: false }));
      if (dataRequest.transaction_type === "NEWSERVICE") {
        setIsNewService(true);
      } else if (dataRequest.transaction_type === "UPDATESERVICE") {
        setIsEdit(true);
        setIsEditWhereData(_.cloneDeep(dataRequest.raw_data));
        setRouteName([
          { title: CUSTOMER_TITLE, path: `/${dataRequest.customer_name}` },
          {
            title: `${dataRequest.customer_name}`,
            path: `/customer/${dataRequest.customer_id}`,
            setSelected: setIsEdit,
            check: true,
          },
          {
            title: "EDIT SERVICE",
          },
        ]);
      } else if (dataRequest.transaction_type === "NEWOFFER") {
        setIsNewOffer(true);
      } else if (dataRequest.transaction_type === "UPDATEOFFER") {
        setIsEdit(true);
        setIsEditWhereData(_.cloneDeep(dataRequest.raw_data));
        setRouteName([
          { title: CUSTOMER_TITLE, path: `/${dataRequest.customer}` },
          {
            title: `${dataRequest.customer_name}`,
            path: `/customer/${dataRequest.customer_id}`,
            setSelected: setIsEdit,
            check: true,
          },
          {
            title: "EDIT OFFER",
          },
        ]);
      }
    }
  }, [dataRequest]);

  const requestSearch = (searchedVal) => {
    setSearchedPage(searchedVal.target.value);
    setAccountPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setAccountTable(originalData);
      return;
    }
    if (originalData.length === 0) return;
    const filteredRows = originalData.filter((row) => {
      return (
        row.account_name
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase()) ||
        row?.account_id
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase())
      );
    });
    setAccountTable(filteredRows);
  };

  const addService = (e) => {
    e.stopPropagation();
    setIsAddActionService(true);
  };

  const addOffer = (e) => {
    e.stopPropagation();
    setIsAddActionOffer(true);
  };

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    const listError = getValidateOfferError(_.cloneDeep(isEditWhereData));
    console.log("listError", listError);

    if (listError.length > 0) {
      checkError = true;
      setErrorState((prev) => {
        return [...prev, { field_name: "validate_error", show: false }];
      });
    } else {
      setErrorState((prev) =>
        prev.filter((item) => item.field_name !== "validate_error")
      );
    }

    if (
      !debitProfile.filter((profile) => profile?.action !== "DELETE").length
    ) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    } else {
      const originalData = dataRequest?.hasOwnProperty("raw_data")
        ? { offer: [_.omit(dataRequest?.raw_data, ["action"])] }
        : JSON.parse(sessionStorage.getItem("originalData"));
      const findOfferOriginal = originalData.offer.find(
        (offer) => offer.offer_code === isEditWhereData.offer_code
      );

      const cloneDebitProfile = _.cloneDeep(debitProfile);
      const clonePositingAccount = _.cloneDeep(
        findOfferOriginal.posting_account
      );

      const cloneFindOfferOriginal = _.cloneDeep(findOfferOriginal);
      const cloneIsEditWhereData = _.cloneDeep(isEditWhereData);

      delete cloneFindOfferOriginal.posting_account;
      delete cloneIsEditWhereData.posting_account;

      const compareOfferOriginal = JSON.stringify(cloneFindOfferOriginal);
      const compareOffer = JSON.stringify(cloneIsEditWhereData);

      const compareDebitProfile = JSON.stringify(cloneDebitProfile);
      const comparePostingAccount = JSON.stringify(clonePositingAccount);

      if (
        compareOfferOriginal === compareOffer &&
        compareDebitProfile === comparePostingAccount
      ) {
        setErrorState((prev) => {
          const find_exists = prev.find(
            (item) => item.field_name === "not_update"
          );
          if (!find_exists) {
            prev.push({
              error: true,
              message: "No data changed",
              field_name: "not_update",
              field_value: [],
              show: true,
            });
          }
          return [...prev];
        });
        checkError = true;
      } else {
        setErrorState((prev) => {
          const filterExist = prev.filter(
            (item) => item.field_name !== "not_update"
          );
          return [...filterExist];
        });
      }
    }

    if (
      errorState.filter((item) => {
        return !["not_update", "validate_error"].includes(item.field_name);
      }).length > 0
    ) {
      checkError = true;
    }

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    dispatch(
      postSendApproverCustomerService({
        data: mappingOfferEditApprover(
          {
            ...isEditWhereData,
            posting_account: debitProfile,
          },
          dataRequest,
          remark
        ),
      })
    );
  };

  const actionModalOffer = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${params.customer_id}&code=${radioOffer}&tran_type=NEWOFFER`
        );

      if (responseData.data.pending === "N") {
        setIsNewOffer(true);
        setIsAddActionOffer(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: CUSTOMER_TITLE, path: "/customer" },
          {
            title: `${data.customer_name}`,
            path: `/customer/${params.customer_id}`,
            setSelected: setIsNewOffer,
            check: true,
          },
          {
            title: "ADD OFFER",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const callbackFilterOffer = (value) => (row) => {
    return (
      row.offer_name.toLowerCase().includes(value.toLowerCase()) ||
      row.offer_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  const callbackNavigate = (row) => {
    navigate(`/customer/${params.customer_id}/history/${row.request_id}`);
  };

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  if (isNewOffer) {
    return (
      <React.Fragment>
        {duplicate ? (
          <AddNewOfferDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <AddNewOffer
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioOffer}
            setRadioOffer={setRadioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}
        {isAddActionOffer && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Offer"
            open={isAddActionOffer}
            onClose={() => setIsAddActionOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioOffer}
            serviceApi={customerService.getOfferCustomerById}
            params={{
              customer_id: params.customer_id,
            }}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalOffer}
            loadingAction={loadingAction}
            placeholder={"Search Offer Name / Code"}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
          />
        )}
      </React.Fragment>
    );
  } else if (isEdit) {
    return (
      <div id="edit" className="w-full">
        <PBFEInvalid
          isShow={checkConfirmError && errorState.length > 0}
          message=""
          setOpen={setCheckConfirmError}
          open={checkConfirmError}
          errorState={errorState}
        />

        <div className="w-full overflow-auto">
          <div className="grid grid-cols-2 w-full px-xl my-20px">
            <PBFEContent label="Customer Name" value={data?.customer_name} />
            <PBFEContent label="Customer ID" value={data?.customer_id} />
          </div>
          <div className="h-10px bg-gray-100"></div>
          <React.Fragment>
            <PBFEAccordionMain
              label={"Offer"}
              detail={
                <PBFEAccordionMain
                  pl="ml-20px"
                  customHeader={
                    <PBFEHeader
                      p=""
                      label={
                        isEditWhereData?.offer_name +
                        " - " +
                        isEditWhereData?.offer_code
                      }
                      purple={true}
                      hideButton={true}
                    />
                  }
                  detail={
                    <OfferRetailInformation
                      data={isEditWhereData}
                      index={0}
                      origin={origin}
                      setData={setData}
                      account_table={isEditWhereData?.posting_account}
                      accountTable={accountTable}
                      isEdit={isEdit}
                      setDebitProfile={setDebitProfile}
                      debitProfile={debitProfile}
                      setErrorState={setErrorState}
                      setDataDuplicate={setIsEditWhereData}
                    />
                  }
                />
              }
            />
          </React.Fragment>
        </div>
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            fullWidth
            helperText={"Limited " + remark?.length + "/300"}
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <PBFEInvalid
          isShow={checkConfirmError && errorState.length > 0}
          message=""
          setOpen={setCheckConfirmError}
          open={checkConfirmError}
          errorState={errorState}
        />
        <div className="w-full overflow-auto ">
          {!isNewOffer && isAddActionOffer && (
            <AsyncModal
              setErrorModalMessage={setErrorModalMessage}
              errorModalMessage={errorModalMessage}
              title="Add Offer"
              open={isAddActionOffer}
              onClose={() => setIsAddActionOffer(false)}
              radioValue={radioOffer}
              setRadio={setRadioOffer}
              serviceApi={customerService.getOfferCustomerById}
              query={{
                customer_id: params.customer_id,
                user_id: user?.info?.preferred_username,
              }}
              params={{
                customer_id: params.customer_id,
              }}
              option_field_label="offer_name"
              option_field_value="offer_code"
              action={actionModalOffer}
              loadingAction={loadingAction}
              orderByDefault="offer_name"
              head_cells={ADD_OFFER_TABLE_HEADER}
              showFields={["offer_name", "offer_code"]}
              callbackFilter={callbackFilterOffer}
              placeholder="Search Offer Name / Code"
            />
          )}
          {showHistory && (
            <HistoryModal
              callbackNavigate={callbackNavigate}
              open={showHistory}
              onClose={() => setShowHistory(false)}
              serviceApi={historyService.getHistoryById}
              query={{
                customer_id: params.customer_id,
                // account_id: "147645013000036",
                offset: 0,
                limit: 100,
              }}
            />
          )}

          <div className="grid grid-cols-2 w-full px-xl my-20px">
            <PBFEContent label="Customer Name" value={data?.customer_name} />
            <PBFEContent label="Customer ID" value={data?.customer_id} />
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowHistory(true);
            }}
            className="flex  items-center ml-auto justify-end space-x-2  px-xl mb-8 mr-4px  text-textscb text-sm"
          >
            <img className="cursor-pointer" src={HISTORY_IMAGE} />
            <div className="cursor-pointer">HISTORY</div>
          </button>

          <div className="h-10px bg-gray-100"></div>
          <PBFEAccordionMain
            label="General Info"
            detail={<GeneralInfo data={data} />}
          />
          <div className="h-10px bg-gray-100"></div>
          <PBFEAccordionSearch
            label="Account"
            requestSearch={requestSearch}
            searched={searchedPage}
            placeholderSearch={"Search Account Name / No."}
            detail={
              <AccountInfo
                searchedPage={searchedPage}
                accountPage={accountPage}
                setAccountPage={setAccountPage}
                accountTable={accountTable}
              />
            }
          />
          <div className="h-10px bg-gray-100"></div>
          <PBFEAccordionWithButton
            buttons={
              (data?.is_maker === "Y" && [
                { img: Plus, name: "ADD OFFER", action: addOffer },
              ]) ||
              []
            }
            label="Offer"
            detail={
              <CustomerOffer
                setDataRequest={setDataRequest}
                refetch={refetch}
                customer={{
                  customer_id: data.customer_id,
                  customer_name: data.customer_name,
                }}
                is_maker={data?.is_maker === "Y"}
                data={data}
                accountTable={accountTable}
                isEdit={isEdit}
                setRouteName={setRouteName}
                setIsEdit={setIsEdit}
                setIsEditWhereData={setIsEditWhereData}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                setCheckConfirmError={setCheckConfirmError}
                setErrorState={setErrorState}
              />
            }
          />
        </div>
      </div>
    );
  }
};
