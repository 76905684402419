import { PBFEContent } from "../../Elements/Text/content";
import HISTORY_IMAGE from "../../../assets/icon/history.svg";

export const PBFETaxInfo = ({
  data,
  type,
  container = "pl-xl pr-20px py-lg",
  setShowHistory,
  hasStatement = false,
}) => {
  if (!data) return <div></div>;
  return (
    <div className={container}>
      <div className="grid grid-cols-4">
        {type !== "Revenue" && (
          <div className="w-full col-span-2">
            <PBFEContent
              label="Name"
              value={type === "Tax" ? data?.tax_scheme_name : data?.gl_name}
            />
          </div>
        )}
        {type !== "Revenue" && (
          <div className="w-content">
            <PBFEContent
              label="Type"
              value={type === "Tax" ? data?.tax_type : data?.gl_type}
            />
          </div>
        )}
        {type !== "Revenue" && (
          <div className="w-content">
            <PBFEContent
              label="Current Rate"
              value={
                type === "Tax"
                  ? (data?.current_tax_rate || "- ") + "%"
                  : (data?.gl_rate || "- ") + "%"
              }
            />
          </div>
        )}
      </div>

      {hasStatement ? (
        <>
          <div
            className={
              "grid grid-cols-4 " + (type !== "Revenue" ? "mt-25px" : "")
            }
          >
            <div className="col-span-2">
              <PBFEContent
                label="Alt. Statement Description"
                value={data?.alt_statement_desc}
              />
            </div>
            <PBFEContent
              label="Alt. GL Account Code"
              value={data?.gl_account_code}
            />
            <PBFEContent label="Alt. GL Tax Code" value={data?.gl_tax_code} />
          </div>
          <div className="grid grid-cols-4 mt-25px">
            <PBFEContent
              label="Alt. GL Description"
              value={data?.gl_description}
            />
            <PBFEContent
              label="Alt. GL Product Code"
              value={data?.gl_product_code}
            />
            <PBFEContent label="Alt. GL RC" value={data?.gl_rc_code} />
            <PBFEContent label="Alt. GL OC" value={data?.gl_oc_code} />
          </div>
        </>
      ) : (
        <>
          <div
            className={
              "grid grid-cols-4 " + (type !== "Revenue" ? "mt-25px" : "")
            }
          >
            <PBFEContent label="GL Description" value={data?.gl_description} />
            <PBFEContent label="Product Code" value={data?.gl_product_code} />
            <PBFEContent label="Account Code" value={data?.gl_account_code} />
            <PBFEContent label="Tax Code" value={data?.gl_tax_code} />
          </div>
          <div className="grid grid-cols-4 mt-25px">
            <PBFEContent label="GL RC" value={data?.gl_rc_code} />
            <PBFEContent label="GL OC" value={data?.gl_oc_code} />
          </div>
        </>
      )}

      {setShowHistory && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowHistory(true);
          }}
          className="flex  items-center ml-auto justify-end space-x-2   text-textscb text-sm"
        >
          <img className="cursor-pointer" src={HISTORY_IMAGE} />
          <div className="cursor-pointer">HISTORY</div>
        </button>
      )}
    </div>
  );
};
