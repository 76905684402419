import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { PBFEContent } from "../../Elements/Text/content";
import { PBFEAutocomplete } from "../../Input/autocomplete";
import PBFEFieldTextInput from "../../Input/fieldTextInput";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  getErrorToDateMax,
  pad,
  checkInvalidPast,
  validateFormatDate,
} from "../../../utils/util";
import api from "../../../api";
import { apiConfig } from "../../../config";
import {
  checkDateAD,
  checkEffectiveDateFutureDebitProfile,
  checkFromToDateDebitProfile,
  FunctionCheckDateValid,
  getNameFromDebitProfileSeleted,
  TEXT_FIELD_DATE_INVALID,
  TEXT_FIELD_ERROR_DATA_NOT_FOUND,
  TEXT_FIELD_ERROR_DATE,
  TEXT_FIELD_ERROR_DATE_FROM_TO,
  TEXT_FIELD_ERROR_DATE_FUTURE,
  TEXT_FIELD_ERROR_DATE_NEXT_STEP,
  TEXT_FIELD_ERROR_DUPLICATED,
} from "../../../functions";
import {
  NULL_DATE,
  TEXT_FIELD_DATE_INVALID_AD,
  TEXT_FIELD_DATE_INVALID_PAST,
} from "../../../constants/constant";
import { ERROR_MESSAGE_REQUIRED } from "../../../utils/errorMessage";
import { trim } from "lodash";
// import { NULL_DATE } from "../../../constants/constant";

export const ErrorPopUp = ({ error }) => {
  return (
    <div
      className="flex bg-red-100 rounded-lg p-4 mb-4 text-sm text-red-700"
      role="alert"
    >
      <svg
        className="w-5 h-5 inline mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      <div>
        <span className="font-medium">{error}</span>
      </div>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AccountModal = ({
  open = false,
  action,
  title = "",
  textButton = "OK",
  onClose,
  accountTable,
  selectAccount,
  setSelectAccount,
  debitProfile,
  apiData = {},
  setApiData = () => {},
  accountSearch,
  setAccountSearch = () => {},
  type = "NEW_ACCOUNT",
}) => {
  const [error, setError] = React.useState(false);
  const [errorLength, setErrorLength] = React.useState(false);
  const [errorDuplicate, setErrorDuplicate] = React.useState(false);
  const [errorToMax, setErrorToMax] = React.useState(false);

  const debit_account =
    selectAccount && getNameFromDebitProfileSeleted(selectAccount);

  // const isDuplicateProfile =
  //   selectAccount === "Other" &&
  //   getErrorDuplicatePostingAccount(accountSearch, debitProfile);

  const isToDateMaxDate = getErrorToDateMax(
    debitProfile.filter((d) => {
      return d.action !== "DELETE";
    })
  );

  const status_debit = accountTable.find(
    (i) => i.account_id === debit_account.id
  );

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  React.useEffect(() => {
    if (errorLength) {
      setTimeout(() => {
        setErrorLength(false);
      }, 3000);
    }
  }, [errorLength]);

  React.useEffect(() => {
    if (errorDuplicate) {
      setTimeout(() => {
        setErrorDuplicate(false);
      }, 3000);
    }
  }, [errorDuplicate]);

  React.useEffect(() => {
    if (errorToMax) {
      setTimeout(() => {
        setErrorToMax(false);
      }, 3000);
    }
  }, [errorToMax]);

  React.useEffect(() => {
    setAccountSearch("");
  }, [selectAccount, setAccountSearch]);

  React.useEffect(() => {
    if (trim(accountSearch)?.length > 0) {
      setErrorLength(false);
    }
  }, [accountSearch]);

  const accountUser =
    accountTable?.length > 0
      ? accountTable
          .filter((elem) => {
            return (
              // !debitProfile?.find((row) => {
              //   return (
              //     elem?.account_id === row?.posting_account_id &&
              //     row?.action !== "DELETE"
              //   );
              // })
              //  &&
              elem?.status !== "Closed"
            );
          })
          ?.map((row) => {
            return {
              label: row.account_name + "-" + row.account_id,
              value: row.account_name + "-" + row.account_id,
            };
          })
      : [];

  const options = accountUser?.concat({ label: "Other", value: "Other" });

  const onClickSearchAccountNumber = async (e) => {
    e.stopPropagation();
    try {
      setAccountSearch(String(accountSearch)?.replaceAll(" ", ""));
      if (trim(accountSearch).length === 0) {
        return setErrorLength(true);
      }

      const { data } = await api.instanceRetial.get(
        `${apiConfig.getAccountAPI}/${String(accountSearch)?.replaceAll(
          " ",
          ""
        )}`
      );
      setError(false);
      setErrorLength(false);
      setApiData(data?.data);
    } catch (er) {
      setError(true);
    }
  };

  const onClickSubmitDebitProfile = (e) => {
    if (isToDateMaxDate && type === "NEW_ACCOUNT") {
      setErrorToMax(true);
    }
    // else if (isDuplicateProfile) {
    //   setErrorDuplicate(true);
    // }
    else if (selectAccount === "Other") {
      if (apiData?.account_id && accountSearch?.length > 0 && !error) {
        action(e);
      } else {
        setErrorLength(true);
      }
    } else if (selectAccount) {
      action(e);
    }
  };

  return (
    <Dialog
      keepMounted={false}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ width: "600px", padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          {title}
        </div>
        {error && <ErrorPopUp error={TEXT_FIELD_ERROR_DATA_NOT_FOUND} />}
        {errorDuplicate && <ErrorPopUp error={TEXT_FIELD_ERROR_DUPLICATED} />}
        {errorToMax && <ErrorPopUp error={"Please select previous to date"} />}

        <div className="px-xxxl py-large grid">
          <div
            className={
              selectAccount === "Other"
                ? "grid grid-cols-2 pb-20px gap-4"
                : "grid  pb-20px gap-4"
            }
          >
            <PBFEAutocomplete
              label={title}
              options={options}
              setValue={setSelectAccount}
              auto={false}
              value={selectAccount}
              disableCloseOnSelect={false}
            />
            {selectAccount === "Other" && (
              <PBFEFieldTextInput
                label="Account Number"
                onClick={(e) => onClickSearchAccountNumber(e)}
                value={accountSearch}
                setValue={setAccountSearch}
                error={errorLength}
                helperText={ERROR_MESSAGE_REQUIRED}
              />
            )}

            {/* <PBFEAutocomplete label="Account Number" /> */}
          </div>
          <div className="grid grid-cols-2 pb-20px">
            {selectAccount === "Other" && apiData?.account_name && (
              <PBFEContent
                label="Account Name"
                value={apiData?.account_name || "-"}
              />
            )}
            {status_debit?.status || apiData.account_status ? (
              <PBFEContent
                label="Account Status"
                value={
                  status_debit
                    ? status_debit?.status
                    : apiData
                    ? apiData.account_status
                    : "-"
                }
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={
            (selectAccount === "Other" &&
              apiData?.account_name === undefined) ||
            (selectAccount === "Other" && apiData?.account_name === "") ||
            (selectAccount === "" && selectAccount !== "Other")
          }
          variant="contained"
          onClick={(e) => onClickSubmitDebitProfile(e)}
        >
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PBFESelectFromDateModal = ({
  open = false,
  title = "",
  onClose,
  debitProfile,
  setDebitProfile,
  index = 0,
  previousDate,
}) => {
  const [dateValue, setDateValue] = React.useState();
  const [errorRequired, setErrorRequired] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorFuture, setErrorFuture] = React.useState(false);
  const [errorPastDate, setErrorPastDate] = React.useState(false);
  const [errorCurrent, setErrorCurrent] = React.useState(false);
  const [errorAd, setErrorAd] = React.useState(false);
  const [stringDateValue, setStringDateValue] = React.useState("");
  const [errorLength, setErrorLength] = React.useState(false);

  // console.log("previousDate", previousDate);

  React.useEffect(() => {
    if (stringDateValue) {
      if (stringDateValue?.length === 10) {
        setErrorLength(false);
      } else {
        setErrorLength(true);
      }
    } else {
      setErrorLength(false);
    }
  }, [stringDateValue]);

  React.useEffect(() => {
    setError(false);
    setErrorFuture(false);
    setErrorPastDate(false);
    setErrorCurrent(false);
    setErrorAd(false);
    setErrorRequired(false);
  }, [open]);

  React.useEffect(() => {
    // if (debitProfile?.from_date === null) {
    // setDateValue(NULL_DATE);
    // } else {
    setDateValue(debitProfile?.from_date);
    // }
  }, [debitProfile, open]);

  const onDateChange = (newValue) => {
    let valiedDate = FunctionCheckDateValid(new Date(newValue));

    if (!valiedDate.error) {
      valiedDate.error = validateFormatDate(new Date(newValue));
      valiedDate.message = "Invalid Date Format.";
    }
    const getErrorFutureDate =
      previousDate &&
      debitProfile &&
      previousDate.action !== "DELETE" &&
      checkEffectiveDateFutureDebitProfile(newValue, previousDate?.to_date);

    const getErrorCurrentPassDate = checkFromToDateDebitProfile(
      newValue,
      debitProfile?.to_date
    );

    const getErrorAd = checkDateAD(newValue);

    if (newValue === null || newValue === "") {
      setErrorRequired(true);
      setError(false);
    } else if (valiedDate.error) {
      setError(true);
      setErrorRequired(false);
    } else if (getErrorAd) {
      setErrorAd(true);
      setError(false);
      setErrorRequired(false);
    } else if (getErrorFutureDate?.error) {
      setErrorFuture(true);
      setErrorRequired(false);
    } else if (getErrorCurrentPassDate) {
      setErrorCurrent(true);
      setErrorRequired(false);
      const formatDate =
        newValue?.getFullYear() +
        "-" +
        pad(newValue?.getMonth() + 1) +
        "-" +
        pad(newValue?.getDate());
      setDateValue(formatDate);
    } else {
      // const checkInValidPast = checkInvalidPast(newValue);

      // if (checkInValidPast) {
      //   setErrorPastDate(true);
      // } else {
      //   setErrorPastDate(false);
      // }

      const formatDate =
        newValue?.getFullYear() +
        "-" +
        pad(newValue?.getMonth() + 1) +
        "-" +
        pad(newValue?.getDate());
      setErrorFuture(false);
      setError(false);
      setErrorRequired(false);
      setErrorPastDate(false);
      setErrorCurrent(false);
      setErrorLength(false);
      setErrorAd(false);
      setDateValue(formatDate);
    }
  };

  const onClickConfirm = () => {
    if (error || errorRequired) {
      return;
    } else if (
      errorFuture ||
      errorPastDate ||
      errorCurrent ||
      errorAd ||
      errorLength
    ) {
      return;
    } else {
      setDebitProfile((prev) => {
        const temp = [...prev];

        if (
          temp[index]?.customer_posting_account_id ||
          temp[index]?.customer_offer_posting_account_id
        ) {
          temp[index]["from_date"] = dateValue;
          temp[index].action = "EDIT";
        } else {
          temp[index]["from_date"] = dateValue;
          temp[index].action = "NEW";
        }

        return [...temp];
      });
      onClose();
    }
  };

  const isError =
    errorLength ||
    error ||
    errorFuture ||
    errorPastDate ||
    errorCurrent ||
    errorAd ||
    errorRequired;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      keepMounted={false}
    >
      <DialogContent sx={{ width: "600px", padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          {title}
        </div>
        <div className="px-xxxl py-large grid">
          <div className={" grid  pb-20px gap-4"}>
            <div className="mx-auto">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  value={dateValue}
                  onChange={onDateChange}
                  // disablePast
                  renderInput={(params) => {
                    setStringDateValue(params.inputProps.value);
                    if (params?.inputProps?.placeholder) {
                      params.inputProps.placeholder = "DD/MM/YYYY";
                    }

                    return (
                      <TextField
                        {...params}
                        placeholder="DD/MM/YYYY"
                        InputProps={{
                          ...params.InputProps,
                          style: { color: "#7A58BF", fontSize: 16 },
                          placeholder: "DD/MM/YYYY",
                        }}
                        variant="standard"
                        label={title}
                        error={isError}
                        helperText={
                          errorLength
                            ? TEXT_FIELD_DATE_INVALID
                            : errorCurrent
                            ? TEXT_FIELD_ERROR_DATE
                            : errorFuture
                            ? TEXT_FIELD_ERROR_DATE_FUTURE
                            : error
                            ? TEXT_FIELD_DATE_INVALID
                            : errorPastDate
                            ? TEXT_FIELD_DATE_INVALID_PAST
                            : errorAd
                            ? TEXT_FIELD_DATE_INVALID_AD
                            : errorRequired
                            ? ERROR_MESSAGE_REQUIRED
                            : null
                        }
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isError} onClick={onClickConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PBFESelectToDateModal = ({
  open = false,
  title = "",
  onClose,
  debitProfile,
  setDebitProfile,
  index = 0,
  previousDate,
}) => {
  const [dateValue, setDateValue] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorLess, setErrorLess] = React.useState(false);
  const [errorPastDate, setErrorPastDate] = React.useState(false);
  const [errorAd, setErrorAd] = React.useState(false);
  const [stringDateValue, setStringDateValue] = React.useState("");
  const [errorLength, setErrorLength] = React.useState(false);
  const [errorFuture, setErrorFuture] = React.useState(false);

  React.useEffect(() => {
    if (stringDateValue) {
      if (stringDateValue?.length === 10 || stringDateValue?.length === 0) {
        setErrorLength(false);
      } else {
        setErrorLength(true);
      }
    } else {
      setErrorLength(false);
    }
  }, [stringDateValue]);

  React.useEffect(() => {
    setError(false);
    setErrorLess(false);
    setErrorPastDate(false);
    setErrorAd(false);
    setErrorLength(false);
    setErrorFuture(false);
  }, [open]);

  React.useEffect(() => {
    // if (debitProfile?.to_date === null) {
    // setDateValue(NULL_DATE);
    // } else {
    setDateValue(debitProfile?.to_date);
    // }
  }, [debitProfile, open]);

  const onDateChange = (newValue) => {
    let valiedDate = FunctionCheckDateValid(newValue, "to_date");

    if (!valiedDate.error) {
      valiedDate.error = validateFormatDate(new Date(newValue));
      valiedDate.message = "Invalid Date Format.";
    }

    const valiedDateLess = checkFromToDateDebitProfile(
      debitProfile?.from_date,
      newValue
    );

    const getErrorFutureDate =
      previousDate &&
      debitProfile &&
      previousDate.action !== "DELETE" &&
      checkEffectiveDateFutureDebitProfile(previousDate?.from_date, newValue);

    const getErrorAD = checkDateAD(newValue);

    if (getErrorAD) {
      setErrorAd(true);
      setError(false);
    } else if (valiedDate.error) {
      setError(true);
    } else if (getErrorFutureDate?.error) {
      setErrorFuture(true);
    } else if (valiedDateLess) {
      setErrorLess(true);
      setErrorFuture(false);
      const formatDate =
        newValue?.getFullYear() +
        "-" +
        pad(newValue?.getMonth() + 1) +
        "-" +
        pad(newValue?.getDate());

      if (formatDate === "undefined-NaN-undefined") {
        setDateValue(null);
      } else {
        setDateValue(formatDate);
      }
    } else {
      setErrorFuture(false);
      const checkInValidPast = checkInvalidPast(newValue);

      if (checkInValidPast) {
        setErrorPastDate(true);
      }

      const formatDate =
        newValue?.getFullYear() +
        "-" +
        pad(newValue?.getMonth() + 1) +
        "-" +
        pad(newValue?.getDate());
      setError(false);
      setErrorLength(false);
      setErrorLess(false);
      setErrorPastDate(false);
      setErrorAd(false);
      if (formatDate === "undefined-NaN-undefined") {
        setDateValue(null);
      } else {
        setDateValue(formatDate);
      }
    }
  };

  const onClickConfirm = () => {
    if (error) {
      return;
    } else if (
      errorLess ||
      errorFuture ||
      errorPastDate ||
      errorAd ||
      errorLength
    ) {
      return;
    } else {
      setDebitProfile((prev) => {
        const temp = [...prev];

        if (
          temp[index]?.customer_posting_account_id ||
          temp[index]?.customer_offer_posting_account_id
        ) {
          temp[index]["to_date"] = dateValue;
          temp[index].action = "EDIT";
        } else {
          temp[index]["to_date"] = dateValue;
          temp[index].action = "NEW";
        }

        return [...temp];
      });
      onClose();
    }
  };

  const isError = errorFuture || error || errorLess || errorAd || errorLength;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      keepMounted={false}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ width: "600px", padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          {title}
        </div>
        <div className="px-xxxl py-large grid">
          <div className={" grid  pb-20px gap-4"}>
            <div className="mx-auto">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  value={dateValue === NULL_DATE ? null : dateValue}
                  onChange={onDateChange}
                  // disablePast
                  renderInput={(params) => {
                    setStringDateValue(params.inputProps.value);
                    if (params?.inputProps?.placeholder) {
                      params.inputProps.placeholder = "DD/MM/YYYY";
                    }

                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { color: "#7A58BF", fontSize: 16 },
                        }}
                        variant="standard"
                        label={title}
                        error={isError}
                        helperText={
                          errorLess
                            ? TEXT_FIELD_ERROR_DATE_FROM_TO
                            : errorFuture
                            ? TEXT_FIELD_ERROR_DATE_NEXT_STEP
                            : error
                            ? TEXT_FIELD_DATE_INVALID
                            : errorPastDate
                            ? TEXT_FIELD_DATE_INVALID_PAST
                            : errorAd
                            ? TEXT_FIELD_DATE_INVALID_AD
                            : errorLength
                            ? TEXT_FIELD_DATE_INVALID
                            : null
                        }
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isError} variant="contained" onClick={onClickConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
