import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";
import { ROW_PER_PAGE } from "../../constants/constant";
import { getComparator, stableSort } from "../../functions";
import {
  TABLE_STYLE_WITH_HOVER_STANDARD_RADIO_WITDH,
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import NoRecordFound from "../Error/noRecordFound";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export const PBFECommonTableRadio = ({
  headCells,
  rows,
  showFooter = false,
  selectable = false,
  onClickRow,
  page = 0,
  setPage,
  orderByDefault = "",
  hasUtilIndex = false,
  showFields = [],
  searched,
  disabledPagination,
  option_field_value,
  option_field_label,
  radioValue,
  setRadio,
  open,
}) => {
  const [order, setOrder] = React.useState();
  // set default order by here
  const [orderBy, setOrderBy] = React.useState();
  const [sortBy, setSortBy] = React.useState(true);

  React.useEffect(() => {
    setOrderBy(orderByDefault);
    setOrder("asc");
  }, [orderByDefault, open]);

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow hover={false}>
          {headCells.map((headCell) => {
            return (
              <TableCell
                // rowSpan={2}
                // colSpan={}
                key={headCell.id}
                align={"left"}
                padding={"normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  // aria-rowspan={2}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const DataArrayTable = stableSort(
    rows,
    getComparator(order, orderBy),
    orderBy,
    sortBy
  );

  const convertShowFieldsObject = (row) => {
    let temp = [];
    if (showFields.length === 0) {
      /// Non Show Field case
      Object.keys(row).map((key) => temp.push(row[key]));
    } else {
      for (let i = 0; i < showFields.length; i++) {
        const key = showFields[i];
        if (row[key]) {
          temp.push(row[key]);
        }
      }
    }
    return temp;
  };

  return (
    <div>
      <TableContainer sx={{ height: "420px" }}>
        {searched?.length >= 3 && rows?.length <= 0 ? (
          <NoRecordFound />
        ) : (
          <RadioGroup
            value={radioValue}
            name="radio-buttons-group"
            onChange={(e) => setRadio(e.target.value)}
          >
            <Table
              // sx={TABLE_STYLE_WITH_HOVER_STANDARD_RADIO_WITDH}
              stickyHeader={true}
              sx={{ width: "100%" }}
              aria-label="simple table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                rowCount={rows?.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {DataArrayTable?.map((row, index) => {
                  return (
                    <TableRow
                      id="table-row"
                      key={index}
                      hover={selectable}
                      onClick={() => onClickRow && onClickRow(row)}
                      sx={TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER}
                    >
                      {convertShowFieldsObject(row).map((item, jedex) => {
                        return (
                          <TableCell
                            id="table-cell"
                            align="left"
                            key={jedex}
                            sx={
                              index === 0 && hasUtilIndex
                                ? { width: "35px" }
                                : {}
                            }
                          >
                            {jedex === 0 ? (
                              <FormControlLabel
                                sx={{ width: "100%", p: 0 }}
                                key={jedex}
                                value={row[option_field_value]}
                                control={<Radio />}
                                label={row[option_field_label]}
                              />
                            ) : (
                              item
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </RadioGroup>
        )}
      </TableContainer>
      {!disabledPagination && (
        <React.Fragment>
          {rows?.length > 0 && showFooter && (
            <div className="border-t">
              <TablePagination
                component="div"
                count={rows?.length}
                rowsPerPage={ROW_PER_PAGE}
                page={page}
                sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
