import {
  MINIMUM_SEARCH_ACTIVE,
  NULL_DATE,
  PRICING_FREQUENCY,
  PRICING_METHOD,
  TEXT_FIELD_DATE_INVALID_AD,
  TRANSACTION_CONSOLIDATION,
  VAT_TYPE,
  WHT_TYPE,
  RATE_TYPE,
} from "../constants/constant";
import { validateError } from "../utils/validationError";
import { differenceInDays } from "date-fns";

/// Sort
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator, name, sortBy) {
  if (name?.length > 0) {
    return array?.sort((a, b) => {
      if (sortBy) {
        return a[name]?.toString().localeCompare(b[name]);
      } else {
        return b[name]?.toString().localeCompare(a[name]);
      }
    });
  } else {
    return array;
  }
}

export const onClickScroll = (input) => {
  const yOffset = -130;
  const element = document.getElementById(input);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: "smooth" });
};

export const currentTime = () => {
  return new Date().toLocaleString("th-TH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatConstantToOptions = (input_object) => {
  const propertyNames = Object.keys(input_object);
  const propertyValues = Object.values(input_object);

  const object = propertyNames.map((r, i) => {
    return { value: propertyValues[i], id: r };
  });
  return object;
};

export const renderDropdownValue = (keyName, value = "") => {
  if (keyName === "pricing_method") {
    return PRICING_METHOD[value];
  } else if (keyName === "pricing_frequency") {
    return PRICING_FREQUENCY[value];
  } else if (keyName === "transaction_consolidation") {
    return TRANSACTION_CONSOLIDATION[value];
  } else if (keyName === "vat_type") {
    return VAT_TYPE[value];
  } else if (keyName === "wht_type") {
    return WHT_TYPE[value];
  } else {
    return value;
  }
};
export const renderDropdownValue2 = (keyName, value) => {
  if (!value && value !== "0" && value !== 0) return "-";
  if (keyName === "to" && value === 999999999.99) {
    return "MAX";
  } else if (value === 0) {
    return 0;
  } else if (keyName === "from" && value === 0) {
    return "MIN";
  } else if (keyName === "pricing_method") {
    return PRICING_METHOD[value];
  } else if (keyName === "pricing_frequency") {
    return PRICING_FREQUENCY[value];
  } else if (keyName === "transaction_consolidation") {
    return TRANSACTION_CONSOLIDATION[value];
  } else if (keyName === "vat_type") {
    return VAT_TYPE[value];
  } else if (keyName === "wht_type") {
    return WHT_TYPE[value];
  } else {
    return value;
  }
};
export const getNameFromDebitProfileSeleted = (input) => {
  if (!input) return "";
  try {
    const splitInput = input.split("-");
    const arrayName = splitInput?.slice(0, splitInput.length - 1);
    const arrayId = splitInput?.slice(-1);
    const res_name = String(arrayName)?.replaceAll(",", " ");
    const res_id = String(arrayId);
    return { name: res_name, id: res_id };
  } catch (error) {
    return "";
  }
};

export const checkNumber = (input) => {
  if (typeof input === "number") {
    return "";
  } else {
    return "This field must be number.";
  }
};

export const TEXT_FIELD_REQUIRED = "This field is required.";
export const TEXT_FIELD_ERROR_MINUS = "Value cannot be less than 0.";
export const TEXT_FIELD_ERROR_DATE =
  "Effective From  Must Be Before Effective To.";
export const TEXT_FIELD_DATE_INVALID = "Invalid Date Format.";
export const TEXT_FIELD_ERROR_DATE_FROM_TO =
  "Effective To Cannot Be Before Effective From.";

export const TEXT_FIELD_ERROR_DATE_NEXT_STEP =
  "Effective to date must be before effective from next step.";

export const TEXT_FIELD_ERROR_DATE_FUTURE =
  "Effective From Future Must Be After Effective To Date From Last Step.";

export const TEXT_FIELD_ERROR_DUPLICATED = "Data cannot be duplicated.";

export const TEXT_FIELD_ERROR_DATA_NOT_FOUND = "Data not found.";
export const TESXT_FIELD_ERROR_NAME_ALREADY_EXIST = "Name already exist.";

export const TEXT_FIELD_DATE_LESS_THAN =
  "From date cannot be less than previous To date.";

export const TEXT_FIELD_ERROR_DECIMAL =
  "Value decimal must not be more than 2 digits.";

export const TEXT_FIELD_EQUAL = "From date cannot be equal to To date.";

export const checkRequired = (input) => {
  if (input === 0) {
    return "";
  } else if (input === "") {
    return TEXT_FIELD_REQUIRED;
  } else if (input === null) {
    return TEXT_FIELD_REQUIRED;
  } else if (input === undefined) {
    return TEXT_FIELD_REQUIRED;
  } else if (input) {
    return "";
  } else {
    return TEXT_FIELD_REQUIRED;
  }
};

export const checkValueNotMinus = (value) => {
  const real_value = Number(value) >= 0 ? Number(value) : value;
  if (value === `-0`) {
    return TEXT_FIELD_ERROR_MINUS;
  } else if (isNaN(real_value)) {
    return "";
  } else if (real_value >= 0) {
    return "";
  } else {
    return TEXT_FIELD_ERROR_MINUS;
  }
};

export const checkWordSync = (word, value) => {
  const react_value = Number(value) >= 0 ? 0 : value;
  if (typeof react_value === "string") {
    const keyword = word.slice(5, word.length).split(",") || [];
    let temp_value = 0;
    keyword.forEach((it) => {
      if (it === value) {
        temp_value++;
      } else {
        return "";
      }
    });
    if (temp_value > 0) {
      return "";
    } else {
      return String(word.slice(5, word.length));
    }
  } else {
    return "";
  }
};

export const checkMaxValue = (value, max) => {
  const real_value = Number(value) >= 0 ? Number(value) : value;

  if (real_value > max) {
    return "Value cannot be greater than " + max;
  } else {
    return "";
  }
};

export const checkFormToValue = (form_value, to_prev_value) => {
  const real_form_value =
    Number(form_value) >= 0
      ? Number(form_value)
      : form_value === "MIN"
      ? 0
      : form_value;
  const real_to_prev_value =
    Number(to_prev_value) >= 0
      ? Number(to_prev_value)
      : to_prev_value === "MAX"
      ? 999999999.99
      : to_prev_value;

  if (real_form_value >= 0 && real_to_prev_value >= 0) {
    if (real_form_value <= real_to_prev_value) {
      // return to_prev_value + " value cannot be greater than " + real_form_value;
      return "from value should be higher than to value from last step";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const checkSyncFieldRequired = (
  value,
  sync_name,
  sync_data,
  field_name_required
) => {
  if (sync_name === field_name_required) {
    if (!value && sync_data !== "-" && value !== 0) {
      //rate 0 บา่ท what ?
      return TEXT_FIELD_REQUIRED;
    } else if (value === "" && sync_data !== "-") {
      return TEXT_FIELD_REQUIRED;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const mappingKeyForRender = {
  max: "Max Charge",
  min: "Min Charge",
  min_tier: "Tier Min",
  max_tier: "Tier Max",
  from: "From",
  to: "To",
};

export const checkCompareValue = (front, back, sync_name) => {
  const front_real_value =
    Number(front) >= 0 ? Number(front) : front === "MIN" ? 0 : front;
  const back_real_value =
    Number(back) >= 0 ? Number(back) : back === "MAX" ? 999999999.99 : back;

  if (front_real_value && back === "" && sync_name === "max") {
    return "";
  } else if (front_real_value && back === "" && sync_name === "max_tier") {
    return "";
  } else if (back_real_value < front_real_value) {
    return "Value cannot be greater than " + mappingKeyForRender[sync_name];
  } else {
    return "";
  }
};

export const FunctionCheckDecimal = (input, length_decimal = 2) => {
  const number_value = Number(input);
  const decimal_length = number_value?.toString()?.split(".")[1]?.length;

  if (input === "MIN") {
    return "";
  } else if (input === "MAX") {
    return "";
  } else if (Math.floor(number_value?.valueOf()) === number_value?.valueOf()) {
    return "";
  } else if (decimal_length > length_decimal) {
    return TEXT_FIELD_ERROR_DECIMAL;
  } else {
    return "";
  }
};

export const getValidateError = (
  name,
  value,
  tier = "",
  sync_name,
  sync_data
) => {
  if (name) {
    const objKeys = Object.keys(validateError);
    const objValues = Object.values(validateError);

    const findIndexOfName = objKeys.findIndex((r) => r === name);

    let temp = [];

    if (findIndexOfName !== -1) {
      objValues[findIndexOfName]?.forEach((i) => {
        if (i === "required") {
          temp?.push(checkRequired(value));
        } else if (i === "minus") {
          temp?.push(checkValueNotMinus(value));
        } else if (i.slice(0, 4) === "word" && typeof value === "string") {
          const index_of_word = objValues[findIndexOfName].findIndex(
            (v) => v.slice(0, 4) === "word"
          );
          temp?.push(
            checkWordSync(objValues[findIndexOfName][index_of_word], value)
          );
        } else if (i.slice(0, 3) === "max") {
          const max_value = Number(i.slice(4, i.length));
          temp?.push(checkMaxValue(value, max_value));
        } else if (i.slice(0, 4) === "sync" && sync_name && sync_data) {
          const field_name_required = i.slice(5, i.length);
          temp?.push(
            checkSyncFieldRequired(
              value,
              sync_name,
              sync_data,
              field_name_required
            )
          );
        } else if (i.slice(0, 10) === "lower_than") {
          temp?.push(checkCompareValue(value, sync_data, sync_name));
        } else if (i.slice(0, 7) === "decimal") {
          const length_decimal = i.slice(8, i.length);
          temp?.push(FunctionCheckDecimal(value, length_decimal));
        } else {
          return "";
        }
      });

      const error_message = temp?.filter((er) => er !== "");

      if (error_message[0] === TEXT_FIELD_REQUIRED) {
        return {
          error: true,
          message: TEXT_FIELD_REQUIRED,
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else if (error_message.length === 1) {
        return {
          error: true,
          message: String(error_message),
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else if (error_message.length > 1) {
        return {
          error: true,
          message: String(error_message).replaceAll(",", " "),
          field_name: name,
          field_value: value,
          tier: tier,
        };
      } else {
        return {
          error: false,
          message: "",
          field_name: name,
          field_value: value,
          tier: tier,
        };
      }
    }
  } else {
    return { error: false, message: "" };
  }
};

export const replaceToUpperCase = (str) => {
  const split_string = str.split(" ");
  const every = split_string.map((row) => {
    const value = row.toUpperCase();
    if (value?.slice(0, 4) === "PBFE") {
      return "PBFE";
    } else {
      return String(row.slice(0, 1)).toUpperCase() + row.slice(1, row.length);
    }
  });
  return String(every).replaceAll(",", " ");
};

export const FormatNumber = (number) => {
  if (number >= 0) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export const checkFromToDateDebitProfile = (from, to) => {
  if (from && to) {
    const toDate = new Date(to);
    const fromDate = new Date(from);

    if (toDate.getTime() < fromDate.getTime()) {
      return "Effective To Cannot Be Before Effective From";
    } else {
      return;
    }
  } else {
    return;
  }
};

export const checkDateAD = (date) => {
  if (date) {
    const newDate = new Date(date);
    let sliceYear = `${newDate.getFullYear()}`.slice(0, 4);
    if (sliceYear > 2500) {
      return TEXT_FIELD_DATE_INVALID_AD;
    }
  } else {
    return "";
  }
};

export const checkFromToDate = (
  from,
  to,
  prev,
  keyName,
  cannotSame = false,
  message = ""
) => {
  if (cannotSame && from !== "" && from === to) {
    console.log("in", {
      from,
      to,
      prev,
      keyName,
      cannotSame,
      message,
    });
    return message ? message : TEXT_FIELD_EQUAL;
  }
  if (prev && from && keyName === "from_date") {
    const prev_date = new Date(prev);

    if (prev_date.getTime() >= new Date(from).getTime()) {
      return TEXT_FIELD_DATE_LESS_THAN;
    }
  }

  if (from && keyName === "from_date") {
    const from_date = new Date(from);
    let sliceYear = `${from_date.getFullYear()}`.slice(0, 4);
    if (sliceYear > 2500) {
      return TEXT_FIELD_DATE_INVALID_AD;
    }
  }

  if (
    to &&
    keyName === "to_date" &&
    to !== "9999-12-31" &&
    to !== "" &&
    to !== null &&
    to !== "9999-12-31T00:00:00"
  ) {
    const to_date = new Date(to);
    let sliceYear = `${to_date.getFullYear()}`.slice(0, 4);
    if (sliceYear > 2500) {
      return TEXT_FIELD_DATE_INVALID_AD;
    }
  }

  if (from && to) {
    const from_date = new Date(from);
    const to_date = new Date(to);

    // const diffday = differenceInDays(from_date, prev_date);

    // if ((diffday <= 0 || diffday > 1) && keyName === "from_date") {
    //   return "Date Invalid";
    // }

    // if (to_date === from_date) {
    //   return "Data Invalid";
    // }
    // if (isNaN(to_date?.getTime())) {
    //   return TEXT_FIELD_ERROR_DATE;
    // } else

    // console.log("prev_date", prev_date);

    if (from_date?.getTime() > to_date?.getTime()) {
      if (keyName === "to_date") {
        return TEXT_FIELD_ERROR_DATE_FROM_TO;
      }
    }
    // else if (prev_date > from_date) {
    //   return "Futrue date is not correct";
    // }
    else {
      return "";
    }
  } else {
    return "";
  }
};

export const checkDateInvalid = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date?.getTime())) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const FunctionCheckDateValid = (input, name = "") => {
  console.log("input", input);
  if (input === null && name === "to_date") {
    return { error: false, message: "" };
  } else if (Object.prototype.toString.call(input) === "[object Date]") {
    // it is a date
    if (isNaN(input?.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return { message: "Invalid date format", error: true };
    } else {
      // date is valid
      return { error: false, message: "" };
    }
  } else {
    return { message: "Invalid date format", error: true };
    // not a date
  }
};

export const FunctionCheckDateLength = (input, name = "") => {
  if (input) {
    if (input?.length === 0 && name === "to_date") {
      return { error: false, message: "" };
    } else if (input?.length === 10) {
      return { error: false, message: "" };
    } else {
      return { message: "Invalid date format", error: true };
    }
  }

  return { error: false, message: "" };
};

export const FunctionCheckDateValidCustom = (input, name = "") => {
  // if (input.length === 19) {
  if (
    (input === null ||
      input === "" ||
      input === "9999-12-31T00:00:00" ||
      input === "9999-12-31") &&
    name === "to_date"
  ) {
    return { error: false, message: "" };
  }

  if (
    name === "from_date" &&
    (input === "" ||
      input === null ||
      input === "undefined-NaN-undefinedT00:00:00")
  ) {
    return { message: "This fied is required", error: true };
  }

  if (Object.prototype.toString.call(new Date(input)) === "[object Date]") {
    if (isNaN(new Date(input)?.getTime())) {
      return { message: "Invalid date format", error: true };
    } else {
      return { error: false, message: "" };
    }
  } else {
    return { message: "Invalid date format", error: true };
  }
};

export const FunctionCheckDateIsNaN = (input) => {
  const errorMsg = "Invalid date format";
  if (input === "NaN-NaN-NaN") {
    return { message: errorMsg, error: true };
  } else if (input === "undefined-NaN-undefined") {
    return { message: "Invalid date format", error: true };
  } else if (input === "Invalid Date") {
    return { message: errorMsg, error: true };
  } else if (input === null) {
    return { message: errorMsg, error: true };
  } else if (input === "undefined-NaN-undefinedT00:00:00") {
    return { message: errorMsg, error: true };
  } else {
    return { error: false, message: "" };
  }
};

export const FormatRateType = (input) => {
  if (input === undefined) {
    return "-";
  } else if (input === "THB") {
    return RATE_TYPE.THB;
  } else if (input === "PERCENT" || input === "%") {
    return RATE_TYPE.PERCENT;
  } else if (input === "POINT" || input === "Point") {
    return RATE_TYPE.POINT;
  } else {
    return "-";
  }
};

export const checkEffectiveDateFutureDebitProfile = (
  from_current,
  to_previous
) => {
  const from_date_current = new Date(from_current);
  const to_date_privious =
    to_previous === null ? new Date(NULL_DATE) : new Date(to_previous);

  if (from_date_current.getTime() <= to_date_privious.getTime()) {
    return { error: true, message: TEXT_FIELD_ERROR_DATE_FUTURE };
  } else {
    return { error: false, message: "" };
  }
};

export const getErrorDuplicatePostingAccount = (
  search = "",
  debitProfileCurrent = []
) => {
  const findDuplicate = debitProfileCurrent.find((row_account) => {
    return (
      row_account.posting_account_id.slice(
        row_account.posting_account_id.length - 10,
        row_account.posting_account_id.length
      ) === search && row_account.action !== "DELETE"
    );
  });

  return findDuplicate ? true : false;
};

export const HookPostingAccount = (input) => {
  if (input > 10000) {
    return input?.slice(input.length - 10, input?.length);
  } else {
    return input;
  }
};

export const checkValidateNameOfUserGroupIsDupicate = (
  arrayData,
  name,
  defualtName
) => {
  if (String(name).trim().length === 0) {
    return { error: true, message: TEXT_FIELD_REQUIRED };
  } else if (name === defualtName) {
    return { message: "", error: false };
  } else if (arrayData?.length > 0 && name?.length > 0) {
    const findDuplicate = arrayData?.find((row) => {
      return row?.user_group_name === name;
    });
    if (findDuplicate?.user_group_name) {
      return { message: TESXT_FIELD_ERROR_NAME_ALREADY_EXIST, error: true };
    } else {
      return { message: "", error: false };
    }
  } else {
    return { message: "", error: false };
  }
};
