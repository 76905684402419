import React, { useEffect, useState } from "react";
import offersService from "../../api/offersService";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import PBFEError from "../../components/Error/alertError";
import { PBFECommonTable } from "../../components/Table/commonTable";
import useQuery from "../../hooks/useQuery";
import {
  MINIMUM_SEARCH_ACTIVE,
  OFFER_TABLE_HEADER,
  OFFER_TITLE,
} from "../../constants/constant";
import { useNavigate } from "react-router-dom";
import { formateDate } from "../../utils/util";

function Offer({ setRouteName, setActionAppbar }) {
  const {
    data: rows,
    setData: setRows,
    loading,
    originalData,
    error,
  } = useQuery(offersService.getOffers);
  const [searched, setSearched] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setRouteName([{ title: OFFER_TITLE, path: "/offer" }]);
    setActionAppbar("");
  }, [setRouteName]);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value);
    setPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalData);
      return;
    }
    if (originalData.length === 0) return;
    const filteredRows = originalData.filter((row) => {
      return (
        row.offer_name
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase()) ||
        row.offer_code
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full">
      <div className="flex justify-end w-full mt-50px pt-md">
        <PBFESearchbar
          placeholder={"Search Offer Name / Code"}
          onChange={requestSearch}
          value={searched}
        />
      </div>
      <PBFECommonTable
        sort_name_by="offer_name"
        searched={searched}
        page={page}
        setPage={setPage}
        headCells={OFFER_TABLE_HEADER}
        rows={rows}
        showFooter={true}
        selectable={true}
        orderByDefault="offer_name"
        onClickRow={(row) => {
          navigate(`/offer/${row.offer_code}`);
        }}
        formatControl={[null, null, formateDate, formateDate]}
        showFields={[
          "offer_name",
          "offer_code",
          "effective_from",
          "effective_to",
        ]}
      />
    </div>
  );
}
export default Offer;
