import makerCheckerService from "../../api/makerCheckerService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFEError from "../../components/Error/alertError";
import { PBFEAccordionMain } from "../../components/Panel/Accordion/accordionMain";
import { PBFEContent } from "../../components/Elements/Text/content";
import { RetailDetail } from "../../components/Panel/Information/retailDetail";
import {
  formateDate,
  formateDateWithTimeHourMintues,
  formatEmpty,
} from "../../utils/util";
import React, { useEffect, useState } from "react";
import { ServiceDetail } from "../../components/Panel/Information/servicesDetail";
import { PBFEHeader } from "../../components/Elements/Text/header";
import {
  NULL_DATE,
  PRICING_FREQUENCY,
  PRICING_METHOD,
  PRICING_WITHOUT_DEBIT,
  TRANSACTION_CONSOLIDATION,
  VAT_TYPE,
  WHT_TYPE,
} from "../../constants/constant";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  TABLE_STYLE_STANDARD_WITDH,
  TABLE_STYLE_WITHOUT_BOTTOM_BORDER,
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import _ from "lodash";
import { PBFEDatePicker } from "../../components/Input/datePicker";

import { HookPostingAccount } from "../../functions";
import UploadMakerChecker from "../Upload/uploadMakerChecker";

export const PBFETitleBefore = ({ type = "", charge }) => {
  return (
    <div className="flex justify-between ">
      <div
        className={`grid w-button text-white px-3 py-2  mb-3  bg-gray-400 my-4 `}
      >
        BEFORE
      </div>
      {type === "update" && charge?.price_without_post === "Y" && (
        <div className="mr-15px text-textprimary text-value">
          {PRICING_WITHOUT_DEBIT}
        </div>
      )}
    </div>
  );
};

export const PBFETitleAfter = ({ type = "", charge }) => {
  return (
    <div className="flex justify-between ">
      <div
        className={`grid w-button text-white px-3 py-2  mb-3 bg-purple-600 my-4 `}
      >
        AFTER
      </div>
      {type === "update" && charge?.price_without_post === "Y" && (
        <div className="mr-15px text-textprimary text-value">
          {PRICING_WITHOUT_DEBIT}
        </div>
      )}
    </div>
  );
};

export const PBFESectionReason = ({ data }) => {
  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      <PBFEContent label={`${data.status} Reason`} value={data.reason} />
    </div>
  );
};

export const PBFEGeneralInfoChecker = ({ data }) => {
  const isPending = data?.status === "Pending";
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      <PBFEContent
        label="Request ID"
        value={formatEmpty(data?.transaction_reference)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="Request Type"
          value={formatEmpty(data.description)}
        />
      </div>
      <PBFEContent label="Status" value={formatEmpty(data.status)} />
      <PBFEContent label="Maker" value={formatEmpty(data?.maker)} />
      <PBFEContent
        label="Request Date Time"
        value={formateDateWithTimeHourMintues(data.create_datetime)}
      />
      {!isPending && (
        <PBFEContent label="Checker" value={formatEmpty(data?.approval)} />
      )}
      {!isPending && (
        <PBFEContent
          label={`${data.status} Date Time`}
          value={` ${formatEmpty(
            formateDateWithTimeHourMintues(data.approval_datetime)
          )}`}
        />
      )}
    </div>
  );
};

export const PBFEGeneralInfoCustomerAccount = ({ data }) => {
  return (
    <div className="grid grid-cols-2 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      {data?.data?.display_obj?.account_name && (
        <React.Fragment>
          <PBFEContent
            label="Account Name"
            value={formatEmpty(data?.data?.display_obj?.account_name)}
          />
          <PBFEContent
            label="Account No."
            value={formatEmpty(data?.data?.display_obj?.account_id)}
          />
        </React.Fragment>
      )}
      {/* {data?.data.display_obj?.offer_code && (
        <React.Fragment>
          <PBFEContent
            label="Offer Name"
            value={formatEmpty(data?.data.display_obj?.offer_name)}
          />
          <PBFEContent
            label="Offer No."
            value={formatEmpty(data?.data.display_obj?.offer_code)}
          />
        </React.Fragment>
      )} */}
      {data?.data?.display_obj?.customer_id && (
        <React.Fragment>
          <PBFEContent
            label="Customer Name"
            value={formatEmpty(data?.data?.display_obj?.customer_name)}
          />
          <PBFEContent
            label="Customer ID"
            value={formatEmpty(data?.data?.display_obj?.customer_id)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export const PFFERenderUpdateDebitProfile = ({
  before_debit = [],
  after_debit = [],
}) => {
  const filter_delete_after = after_debit?.filter((f) => {
    return f?.action !== "DELETE";
  });

  if (filter_delete_after?.length > 0 && before_debit?.length === 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            {/* <div className="">
              <PBFETitleBefore />
            </div>
            <div className="">
              <PBFETitleAfter />
            </div> */}
            <PBFEDebitProfileTable rows={filter_delete_after} />
          </React.Fragment>
        }
      />
    );
  } else if (filter_delete_after?.length > 0 && before_debit?.length > 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            <div className="">
              <PBFETitleBefore />
            </div>
            <PBFEDebitProfileTable rows={before_debit} />
            <div className="">
              <PBFETitleAfter />
            </div>
            <PBFEDebitProfileTable rows={after_debit} />
          </React.Fragment>
        }
      />
    );
  } else if (before_debit?.length > 0 && filter_delete_after?.length === 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            <div className="">
              <PBFETitleBefore />
            </div>
            <PBFEDebitProfileTable rows={before_debit} />
            <div>
              <PBFETitleAfter />
            </div>
          </React.Fragment>
        }
      />
    );
  } else {
    return "";
  }
};

export const PBFERenderHeaderChargeFromService = ({ charge }) => {
  const isSubscription = charge?.fee_charge?.pricing_method === "SUBSCRIPTION";

  return (
    <React.Fragment>
      <div className="flex justify-between">
        {/* <div
          className={`  w-button  text-white px-3 py-2 inline-block mb-3 ${
            charge.type === "before" ? "bg-gray-400" : "bg-purple-600"
          }`}
        >
          {_.upperCase(charge.type)}
        </div> */}
        {charge?.fee_charge?.price_without_post === "Y" && (
          <div className="mr-15px text-textprimary text-value ml-auto">
            {PRICING_WITHOUT_DEBIT}
          </div>
        )}
      </div>
      <div className="grid grid-cols-5 gap-40px mb-20px">
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label="Pricing Method"
          value={PRICING_METHOD[charge?.fee_charge?.pricing_method]}
        />
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Pricing Frequency`}
          value={PRICING_FREQUENCY[charge.fee_charge?.pricing_frequency]}
        />
        {!isSubscription ? (
          <PBFEContent
            w=" w-20 "
            mode="flex w-full"
            label={`Transaction Consolidation`}
            value={
              TRANSACTION_CONSOLIDATION[
                charge?.fee_charge?.transaction_consolidation
              ]
            }
          />
        ) : (
          <div></div>
        )}
        {/* {!isSubscription ? ( */}
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`VAT`}
          value={VAT_TYPE[charge?.fee_charge?.vat_type]}
        />
        {/* ) : (
          <div></div>
        )} */}
        {!isSubscription ? (
          <PBFEContent
            w=" w-20 "
            mode="flex w-full"
            label={`WHT`}
            value={WHT_TYPE[charge?.fee_charge?.wht_type]}
          />
        ) : (
          <div></div>
        )}
      </div>
    </React.Fragment>
  );
};

export const PBFEDebitProfileTable = ({ rows }) => {
  return (
    <React.Fragment>
      <div className="border-t border-b">
        <TableContainer>
          <Table sx={TABLE_STYLE_STANDARD_WITDH} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align={"left"} padding={"normal"}>
                  Acount No.
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  Acount Name
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  Account Status
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  From
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  To
                </TableCell>
              </TableRow>
            </TableHead>
            {rows?.map((row, index) => {
              return (
                <TableBody key={`table${index}`}>
                  <TableRow
                    id="table-row"
                    key={index}
                    sx={
                      (TABLE_STYLE_WITHOUT_BOTTOM_BORDER,
                      { backgroundColor: "#00000014" })
                    }
                  >
                    <TableCell id="table-cell-id" align="left">
                      {HookPostingAccount(`${row.posting_account_id}`)}
                    </TableCell>
                    <TableCell id="table-cell-name" align="left">
                      {row.posting_account_name}
                    </TableCell>
                    <TableCell id="table-cell-status" align="left">
                      {row.status}
                    </TableCell>
                    <TableCell id="table-cell-from" align="left">
                      {row.from_date ? formateDate(row.from_date) : "-"}
                    </TableCell>
                    <TableCell id="table-cell-to" align="left">
                      {row?.to_date === NULL_DATE
                        ? "-"
                        : formateDate(row.to_date)}
                    </TableCell>
                    <TableCell
                      sx={{ width: "25px" }}
                      id="table-cell-to"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export const PBFERenderNewOffer = ({ data }) => {
  const [dataOffer, setDataOffer] = useState([]);

  useEffect(() => {
    if (data) {
      setDataOffer(data?.data?.display_obj?.raw_data);
    }
  }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <div className="grid grid-cols-5 gap-40px my-25px">
                  <PBFEDatePicker
                    keyName="from_date"
                    data={data?.data?.display_obj}
                    label={"Effective From"}
                    isEdit={false}
                  />
                  <PBFEDatePicker
                    keyName="to_date"
                    data={data?.data?.display_obj}
                    label="To"
                    isEdit={false}
                  />
                </div>
                <PFFERenderUpdateDebitProfile
                  after_debit={data?.data?.display_obj?.posting_account}
                />
                <RetailDetail
                  data={{
                    ...dataOffer,
                    enrollment_conditions:
                      data?.data?.display_obj?.enrollment_conditions,
                  }}
                />
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderEditFromToOffer = ({ before_date, after_date }) => {
  if (before_date?.to_date && after_date?.to_date) {
    return (
      <div>
        <PBFETitleBefore />
        <div className="grid grid-cols-5 gap-40px my-25px">
          <PBFEDatePicker
            keyName="from_date"
            data={before_date}
            label={"Effective From"}
            isEdit={false}
          />
          <PBFEDatePicker
            keyName="to_date"
            data={before_date}
            label="To"
            isEdit={false}
          />
        </div>
        <PBFETitleAfter />
        <div className="grid grid-cols-5 gap-40px my-25px">
          <PBFEDatePicker
            keyName="from_date"
            data={after_date}
            label={"Effective From"}
            isEdit={false}
          />
          <PBFEDatePicker
            keyName="to_date"
            data={after_date}
            label="To"
            isEdit={false}
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const PBFERenderUpdateOffer = ({ data }) => {
  const [beforeDebit, setBeforeDebit] = useState([]);
  const [afterDebit, setAfterDebit] = useState([]);

  useEffect(() => {
    const display_obj = data?.data?.display_obj;
    setAfterDebit(display_obj.after_debit);
    setBeforeDebit(display_obj?.before_debit);
  }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <PBFERenderEditFromToOffer
                  before_date={data?.data?.display_obj?.before_date}
                  after_date={data?.data?.display_obj?.after_date}
                />
                <PFFERenderUpdateDebitProfile
                  after_debit={afterDebit}
                  before_debit={beforeDebit}
                />
                <RetailDetail
                  data={{
                    ...data?.data?.display_obj?.raw_data,
                    enrollment_conditions:
                      data?.data?.display_obj?.enrollment_conditions,
                  }}
                />
                {/* {data?.data?.display_obj?.raw_data?.service?.map(
                  (info, index) => {
                    return (
                      <PBFEAccordionMain
                        pl="ml-20px"
                        customHeader={
                          <PBFEHeader
                            p=""
                            label={
                              info.service_name + " - " + info.service_code
                            }
                            purple={true}
                            editable={false}
                            hideButton={true}
                          />
                        }
                        detail={
                          <ServiceDetail
                            key={`${info.service_code}-${index}`}
                            isOffer={true}
                            primaryIndex={index}
                            primaryField="fee_charge"
                            origin={info}
                            data={info.fee_charge}
                            isEdit={false}
                          />
                        }
                      />
                    );
                  }
                )} */}
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFEApprovalDetail = ({
  setRouteName,
  setDataRequest,
  setActionAppbar,
  serviceType,
  type = "",
}) => {
  const params = useParams();
  const location = useLocation();
  const { data, loading, error } = useQuery(
    makerCheckerService.getMakerCheckerInfoById,
    {
      request_id: params.request_id,
    },
    {},
    [],
    { checkFirstLoad: true }
  );

  useEffect(() => {
    if (serviceType === "history") {
      setActionAppbar("HISTORY");

      let title = "";
      let pathName = "";
      let id = "";

      if (type === "customer") {
        title = "CUSTOMER";
        pathName = "/customer";
        id = params.customer_id;
      } else {
        title = "ACCOUNT";
        pathName = "/account";
        id = params.account_id;
      }

      setRouteName([
        {
          title: title,
          path: `${pathName}`,
        },
        {
          title: id,
          path: `${pathName}/${id}`,
        },
        {
          title: `HISTORY: ${data?.transaction_reference}`,
        },
      ]);
    } else {
      let splitPath = location.pathname.split("/");
      splitPath = splitPath.filter((path) => path);

      let newPath = "";
      let newTitle = "";

      if (splitPath.length === 3) {
        newPath = `/${splitPath[0]}/${splitPath[1]}`;
      } else if (splitPath.length === 2) {
        newPath = `/${splitPath[0]}`;
      }

      if (
        ["/waitingforapprove/allrequest", "/myrequest/allrequest"].includes(
          newPath
        )
      ) {
        newTitle = "ALL REQUEST";
      } else if (newPath === "/myrequest") {
        newTitle = "MY REQUEST";
      } else if (newPath === "/waitingforapprove") {
        newTitle = "WAITING FOR APPROVAL";
      }

      setRouteName([
        {
          title: newTitle,
          path: newPath,
        },
        { title: data?.transaction_reference },
      ]);

      let newActionAppBar = "";

      if (serviceType === "maker") {
        newActionAppBar = "MAKER_DETAIL";
      } else {
        newActionAppBar = "CHECKER_DETAIL";
      }

      setActionAppbar(newActionAppBar);

      console.log("Approve detail recived data ", {
        data,
        newActionAppBar,
        type,
      });

      if (data) {
        setDataRequest({
          customer_name:
            data?.transaction_type === "UPDATESERVICEADDRESS"
              ? data?.customer_name
              : data?.data?.display_obj?.customer_name,
          account_name: data?.data?.display_obj?.account_name,
          customer_id: data.customer_id,
          account_id: data.account_id ? data.account_id : null,
          transaction_id: data?.transaction_id,
          transaction_type: data.transaction_type,
          raw_data: data?.data?.display_obj?.raw_data,
          update_obj: data?.data?.update_obj,
          status: data.status,
          maker: data?.maker,
          file_upload_id: data?.file_upload_id,
          hide_checker_action: data?.hide_checker_action,
          //  hide_checker_action: "N",
          hide_maker_action: data?.hide_maker_action,
        });
      }
    }
  }, [data]);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  const transaction_type = data.transaction_type;

  if (transaction_type === "NEWOFFER") {
    return <PBFERenderNewOffer data={data} />;
  } else if (transaction_type === "UPDATEOFFER") {
    return <PBFERenderUpdateOffer data={data} />;
  } else if (transaction_type === "TRANSACTION") {
    return <UploadMakerChecker data={data} />;
  } else {
    return null;
  }
};
