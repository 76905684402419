import React, { useEffect } from "react";
import { PBFEAccordionMain } from "../../components/Panel/Accordion/accordionMain";
import { PBFEContent } from "../../components/Elements/Text/content";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEChargesTable } from "../../components/Table/chargesTable";
import useQuery from "../../hooks/useQuery";
import offersService from "../../api/offersService";
import { PBFETaxInfo } from "../../components/Panel/TaxInfo/taxInfo";
import { PBFERetailTable } from "../../components/Table/retailTable";
import { useParams } from "react-router-dom";
import { RETAIL_PATH_NAME, RETAIL_ROUTE } from "../../constants/constant";
import { formateDate } from "../../utils/util";
import PBFEError from "../../components/Error/alertError";

export const MetaData = ({ data }) => {
  return (
    <div id="MetaData" className="px-xl py-lg ">
      <div className="flex">
        <div className="w-full">
          <PBFEContent label="Name" value={data?.offer_name} />
        </div>
        <div className="w-content">
          <PBFEContent label="Code" value={data?.offer_code} />
        </div>
      </div>
      <div className="grid grid-cols-2 mt-25px">
        <PBFEContent label="From" value={formateDate(data?.from_date)} />
        <PBFEContent label="To" value={formateDate(data?.to_date)} />
        {/* <PBFEContent label="Transaction Code" value={data?.gl_product_code} /> */}
      </div>
    </div>
  );
};

export const RenderServiceDetail = ({ data }) => {
  return (
    <div className="w-full pt-xxl">
      <MetaData data={data} />
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionMain
        label="Revenue GL"
        detail={<PBFETaxInfo data={data} type={"Revenue"} container="py-0" />}
      />
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionMain
        label="Vat GL"
        detail={
          <PBFETaxInfo data={data?.vat_gl} type={"VAT"} container="py-0" />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionMain
        label="Withholding Tax GL"
        detail={
          <PBFETaxInfo data={data?.wht_gl} type={"WHT"} container="py-0" />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className=" pl-12 mt-5 mb-2 text-sm">
        <PBFEContent
          label="Enrollment Criteria"
          value={
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="whitespace-pre">{data?.enrollment_condition || "-"}</div>
              </div>
            </div>
          }
        />
      </div>
      <PBFEAccordionMain
        label="Charges"
        detail={
          <PBFEChargesTable
            type="Offer"
            data={data?.offer_fee_charge}
            displayQualifier={false}
            displayPricingDate={true}
          />
        }
      />

      {data?.offer_fee_charge?.map((charge, index) => {
        return (
          <React.Fragment key={index}>
            <PBFEAccordionMain
              id={charge.charge_code}
              label={`${charge.charge_code} - ${charge.charge_name}`}
              detail={
                <PBFERetailTable displayLimit displayFrequency data={charge} />
              }
              // hasToogle
              // toogleLabel={"Pricing Without Debit"}
              // toogleStatus={charge.pricing}
              // toogleStatus={charge?.price_without_post === "Y"}
            />
            <div className="h-10px bg-gray-100"></div>
          </React.Fragment>
        );
      })}
      <div className="h-10px bg-gray-100"></div>
    </div>
  );
};

export const OfferDetail = ({ setRouteName }) => {
  const params = useParams();
  const { data, loading, error } = useQuery(offersService.getOffersInfo, {
    offer_code: params.offer_code,
  });

  useEffect(() => {
    setRouteName([
      { title: RETAIL_PATH_NAME, path: RETAIL_ROUTE },
      { title: data?.offer_name, path: `/offer/${data?.offer_code}` },
    ]);
  }, [data, setRouteName]);

  if (loading) {
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );
  }
  if (error) return <PBFEError error={error} />;

  return <RenderServiceDetail data={data} />;
};
