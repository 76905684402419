import { Fragment, useEffect, useRef, useState } from "react";
import PBFEError from "../../components/Error/alertError";
import { DateRangePicker } from "../../components/Input/dateRangePicker";
import { FilterDropdown } from "../../components/Input/filterDropdown";
import {
  MORE_THAN_SEARCH_ACTIVE,
  OPTIONS_STATUS,
  OPTIONS_STATUS_UPLOAD,
  OPTIONS_TEMPLATE,
  ROW_PER_PAGE,
  TEXT_REFERCH,
  UPLOAD_BREADCOMB,
  UPLOAD_ROUTE,
  UPLOAD_TABLE_HEADER,
} from "../../constants/constant";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import {
  DownloadCell,
  formatDateApi,
  formateDateWithTimeHourMintues,
  UploadColorStatus,
  validateFormatDate,
} from "../../utils/util";
import useQuery from "../../hooks/useQuery";
import uploadService from "../../api/uploadService";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import _ from "lodash";

const FormatDataUpload = (array) => {
  const res = array?.map((row) => {
    return {
      colorStatus: row?.status,
      id: row?.id,
      create_date: row?.create_date,
      file_name: row?.file_path,
      template: row?.template,
      maker: row?.upload_user_name,
      status: _.capitalize(row?.status),
      error: {
        amount: row?.no_err,
        url: row?.file_path,
        type: "ERROR",
        id: row?.id,
        status: row?.status,
      },
      completed: {
        amount: row?.no_success,
        url: row?.file_path,
        type: "SUCCESS",
        id: row?.id,
        status: row?.status,
      },
      total: {
        amount: row?.no_total,
        url: row?.file_path,
        type: "TOTAL",
        id: row?.id,
        status: row?.status,
      },
    };
  });

  return res;
};

const Upload = ({ setRouteName, setActionAppbar }) => {
  const limit = 15;

  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  const refCalendar = useRef();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("create_date");
  const [sortBy, setSortBy] = useState(true);
  const [template, setTemplate] = useState("BUNDLE");
  const [status, setStatus] = useState("");
  const [searched, setSearched] = useState("");

  const [calendarValue, setCalendarValue] = useState([
    new Date(today),
    new Date(today),
  ]);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [isErrorDate, setIsErrorDate] = useState([false, false]);

  const { data, refetch, loading, error, totalRecords } = useQuery(
    uploadService.getUpload,
    {},
    {
      offset: 0,
      limit,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
      order_by: orderBy,
      sort_by: order,
    }
  );

  const toggleCalendar = (e) => {
    setOpenCalendar(!isOpenCalendar);
    e?.stopPropagation();
  };

  useEffect(() => {
    setActionAppbar("UPLOAD DATA");
    setRouteName([
      {
        title: UPLOAD_BREADCOMB,
        path: UPLOAD_ROUTE,
      },
    ]);
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [setRouteName, refCalendar, calendarValue]);

  const handleClickOutsideCalendar = async (event) => {
    if (refCalendar.current && !refCalendar.current.contains(event.target)) {
      let newCalendarValue = calendarValue;
      if (isErrorDate.some((item) => item === true)) {
        return;
        // newCalendarValue = [new Date(), new Date()];
        // setCalendarValue(newCalendarValue);
      }

      setOpenCalendar(false);
      await refetch(
        {},
        {
          ...getFilter(status, template),
          offset: 0,
          limit,
          search_criteria: searched,
          sort_by: order,
          order_by: orderBy,
          from_date: formatDateApi(newCalendarValue[0]),
          to_date:
            newCalendarValue[1] === null
              ? formatDateApi(newCalendarValue[0])
              : formatDateApi(newCalendarValue[1]),
        }
      );
    }
  };

  const onChangeFilterCalendar = (value, shouldReload = false) => {
    setCalendarValue(value);
    if (shouldReload) reload(value);
  };

  const onClickFilterStatus = async (e) => {
    const value = e.target.value;
    setStatus(value);

    await refetch(
      {},
      {
        ...getFilter(value, template),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date: formatDateApi(calendarValue[1]),
      }
    );
  };

  const onClickFilterType = async (e) => {
    const value = e.target.value;
    setTemplate(value);

    await refetch(
      {},
      {
        ...getFilter(status, value),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date: formatDateApi(calendarValue[1]),
      }
    );
  };

  const requestSearch = async (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length > MORE_THAN_SEARCH_ACTIVE) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: value,
          sort_by: order,
          order_by: orderBy,
          ...getFilter(status, template),
        }
      );
      return;
    }
    if (value?.length === 0) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: "",
          sort_by: order,
          order_by: orderBy,
          ...getFilter(status, template),
        }
      );
      return;
    }
  };

  const reload = async (value) => {
    await refetch(
      {},
      {
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(value[0]),
        to_date:
          value[1] === null ? formatDateApi(value[0]) : formatDateApi(value[1]),
      }
    );
  };

  const getFilter = (status, template) => {
    let filter_obj = {
      status,
      template,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
    };
    if (status === "") {
      delete filter_obj.status;
    }

    if (template === "") {
      delete filter_obj.template;
    }
    return filter_obj;
  };

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full min-h-screen">
      <div className="flex justify-between mt-50px w-full pt-md">
        <div className="m-15px flex">
          <FilterDropdown
            label="Template"
            value={template}
            onChange={onClickFilterType}
            options={OPTIONS_TEMPLATE}
            width="100px"
            isErrorDate={isErrorDate}
          />
          <div className="w-20px"></div>
          <FilterDropdown
            label="Status"
            value={status}
            onChange={onClickFilterStatus}
            options={OPTIONS_STATUS_UPLOAD}
            width="80px"
            isErrorDate={isErrorDate}
          />
          <div className="w-20px"></div>
          <DateRangePicker
            refCalendar={refCalendar}
            isOpenCalendar={isOpenCalendar}
            calendarValue={calendarValue}
            setCalendarValue={setCalendarValue}
            toggleCalendar={toggleCalendar}
            onChange={onChangeFilterCalendar}
            isErrorDate={isErrorDate}
            setIsErrorDate={setIsErrorDate}
          />
          <div
            style={{
              height: "30px",
              backgroundColor: "#7A58BF26",
            }}
            className=" ml-10px rounded-3xl px-4 py-2 text-13px  cursor-pointer "
            onClick={async () => {
              await refetch(
                {},
                {
                  ...getFilter(status, template),
                  offset: 0,
                  limit,
                  search_criteria: searched,
                  sort_by: order,
                  order_by: orderBy,
                  from_date: formatDateApi(calendarValue[0]),
                  to_date: formatDateApi(calendarValue[1]),
                }
              );
            }}
          >
            <div
              style={{
                marginTop: "-2px",
              }}
              className="my-auto"
            >
              {TEXT_REFERCH}
            </div>
          </div>
        </div>
        {/* <PBFESearchbar
          placeholder={"Request ID / Code"}
          onChange={requestSearch}
          value={searched}
        /> */}
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <Fragment>
          <PBFECommonTableAsync
            filter={getFilter(status, template)}
            page={page}
            setPage={setPage}
            disabledSortList={[
              "file_name",
              "maker",
              "error",
              "completed",
              "total",
              "color",
            ]}
            searched={searched}
            headCells={UPLOAD_TABLE_HEADER}
            rows={FormatDataUpload(data)}
            showFooter={true}
            selectable={false}
            onClickRow={() => {}}
            formatControl={[
              UploadColorStatus,
              null,
              formateDateWithTimeHourMintues,
              null,
              null,
              null,
              null,
              DownloadCell,
              DownloadCell,
              DownloadCell,
            ]}
            refetch={refetch}
            total_records={totalRecords}
            limit={ROW_PER_PAGE}
            orderByDefault="id"
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Upload;
