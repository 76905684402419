import api from "./index";
import { apiConfig } from "../config";

const getUser = async (params, query, headers = {}) => {
  return api.instanceRetial.get(apiConfig.getUserProfile, { headers });
};

const getAllUserProfile = async (params, query, headers = {}) => {
  return api.instanceRetial.get(apiConfig.getAllUserProfile, { headers });
};

const getAllUserProfileById = async (params, query, headers = {}) => {
  return api.instanceRetial.get(
    apiConfig.getAllUserProfile + "/" + params.user_id
  );
};

const getUserIdap = async (user_id) => {
  return api.instanceRetial.get(apiConfig.getUserIdap + "/" + user_id);
};

const updateUserProfileById = async (user_id, body) => {
  return api.instanceRetial.put(apiConfig.getAllUserProfile + "/" + user_id, {
    ...body,
  });
};

const postUserProfile = async (body) => {
  return api.instanceRetial.post(apiConfig.getAllUserProfile, {
    ...body,
  });
};

const exportedObject = {
  getUser,
  getAllUserProfile,
  getAllUserProfileById,
  updateUserProfileById,
  getUserIdap,
  postUserProfile,
};

export default exportedObject;
