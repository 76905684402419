import api from "./index";
import { apiConfig } from "../config";

const getUserService = async (params, query, headers = {}) => {
  return api.instanceRetial.get(apiConfig.getUserService, { headers });
};

const getUserCharge = async (params, query, headers = {}) => {
  return api.instanceRetial.get(apiConfig.getUserCharges, { headers });
};

const exportedObject = {
  getUserService,
  getUserCharge,
};

export default exportedObject;
