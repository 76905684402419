import api from "./index";
import { apiConfig } from "../config";

const getOffers = async (params, query) => {
  return api.instanceRetial.get(apiConfig.getOfferAPI);
};

const getOffersInfo = async (params, query) => {
  return api.instanceRetial.get(
    `${apiConfig.getOfferAPI}/${params.offer_code}`
  );
};

const exportedObject = {
  getOffers,
  getOffersInfo,
};

export default exportedObject;
