import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// redux
import { refreshTokenThunk } from "../../redux/authSlice";
export const PBFETimerRefreshToken = ({ stopRefreshToken = false }) => {
  const [checkTime, setCheckTime] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stopRefreshToken) {
      let timer = setTimeout(async () => {
        setCheckTime(!checkTime);
        const now = Date.now();
        const plan_refresh_token_expired_at = sessionStorage.getItem(
          "plan_refresh_token_expired_at"
        );
        const actual_refresh_token_expired_at =
          sessionStorage.getItem("refresh_expired_at");
        if (
          now >= parseInt(plan_refresh_token_expired_at) &&
          now < actual_refresh_token_expired_at
        ) {
          const refresh_token = sessionStorage.getItem("refresh_token");
          await dispatch(refreshTokenThunk({ refresh_token })).unwrap();
        }
      }, 1000 * 30);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [checkTime, dispatch, stopRefreshToken]);
};
