import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PBFEError from "../../components/Error/alertError";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import {
  MAKER_CHECKER_WAITING_PATH_NAME,
  MAKER_CHECKER_WAITING_ROUTE,
  WAITING_TABLE_HEADER,
} from "../../constants/constant";

import React from "react";
import makerCheckerService from "../../api/makerCheckerService";
import useQuery from "../../hooks/useQuery";
import { formateDateWithTimeHourMintues, TwoLineColumn } from "../../utils/util";
import Skeleton from "react-loading-skeleton";

const Approval = ({ setRouteName, mt = "50px" }) => {
  const limit = 15;

  const prePareData = (data) => {
    let new_data = [...data];
    new_data = new_data?.sort((a, b) => {
      const sort_by =
        new Date(b.request_date).getTime() > new Date(a.request_date).getTime()
          ? 1
          : -1;
      return sort_by;
    });
    return [...new_data];
  };

  const {
    data,
    setData: setRows,
    loading,
    error,
    totalRecords,
    refetch,
  } = useQuery(
    makerCheckerService.getMakerChecker,
    {},
    {
      offset: 0,
      limit,
    },
    [],
    {
      prePareData,
      checkFirstLoad: true,
    }
  );

  const [isOpenCalendar, setOpenCalendar] = useState(false);

  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const closeCalendar = () => {
    setOpenCalendar(false);
  };

  useEffect(() => {
    setRouteName([
      {
        title: MAKER_CHECKER_WAITING_PATH_NAME,
        path: MAKER_CHECKER_WAITING_ROUTE,
      },
    ]);
  }, [setRouteName]);

  if (error) return <PBFEError error={error} />;

  return (
    <div className={"w-full  pt-md mt-" + mt} onClick={closeCalendar}>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <PBFECommonTableAsync
          // hasUtilIndex={true}
          orderDefault="desc"
          disabledSort
          // orderByDefault="request_date"
          // sort_name_by="request_date"
          limit={limit}
          refetch={refetch}
          total_records={totalRecords}
          searched={searched}
          page={page}
          setPage={setPage}
          headCells={WAITING_TABLE_HEADER}
          rows={data}
          showFooter={true}
          onClickRow={(row) => {
            navigate(`/waitingforapprove/${row.request_id}`);
          }}
          selectable={true}
          // orderByDefault={"request_date"}
          formatControl={[
            null,
            formateDateWithTimeHourMintues,
            TwoLineColumn,
            null,
            null,
            null,
            null,
          ]}
          showFields={[
            "transaction_reference",
            "request_date",
            "request_type,customer_name,account_id",
            "code",
            "maker",
            "status",
            "reject_reason",
          ]}
        />
      )}
    </div>
  );
};

const Waitingforapprove = ({
  setRouteName,
  primaryDisplay,
  mt = "50px",
  disabledRouteName = false,
  setActionAppbar,
}) => {
  useEffect(() => {
    if (setActionAppbar) {
      setActionAppbar("WAITING_FOR_APPROVAL");
    }
  }, []);

  return (
    <Approval
      mt={mt}
      primaryDisplay={primaryDisplay}
      setRouteName={setRouteName}
      disabledRouteName={disabledRouteName}
    />
  );
};

export default Waitingforapprove;
