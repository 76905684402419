import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Check from "../../../assets/icon/check.svg";
import Close from "../../../assets/icon/close.svg";
import { PBFEContent } from "../../Elements/Text/content";
import { PBFEAutocomplete } from "../../Input/autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PBFERejectModal = ({
  open = false,
  isSuccess,
  label = "",
  isError,
  message = "Request Has Been Sent to Approver",
  confirmText = "CONFIRM",
  successText = "DISMISS",
  onClose,
  onClick,
  loading,
  value = "",
  setValue,
  title,
  isInvalidData,
  helperText = "",
}) => {
  React.useEffect(() => {
    setValue("");
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ width: "600px", padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          {title}
        </div>
        <div className=" px-10 mt-8">
          {isSuccess && (
            <div className="text-value flex w-full text-center font-semibold text-textprimary py-10px border-gray-300">
              <img src={Check} className="h-20px w-20px mr-12px"></img>
              {message}
            </div>
          )}
          {isError && (
            <div className="text-value flex w-full text-center font-semibold text-textprimary py-10px border-gray-300">
              <img
                src={Close}
                className="h-20px w-20px mr-12px text-red-400"
              ></img>
              {value}
            </div>
          )}
          {!isError && !isSuccess && open && (
            <TextField
              InputProps={{
                style: { color: "#7A58BF", fontSize: 16 },
              }}
              label={label}
              size="small"
              margin="none"
              name={`reason`}
              error={isInvalidData}
              // defaultValue={""}
              // value={""}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              helperText={isInvalidData ? helperText : ""}
              //  classes={{ notchedOutline: classes.input }}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        {!isSuccess ? (
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        ) : (
          <Button onClick={onClose} variant="contained">
            {successText}
          </Button>
        )}

        {!isSuccess && !isError && (
          <LoadingButton
            disabled={isInvalidData}
            loading={loading}
            onClick={onClick}
            variant="contained"
            loadingPosition="start"
          >
            {confirmText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
