import { Autocomplete, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { PBFEContent } from "../Elements/Text/content";
import _ from "lodash";
import { renderDropdownValue } from "../../functions";

export const PBFECommonDropdown = ({
  label = "input",
  options = ["VALUE", "VALUE2"],
  error = false,
  disabled = false,
  value,
  onChange,
}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        value={value}
        onChange={onChange}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
