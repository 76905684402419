import {
  MORE_THAN_SEARCH_ACTIVE,
  OPTIONS_STATUS_REPORT,
  PRICED_REPORT_TABLE_HEADER,
  TEXT_REFERCH,
} from "../../constants/constant";

import { Fragment, useEffect, useRef, useState } from "react";
import PBFEError from "../../components/Error/alertError";
import { DateRangePicker } from "../../components/Input/dateRangePicker";
import { FilterDropdown } from "../../components/Input/filterDropdown";
import { ROW_PER_PAGE } from "../../constants/constant";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import {
  DownloadCellCommonWithOutText,
  formatDateApi,
  formateDateWithTimeHourMintues,
  ReportColorStatus,
} from "../../utils/util";
import reportService from "../../api/reportService";
import useQuery from "../../hooks/useQuery";
import _ from "lodash";
import { useLocation } from "react-router-dom";

const Report = ({ setRouteName, setActionAppbar, path, route }) => {
  const reportType = path === "FEE DEBIT" ? "FEE_DEBIT" : "PRICED_TRANSACTION";
  const error = false;
  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;
  const refCalendar = useRef();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("request_date");
  const [sortBy, setSortBy] = useState(true);
  // const [reportType, setReportType] = useState(
  //   path === "FEE DEBIT" ? "FEE_DEBIT" : "PRICED_TRANSACTION"
  // );
  const [status, setStatus] = useState("");
  const [searched, setSearched] = useState("");

  const [calendarValue, setCalendarValue] = useState([
    new Date(today),
    new Date(today),
  ]);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [intervalTime, setIntervalTime] = useState(false);
  const [isErrorDate, setIsErrorDate] = useState([false, false]);

  const limit = 15;

  const { data, totalRecords, refetch, loading } = useQuery(
    reportService.getReport,
    {},
    {
      report_type: reportType,
      offset: 0,
      limit,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
      order_by: orderBy,
      sort_by: order,
    },
    [path, route, location.pathname]
  );

  useEffect(() => {
    setCalendarValue([new Date(today), new Date(today)]);
  }, [path, route]);

  const toggleCalendar = (e) => {
    setOpenCalendar(!isOpenCalendar);
    e?.stopPropagation();
  };

  const closeCalendar = () => {
    setOpenCalendar(false);
  };

  useEffect(() => {
    setActionAppbar(path);
    setRouteName([
      {
        title: path,
        path: route,
      },
    ]);
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [setRouteName, refCalendar, path, route, calendarValue]);

  const reloadReport = async () => {
    setIntervalTime(!intervalTime);
    await refetch(
      {},
      {
        ...getFilter(status, reportType),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date:
          calendarValue[1] === null
            ? formatDateApi(calendarValue[0])
            : formatDateApi(calendarValue[1]),
      },
      true
    );
  };

  const handleClickOutsideCalendar = async (event) => {
    if (refCalendar.current && !refCalendar.current.contains(event.target)) {
      let newCalendarValue = calendarValue;
      if (isErrorDate.some((item) => item === true)) {
        return;
        // newCalendarValue = [new Date(), new Date()];
        // setCalendarValue(newCalendarValue);
      }

      setOpenCalendar(false);
      await refetch(
        {},
        {
          ...getFilter(status, reportType),
          offset: 0,
          limit,
          search_criteria: searched,
          sort_by: order,
          order_by: orderBy,
          from_date: formatDateApi(newCalendarValue[0]),
          to_date:
            newCalendarValue[1] === null
              ? formatDateApi(newCalendarValue[0])
              : formatDateApi(newCalendarValue[1]),
        }
      );
    }
  };

  const requestSearch = async (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length > MORE_THAN_SEARCH_ACTIVE) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: value,
          sort_by: order,
          order_by: orderBy,
          ...getFilter(status, reportType),
        }
      );
      return;
    }
    if (value?.length === 0) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: "",
          sort_by: order,
          order_by: orderBy,
          ...getFilter(status, reportType),
        }
      );
      return;
    }
  };

  const onChangeFilterCalendar = (value, shouldReload = false) => {
    setCalendarValue(value);
    if (shouldReload) reload(value);
  };

  const onClickFilterStatus = async (e) => {
    const value = e.target.value;
    setStatus(value);

    await refetch(
      {},
      {
        ...getFilter(value, reportType),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(calendarValue[0]),
        to_date: formatDateApi(calendarValue[1]),
      }
    );
  };

  const onClickFilterMyReport = async (e) => {
    const value = e.target.value;
    // setReportType(value);
  };

  const reload = async (value) => {
    await refetch(
      {},
      {
        ...getFilter(status, reportType),
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        from_date: formatDateApi(value[0]),
        to_date:
          value[1] === null ? formatDateApi(value[0]) : formatDateApi(value[1]),
      }
    );
  };

  const getFilter = (status, report_type) => {
    let filter_obj = {
      status,
      report_type,
      from_date: formatDateApi(calendarValue[0]),
      to_date: formatDateApi(calendarValue[1]),
    };
    if (status === "") {
      delete filter_obj.status;
    }

    if (reportType === "") {
      delete filter_obj.report_type;
    }
    return filter_obj;
  };

  const renderComma = (index, totalLength) => {
    if (index > 0 && index < totalLength - 1) {
      return ", ";
    }

    return "";
  };

  const Criteria = (criteria) => {
    const Content = ({ label, value = [] }) => {
      if (value === undefined) return <></>;
      return (
        <div className="text-textlabel text-14px flex flex-row">
          <div className="w-20">{label}: </div>
          <div className="font-bold text-textprimary w-full ml-2 ">
            {" "}
            {!value.length ? " All" : value?.join(",\n")}
          </div>
        </div>
      );
    };

    return (
      <div className="max-w-lg">
        <Content
          label="Customer"
          value={criteria?.customers?.map((customer) => `${customer.name}`)}
        />
        <Content
          label="Account"
          value={criteria?.accounts?.map((account) => `${account.name}`)}
        />
        {/* <Content
          label="Service"
          value={criteria?.service_codes?.map(
            (service_code) => `${service_code.name}`
          )}
        /> */}
        <Content
          label="Charge"
          value={criteria?.charge_codes?.map(
            (charge_code) => `${charge_code.name}`
          )}
        />
      </div>
    );
  };

  const dataMapReport = (data = []) => {
    if (data?.length > 0) {
      return data?.map((item) => {
        return {
          colorStatus: item.status,
          request_id: item.request_id,
          request_date: item.request_date,
          date_range: item.criteria,
          criteria: item.criteria,
          status: _.capitalize(item.status),
          download: {
            request_id: item.request_id,
            status: _.upperCase(item.status),
            fail_reason: item.fail_reason,
          },
        };
      });
    }

    return [];
  };

  const formatDateRange = (criteria) => {
    return (
      <div>
        <div> {format(new Date(criteria.from_date), "dd/MM/yyyy")} -</div>
        <div> {format(new Date(criteria.to_date), "dd/MM/yyyy")}</div>
      </div>
    );
  };

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full min-h-screen">
      <div className="flex justify-between mt-50px w-full pt-md">
        <div className="m-15px flex">
          <FilterDropdown
            label="Status"
            value={status}
            onChange={onClickFilterStatus}
            options={OPTIONS_STATUS_REPORT}
            width="80px"
            isErrorDate={isErrorDate}
          />
          <div className="w-20px"></div>
          <DateRangePicker
            refCalendar={refCalendar}
            isOpenCalendar={isOpenCalendar}
            calendarValue={calendarValue}
            setCalendarValue={setCalendarValue}
            toggleCalendar={toggleCalendar}
            onChange={onChangeFilterCalendar}
            isErrorDate={isErrorDate}
            setIsErrorDate={setIsErrorDate}
          />
          <div
            style={{
              height: "30px",
              backgroundColor: "#7A58BF26",
            }}
            className=" ml-10px rounded-3xl px-4 py-2 text-13px  cursor-pointer "
            onClick={async () => {
              reloadReport();
            }}
          >
            <div
              style={{
                marginTop: "-2px",
              }}
              className="my-auto"
            >
              {TEXT_REFERCH}
            </div>
          </div>
        </div>
        {/* <PBFESearchbar
          placeholder={"Request ID / Code"}
          onChange={requestSearch}
          value={searched}
        /> */}
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <Fragment>
          <PBFECommonTableAsync
            cursorPointer={false}
            disabledSortList={[
              "date_range",
              "criteria",
              "download",
              "request_by",
            ]}
            searched={searched}
            filter={getFilter(status, reportType)}
            page={page}
            setPage={setPage}
            headCells={
              PRICED_REPORT_TABLE_HEADER
              // path === REPORT_FEE_PATH_NAME
              //   ? FEE_REPORT_TABLE_HEADER
              //   : PRICED_REPORT_TABLE_HEADER
            }
            // rows={path === REPORT_FEE_PATH_NAME ? FEE_DATAS : PRICE_DATAS}
            rows={dataMapReport(data)}
            showFooter={true}
            selectable={false}
            formatControl={[
              ReportColorStatus,
              null,
              formateDateWithTimeHourMintues,
              formatDateRange,
              Criteria,
              null,
              DownloadCellCommonWithOutText,
            ]}
            refetch={refetch}
            total_records={totalRecords}
            limit={ROW_PER_PAGE}
            orderByDefault="request_date"
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Report;
