import React from "react";

//mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import { ACTIVE_COLOR, HOVER_COLOR } from "../../../constants/style";
import { CustomListBarNavLink } from "../ListbarCollapse/listbarCollapse";

function PBFEListbar({ textHeader = "", items = [], ...props }) {
  const CustomListBar = styled(ListItem)(({ theme }) => ({
    "&:hover": {
      backgroundColor: HOVER_COLOR,
    },
    "&:active": {
      backgroundColor: ACTIVE_COLOR,
    },
  }));
  return (
    <List {...props}>
      {items.map((item, index) => {
        if (item.hidden) return null;
        //   const { imgIcon, text = "" } = item;
        return (
          <CustomListBarNavLink key={index} item={item} icon={item.icon} />
        );
        /*
        return (
          <CustomListBar key={index} button>
            <ListItemIcon>
              {item.imgIcon && <img alt="" src={imgIcon} />}

            </ListItemIcon>
            <div className="text-medium-10px  ">{text}</div>
          </CustomListBar>
        );
      })}*/
      })}
    </List>
  );
}

export default PBFEListbar;
