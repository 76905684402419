import ServiceIcon from "../../../assets/icon/service_black.svg";
import ServiceIconPurple from "../../../assets/icon/service_purple.svg";
import OfferIconPurple from "../../../assets/icon/offer_purple.svg";
import EditIcon from "../../../assets/icon/edit.svg";
import RemoveIcon from "../../../assets/icon/remove.svg";
import PlusIcon from "../../../assets/icon/plus.svg";

export const HeaderComponent = ({
  label,
  name,
  icon = EditIcon,
  isOffer = false,
  onClick = () => {},
  p = "pl-xl pt-large",
  purple = false,
  hideButton = false,
}) => {
  return (
    <div className={p + " text-header font-bold  flex"}>
      <img
        className="w-28px h-28px"
        alt="icon"
        src={
          purple ? (isOffer ? OfferIconPurple : ServiceIconPurple) : ServiceIcon
        }
      />
      <div
        id="PBFEHeader"
        className={"ml-10px h-32px mt-0 " + (purple ? " text-textpurple" : "")}
      >
        {label}
      </div>
      {!hideButton && (
        <div
          className="flex ml-36px text-14px items-center cursor-pointer text-textscb"
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
        >
          <img src={icon} className="mr-10px" alt="header_image" />
          {name}
        </div>
      )}
    </div>
  );
};

export const PBFEHeader = ({
  label = "label",
  p = "pl-xl pt-large",
  purple = false,
  editable = false,
  onPress,
  onRemove,
  isRemove = false,
  isOffer = false,
  onAdd,
  hideButton = false,
}) => {
  if (editable === true) {
    return (
      <HeaderComponent
        label={label}
        name={"EDIT"}
        onClick={onPress}
        icon={EditIcon}
        isOffer={isOffer}
        p={p}
        purple={purple}
        hideButton={hideButton}
      />
    );
  } else {
    return (
      <HeaderComponent
        isOffer={isOffer}
        label={label}
        p={p}
        purple={purple}
        hideButton={true}
      />
    );
  }
  // else if (isRemove === true) {
  //   return (
  //     <HeaderComponent
  //       label={label}
  //       name={"ADD"}
  //       onClick={onAdd}
  //       icon={PlusIcon}
  //       p={p}
  //       purple={purple}
  //       hideButton={hideButton}
  //     />
  //   );
  // } else {
  //   return (
  //     <HeaderComponent
  //       label={label}
  //       name={"REMOVE"}
  //       onClick={onRemove}
  //       icon={RemoveIcon}
  //       p={p}
  //       purple={purple}
  //       hideButton={hideButton}
  //     />
  //   );
  // }
};
