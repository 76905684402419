import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ACCOUNT_HEAD_CELLS,
  ACCOUNT_TITLE,
  CUSTOMER_TITLE,
} from "../../../constants/constant";
import { getNameFromDebitProfileSeleted } from "../../../functions";
import { setDisabledAction } from "../../../redux/actionAppbarSlice";
import { PBFEHeader } from "../../Elements/Text/header";
import { PBFEDatePicker } from "../../Input/datePicker";
import { PBFEEditTable } from "../../Table/editTable";
import { PBFEAccordionMain } from "../Accordion/accordionMain";
// import { AccountModal } from "../Modal/accountModal";
import { ServiceDetail } from "./servicesDetail";
import makerCheckerService from "../../../api/makerCheckerService";
import { onScrollToTop } from "../../../utils/util";
import { useNavigate } from "react-router-dom";
import { RetailDetail } from "./retailDetail";

export const OfferRetailInformation = ({
  data,
  index = 0,
  setData,
  account_table = [],
  isEdit,
  isOffer,
  accountTable = [],
  debitProfile = [],
  setDebitProfile,
  setErrorState,
  isNew,
  setDataDuplicate = () => {},
}) => {
  // const [dataretail, setDataretail] = useState([]);

  // console.log("data", data);

  return (
    <React.Fragment>
      <div className="grid grid-cols-5 gap-40px my-25px ml-20px">
        <PBFEDatePicker
          originalData={[]}
          isNew={isNew}
          isOffer={true}
          keyName="from_date"
          data={data}
          setData={setData}
          index={index}
          label={"Effective From"}
          isEdit={isEdit}
          disabled={data.checkCurrentDate}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
        />
        <PBFEDatePicker
          originalData={[]}
          isNew={isNew}
          isOffer={true}
          keyName="to_date"
          data={data}
          setData={setData}
          index={index}
          label="To"
          isEdit={isEdit}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
      </div>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-20px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isNew={isNew}
              isEdit={isEdit}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              // debitProfile={debitProfile}
              // setDebitProfile={setDebitProfile}
              globalDebitProfile={debitProfile}
              setDebitProfileGlobal={setDebitProfile}
              setErrorState={setErrorState}
            />
          }
        />
      )}
      <RetailDetail data={data} />
    </React.Fragment>
  );
};

export const OfferInformation = ({
  data,
  index,
  setData,
  account_table,
  isEdit,
  isOffer,
  accountTable,
  debitProfile,
  setDebitProfile,
  setErrorState,
  isNew,
}) => {
  const [dataService, setDataService] = useState([]);
  // const [modal, setModal] = React.useState(false);
  // const [currentRowData, setCurrentRowData] = React.useState(null);
  // const [selectAccount, setSelectAccount] = React.useState("");

  useEffect(() => {
    if (data) {
      setDataService(data?.service);
    }
    return () => {};
  }, [data]);

  // const onClickRow = (row) => {
  //   setCurrentRowData(row);
  //   setModal(true);
  // };
  // const editRow = (e) => {
  //   e.stopPropagation();

  //   const index = debitProfile?.findIndex(
  //     (i) => i.posting_account_id === currentRowData.posting_account_id
  //   );
  //   const accountSeleted = getNameFromDebitProfileSeleted(selectAccount);

  //   let temp = [...debitProfile];

  //   const today = new Date();

  //   // const customer_posting_account_id =
  //   temp[index] = {
  //     posting_account_id: selectAccount?.id,
  //     posting_account_name: accountSeleted?.name,
  //     customer_posting_account_id: selectAccount?.id,
  //     status: "Active",
  //     from_date: new Date().toJSON()?.slice(0, 10) + "T00:00:00",
  //     to_date:
  //       new Date(
  //         `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  //       )
  //         .toJSON()
  //         ?.slice(0, 10) + "T00:00:00",
  //     action: selectAccount?.id ? "EDIT" : "NEW",
  //   };
  //   setDebitProfile([...temp]);
  //   setModal(false);
  // };

  return (
    <React.Fragment>
      <div className="grid grid-cols-5 gap-40px my-25px">
        <PBFEDatePicker
          originalData={dataService}
          isNew={isNew}
          isOffer={true}
          keyName="from_date"
          data={data}
          setData={setData}
          index={index}
          label={"Effective From"}
          isEdit={isEdit}
          disabled={data.checkCurrentDate}
          setErrorState={setErrorState}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
        <PBFEDatePicker
          originalData={dataService}
          isNew={isNew}
          isOffer={true}
          keyName="to_date"
          data={data}
          setData={setData}
          index={index}
          label="To"
          isEdit={isEdit}
          setErrorState={setErrorState}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
      </div>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-20px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isNew={isNew}
              isEdit={isEdit}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              // onClickRow={onClickRow}
              setErrorState={setErrorState}
            />
          }
        />
      )}
      {dataService?.map((info, index) => {
        return (
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={info.service_name + " - " + info.service_code}
                purple={true}
                editable={false}
                hideButton={true}
              />
            }
            detail={
              <ServiceDetail
                key={`${info.service_code}-${index}`}
                isOffer={isOffer}
                primaryIndex={index}
                primaryField="fee_charge"
                origin={info}
                setData={setDataService}
                data={info.fee_charge}
                account_table={account_table}
                isOffer={true}
                isEdit={false}
                setErrorState={setErrorState}
              />
            }
          />
        );
      })}
      {/* <AccountModal
        selectAccount={selectAccount}
        open={modal}
        title={"Edit Account"}
        action={editRow}
        setSelectAccount={setSelectAccount}
        textButton="Confirm"
        onClose={() => setModal(false)}
        accountTable={accountTable}
        debitProfile={debitProfile}
      /> */}
    </React.Fragment>
  );
};

export const ServiceInformation = ({
  data,
  index,
  setData,
  account_table,
  isEdit,
  accountTable,
  isNew,
  debitProfile,
  setDebitProfile,
  originBigData,
  setErrorState,
  setHasChages,
  hasChages,
}) => {
  const [dataGroup, setDataGroup] = React.useState([]);

  // const [modal, setModal] = React.useState(false);
  // const [currentRowData, setCurrentRowData] = React.useState(null);
  // const [selectAccount, setSelectAccount] = React.useState("");

  useEffect(() => {
    if (data) {
      setDataGroup([...data?.charge_group]);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (isNew && dataGroup?.length > 0) {
      setData((prev) => {
        prev[index].charge_group = [...dataGroup];
        return [...prev];
      });
    }
    return () => {};
  }, [dataGroup]);

  // const onClickRow = (row) => {
  //   setCurrentRowData(row);
  //   setModal(true);
  // };
  // const editRow = (e) => {
  //   e.stopPropagation();

  //   const index = debitProfile?.findIndex(
  //     (i) => i.posting_account_id === currentRowData.posting_account_id
  //   );
  //   const accountSeleted = getNameFromDebitProfileSeleted(selectAccount);
  //   //  const customer_posting_account_id =  account_table.find()
  //   let temp = [...debitProfile];

  //   const today = new Date();

  //   console.log("selectAccount", selectAccount);

  //   // const edit_data = debitProfile?.find()

  //   temp[index] = {
  //     posting_account_id: accountSeleted?.id,
  //     posting_account_name: accountSeleted?.name,
  //     customer_posting_account_id: selectAccount?.id,
  //     status: "Active",
  //     from_date: new Date().toJSON()?.slice(0, 10) + "T00:00:00",
  //     to_date:
  //       new Date(
  //         `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  //       )
  //         .toJSON()
  //         ?.slice(0, 10) + "T00:00:00",
  //     action: selectAccount?.id ? "EDIT" : "NEW",
  //   };
  //   setDebitProfile([...temp]);
  //   setModal(false);
  // };

  return (
    <React.Fragment>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-3px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isEdit={isEdit}
              isNew={isNew}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              // onClickRow={onClickRow}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setErrorState={setErrorState}
            />
          }
        />
      )}

      {dataGroup?.length > 0 &&
        dataGroup?.map((group, index) => {
          if (!group?.fee_charge?.length) return;

          return (
            <ServiceDetail
              key={`service-detail-${index}`}
              primaryIndex={index}
              primaryField="fee_charge"
              origin={data}
              isEdit={isEdit}
              setData={setDataGroup}
              data={group.fee_charge}
              account_table={account_table}
              accountTable={accountTable}
              charge_code={group.charge_code}
              charge_name={group.charge_name}
              isNew={isNew}
              isGroup={true}
              lenghGroup={group?.fee_charge?.length}
              originBigData={originBigData}
              setErrorState={setErrorState}
              group={group}
            />
          );
        })}

      {/* <AccountModal
        selectAccount={selectAccount}
        open={modal}
        title={"Edit Account"}
        action={editRow}
        setSelectAccount={setSelectAccount}
        textButton="Confirm"
        onClose={() => setModal(false)}
        accountTable={accountTable}
        debitProfile={debitProfile}
      /> */}
    </React.Fragment>
  );
};

export const Information = ({
  label,
  data,
  index,
  isOffer = false,
  setData,
  account_table,
  accountTable,
  isEdit = false,
  isNew = false,
  hideButton,
  setIsEdit,
  setIsEditWhereData,
  type,
  setDebitProfile,
  debitProfile,
  originBigData,
  setErrorState = () => {},
  setDataRequest = () => {},
  is_maker,
  setRouteName,
  customer,
  account,
  setCheckConfirmError,
  refetch = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataCurrent, setDataCurrent] = useState([]);
  const [hasChages, setHasChages] = useState(false);

  const toogleEdit = async (e) => {
    e.stopPropagation();

    let pathname = "";
    let name = "";
    let id = "";

    if (account?.account_id) {
      pathname = "account";
      name = account?.account_name;
      id = account?.account_id;
    } else {
      pathname = "customer";
      name = customer?.customer_name;
      id = customer?.customer_id;
    }

    try {
      let queryString = "";
      let tran_type = "";
      let code = "";

      if (isOffer) {
        tran_type = "UPDATEOFFER";
        code = data.offer_code;
      } else {
        tran_type = "UPDATESERVICE";
        code = data.service_code;
      }

      if (customer?.customer_id && account?.account_id) {
        queryString = `?customer_id=${customer.customer_id}&account_id=${account.account_id}&tran_type=${tran_type}&code=${code}`;
      } else {
        queryString = `?customer_id=${customer.customer_id}&tran_type=${tran_type}&code=${code}`;
      }

      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          queryString
        );

      const params = {
        customer_id: customer?.customer_id,
        account_id: account?.account_id,
      };

      if (responseData.data.pending === "N") {
        // const type = isOffer ? "offer" : "service";
        // if (params.account_id) {
        //   navigate(`/${pathname}/${params.account_id}/edit/${type}/${code}`);
        // } else {
        //   navigate(`/${pathname}/${params.customer_id}/edit/${type}/${code}`);
        // }

        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "validate_pending");
          return [...temp];
        });

        setCheckConfirmError(true);
        if (!isOffer) {
          setRouteName([
            {
              title: pathname,
              path: `/${pathname}`,
            },
            {
              title: `${name}`,
              path: `/${pathname}/${id}`,
              setSelected: setIsEdit,
              check: true,
              action: () => {
                setErrorState([]);
                refetch(params, {});
              },
            },
            {
              title: "EDIT SERVICE",
            },
          ]);
        } else {
          setRouteName([
            {
              title: pathname,
              path: `/${pathname}`,
            },
            {
              title: `${name}`,
              path: `/${pathname}/${id}`,
              setSelected: setIsEdit,
              check: true,
              action: () => {
                setErrorState([]);
                refetch(params, {});
              },
            },
            {
              title: "EDIT OFFER",
            },
          ]);
        }
        setIsEditWhereData(data);
        setIsEdit(!isEdit);
        dispatch(setDisabledAction({ disabledAction: false }));
        setDataRequest({});
        onScrollToTop("edit");
        return;
      } else {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "validate_pending");

          return [
            ...temp,
            {
              field_name: "validate_pending",
              message: responseData.data.description,
              show: true,
            },
          ];
        });
        setCheckConfirmError(true);
        onScrollToTop("invalid");
      }
    } catch (error) {
      console.error("edit check error", error);
    }
  };

  const onRemoveService = (e) => {
    e.stopPropagation();
    setDataCurrent(data);
    setData((prev) => {
      prev[index] = {
        ...data,
        isRemove: true,
        charge_group: [],
        fee_charge: [],
        service: [],
        posting_account: [],
      };
      return [...prev];
    });
  };

  const onRemoveOffer = (e) => {
    e.stopPropagation();
    setDataCurrent(data);
    setData((prev) => {
      prev[index] = {
        ...data,
        isRemove: true,
        service: [],
        posting_account: [],
      };
      return [...prev];
    });
  };

  const onAdd = (e) => {
    e.stopPropagation();
    setData((prev) => {
      prev[index] = {
        ...dataCurrent,
      };
      return [...prev];
    });
  };

  return (
    <React.Fragment>
      <PBFEAccordionMain
        pl="ml-20px"
        customHeader={
          <PBFEHeader
            p=""
            label={label}
            purple={true}
            editable={isEdit === true ? false : true}
            isRemove={data?.isRemove}
            hideButton={
              data?.is_maker === "N" || data?.offer_fee_charge?.length === 0
            }
            isOffer={isOffer}
            onRemove={type === "service" ? onRemoveService : onRemoveOffer}
            onPress={toogleEdit}
            onAdd={onAdd}
          />
        }
        detail={
          <OfferRetailInformation
            label={label}
            data={data}
            index={index}
            setData={setData}
            account_table={account_table}
            accountTable={accountTable}
            isEdit={isEdit}
            isNew={isNew}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            originBigData={originBigData}
            setErrorState={setErrorState}
            setHasChages={setHasChages}
            hasChages={hasChages}
            // setIsEditWhereData={setIsEditWhereData}
          />
        }
      />
    </React.Fragment>
  );
};
