import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "../../components/Input/dateRangePicker";

import { format } from "date-fns";

import { PBFEEditableList } from "../../components/Display/pbfeList";
import GenerateReportFilterModal from "../../components/Panel/Modal/generateReportFilterModal";
import { validateFormatDate } from "../../utils/util";

const GenerateReport = ({
  setRouteName,
  setActionAppbar,
  path,
  route,
  setDataGenerateReport,
  dataGenerateReport,
  reset,
}) => {
  const MODE = {
    CUSTOMER: "customer",
    ACCOUNT: "account",
    // SERVICE: "service",
    CHARGE: "charge",
  };

  const initData = {
    customer: [],
    account: [],
    service: [],
    charge: [],
  };

  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  const initCalendarValue = [new Date(today), new Date(today)];

  const refCalendar = useRef();
  const [calendarValue, setCalendarValue] = useState(initCalendarValue);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [data, setData] = useState(initData);
  const [cacheData, setCacheData] = useState(initData);
  const [isErrorDate, setIsErrorDate] = useState([false, false]);

  useEffect(() => {
    setCalendarValue(initCalendarValue);
    setData(initData);
    setCacheData(initData);
  }, [reset]);

  useEffect(() => {
    if (data && setDataGenerateReport) {
      setDataGenerateReport({ ...data, calendarValue });
    }
  }, [data, calendarValue]);

  const toggleCalendar = (e) => {
    setOpenCalendar(!isOpenCalendar);
    e?.stopPropagation();
  };
  useEffect(() => {
    setActionAppbar("GENERATE");
    setRouteName([
      {
        title: path,
        path: route,
      },
      {
        title: "GENERATE REPORT",
        path: "",
      },
    ]);
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [setRouteName, refCalendar, path, route, calendarValue]);

  const handleClickOutsideCalendar = async (event) => {
    if (refCalendar.current && !refCalendar.current.contains(event.target)) {
      if (isErrorDate.some((item) => item === true)) {
        return;
        // newCalendarValue = [new Date(), new Date()];
        // setCalendarValue(newCalendarValue);
      }

      setOpenCalendar(false);
    }
  };

  const onChangeFilterCalendar = (value) => {
    setCalendarValue(value);
  };

  const adjustFilter = (newData) => {
    switch (mode) {
      case MODE.ACCOUNT:
        setData({ ...data, account: newData });
        break;
      case MODE.CUSTOMER:
        setData({ ...data, customer: newData });
        break;
      // case MODE.SERVICE:
      //   setData({ ...data, service: newData });
      //   break;
      case MODE.CHARGE:
        setData({ ...data, charge: newData });
        break;
      default:
      // code block
    }
    setOpenModal(false);
  };

  const openModalMode = (mode) => {
    setMode(mode);
    setOpenModal(true);
  };

  const removeItem = (mode, item) => {
    let temp = [...data[mode]];
    let index = temp.findIndex((element) => element.id === item.id);
    if (index > -1) temp.splice(index, 1); // 2nd parameter means remove one item only
    setData({ ...data, [mode]: temp });
  };

  const onClose = () => {
    setOpenModal(false);
    // if (mode) {
    // setCacheData({ ...cacheData, [mode]: [] });
    // setData({ ...data, [mode]: [] });
    // }
  };

  return (
    <div className="grid grid-cols-2 gap-8 w-full mt-24 max-w-3xl pl-xl">
      <GenerateReportFilterModal
        selected={mode && data[mode]}
        open={openModal}
        onSubmit={adjustFilter}
        onClose={onClose}
        placeholder={""}
        mode={mode}
        cacheData={cacheData}
        setCacheData={setCacheData}
      />
      <div>
        <div className="text-textlabel text-12px mb-10px">Date Range</div>
        <DateRangePicker
          marginX="0"
          isErrorDate={isErrorDate}
          setIsErrorDate={setIsErrorDate}
          refCalendar={refCalendar}
          isOpenCalendar={isOpenCalendar}
          calendarValue={calendarValue}
          setCalendarValue={setCalendarValue}
          toggleCalendar={toggleCalendar}
          onChange={onChangeFilterCalendar}
        />
        {/* <div className="text-textlabel text-12px mt-10px">
          Specific Date Description
        </div> */}
      </div>
      <PBFEEditableList
        closeAction={removeItem}
        action={() => openModalMode(MODE.CUSTOMER)}
        mode={MODE.CUSTOMER}
        label="Customer"
        arr={data.customer}
      />
      <PBFEEditableList
        closeAction={removeItem}
        action={() => openModalMode(MODE.ACCOUNT)}
        mode={MODE.ACCOUNT}
        label="Account"
        arr={data.account}
      />
      {/* <PBFEEditableList
        closeAction={removeItem}
        action={() => openModalMode(MODE.SERVICE)}
        mode={MODE.SERVICE}
        label="Service"
        arr={data.service}
      /> */}
      <PBFEEditableList
        closeAction={removeItem}
        action={() => openModalMode(MODE.CHARGE)}
        mode={MODE.CHARGE}
        label="Charge"
        arr={data.charge}
      />
    </div>
  );
};

export default GenerateReport;
