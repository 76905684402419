import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { PBFEContent } from "../Elements/Text/content";
import { formateDate } from "../../utils/util";
import { pad } from "../../utils/util";
import {
  checkFromToDate,
  FunctionCheckDateIsNaN,
  FunctionCheckDateLength,
  FunctionCheckDateValidCustom,
  TEXT_FIELD_ERROR_DATE,
} from "../../functions";
import { useEffect, useState } from "react";
import _ from "lodash";
import { NULL_DATE } from "../../constants/constant";

export const PBFEDatePicker = ({
  keyName,
  data,
  setData,
  label = "From",
  index = 0,
  isEdit = false,
  isNew = false,
  primaryIndex = 0,
  primaryField,
  disabled = false,
  error = false,
  group = {},
  isOffer = false,
  setErrorState,
  helperText,
  originalData,
  setErrorLocalFee,
  setCheckChange,
  hasChange,
  setDataDuplicate = () => {},
}) => {
  const [getDateFocus, setGetDateFocus] = useState("");

  const onDateChange = (newValue) => {
    if (setCheckChange) {
      setCheckChange((prev) => !prev);
    }
    let formatDate =
      newValue?.getFullYear() +
      "-" +
      pad(newValue?.getMonth() + 1) +
      "-" +
      pad(newValue?.getDate());

    if ((newValue === "" || newValue === null) && keyName === "to_date") {
      formatDate = "";
    } else {
      formatDate = `${formatDate}T00:00:00`;
    }
    setData((prev) => {
      if (!isOffer) {
        prev[primaryIndex][primaryField][index][keyName] = formatDate;
        prev[primaryIndex][primaryField][index].action = "EDIT";
        return [...prev];
      } else {
        if (isNew) {
          prev[index][keyName] = formatDate;
          prev[index].action = "EDIT";
          return [...prev];
        }

        prev.offer[index][keyName] = formatDate;
        prev.offer[index].action = "EDIT";
        return { ...prev };
      }
    });
    if (setDataDuplicate) {
      setDataDuplicate((prev) => {
        prev[keyName] = formatDate;
        return { ...prev };
      });
    }
  };

  const ValidateLengthValid =
    keyName === "to_date" || keyName === "from_date"
      ? FunctionCheckDateLength(getDateFocus, keyName)
      : { error: false, message: "" };

  const ValidateDateValid =
    keyName === "to_date" || keyName === "from_date"
      ? FunctionCheckDateValidCustom(data[keyName], keyName)
      : { error: false, message: "" };

  const getGroupIndex = () => {
    let currentIndex = index;
    for (let newIndex = currentIndex - 1; newIndex >= 0; newIndex--) {
      if (group?.fee_charge[newIndex]?.isRemove === false) {
        currentIndex = newIndex;
        break;
      }
    }

    return currentIndex;
  };

  const getErrorFromToDate =
    (keyName === "from_date" &&
      checkFromToDate(
        data?.from_date,
        data?.to_date,
        group?.fee_charge?.length > 0 && index > 0
          ? group?.fee_charge[getGroupIndex()]?.to_date
          : null,
        keyName
      )) ||
    (keyName === "to_date" && checkFromToDate(data?.from_date, data?.to_date));
  const getErrorDate =
    getErrorFromToDate === TEXT_FIELD_ERROR_DATE
      ? { error: true, message: TEXT_FIELD_ERROR_DATE }
      : { error: false, message: "" };

  const getIsError = () => {
    const getError = checkFromToDate(
      data?.from_date,
      data?.to_date,
      group?.fee_charge?.length > 0 && index > 0
        ? group?.fee_charge[getGroupIndex()]?.to_date
        : null,
      keyName
    );

    if (
      getError ||
      ValidateDateValid.error ||
      getErrorDate.error ||
      ValidateLengthValid.error
    ) {
      return true;
    }

    return error;
  };

  const getErrorMessage = () => {
    const getError = checkFromToDate(
      data?.from_date,
      data?.to_date,
      group?.fee_charge?.length > 0 && index > 0
        ? group?.fee_charge[getGroupIndex()]?.to_date
        : null,
      keyName
    );

    if (
      getError ||
      ValidateDateValid.error ||
      getErrorDate.error ||
      ValidateLengthValid.error
    ) {
      return (
        getError ||
        ValidateDateValid.message ||
        getErrorDate.message ||
        ValidateLengthValid.message
      );
    }

    return helperText;
  };

  if (data.isRemove) {
    return null;
  }

  if (isEdit) {
    let getError = false;
    if (group?.fee_charge?.length > 0) {
      getError = checkFromToDate(
        data?.from_date,
        data?.to_date,
        group?.fee_charge?.length > 0 && group?.fee_charge[index - 1]
          ? group?.fee_charge[index - 1]?.to_date
          : {},
        keyName
      );
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Date desktop"
          inputFormat="dd/MM/yyyy"
          value={
            keyName === "to_date" && data[keyName] === NULL_DATE
              ? null
              : data[keyName]
          }
          // value={data[keyName]}
          onChange={onDateChange}
          disabled={disabled}
          // disablePast
          renderInput={(params) => {
            setGetDateFocus(params?.inputProps?.value);
            if (params?.inputProps?.placeholder) {
              params.inputProps.placeholder = "DD/MM/YYYY";
            }

            return (
              <TextField
                placeholder="DD/MM/YYYY"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { color: "#7A58BF", fontSize: 16 },
                  placeholder: "DD/MM/YYYY",
                }}
                variant="standard"
                label={label}
                error={getIsError()}
                helperText={getErrorMessage()}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <div className=" ">
        <PBFEContent
          mode="flex w-full"
          label={label}
          value={formateDate(data[keyName])}
        />
      </div>
    );
  }
};
